import { MdOutlineElectricBolt, MdOutlineLaptopWindows } from "react-icons/md";
import { PiFireSimpleBold, PiOfficeChairBold } from "react-icons/pi";
import { IoWaterOutline } from "react-icons/io5";
import { Button } from "flowbite-react";
import { HiOutlineArrowDownTray, HiOutlineArrowUpTray } from "react-icons/hi2";
import ImageView from "../../components/ImageView";
import moment from "moment";
import { LineChart } from "@mui/x-charts/LineChart";

const boxes = [
  { name: "Internet", icon: MdOutlineLaptopWindows, color: "bg-purple-100" },
  { name: "Office", icon: PiOfficeChairBold, color: "bg-green-100" },
  { name: "Gas", icon: PiFireSimpleBold, color: "bg-blue-50" },
  { name: "Electricity", icon: MdOutlineElectricBolt, color: "bg-red-50" },
  { name: "Water", icon: IoWaterOutline, color: "bg-orange-50" },
];

const MyWallet = () => {
  return (
    <div className="flex gap-2 p-4 mb-5">
      <div className="flex flex-col gap-5 w-[75%]">
        <div className="grid grid-cols-5 gap-3">
          {boxes.map((box) => (
            <div
              className={`flex flex-col items-center text-black gap-1 py-5 px-2 rounded-md ${box.color}`}
            >
              <div className="text-4xl font-semibold">
                <box.icon />
              </div>
              <span className="text-center text-xl">{box.name}</span>
            </div>
          ))}
        </div>

        <div className="flex gap-3">
          <div className="w-[60%] bg-gray-100 dark:bg-gray-800 rounded-lg">
            <LineChart
              xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
              series={[
                {
                  data: [2, 5.5, 2, 8.5, 1.5, 5],
                },
              ]}
              className="text-red-500"
              height={400}
            />
          </div>
          <div className="w-[40%] bg-gray-100 dark:bg-gray-800 rounded-lg px-3 pt-2 pb-4">
            <span className="text-sm font-semibold">Transaction History</span>

            <div className="flex flex-col min-h-40 gap-6 mt-6">
              {[1, 2, 3, 4, 5].map((item, i) => (
                <div key={i} className="flex justify-between items-start">
                  <div className="flex gap-2">
                    <ImageView
                      url={
                        "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
                      }
                      className="w-10 h-10 rounded-lg"
                    />

                    <div className="flex flex-col justify-between flex-1">
                      <span className="text-xs font-semibold">Electricity</span>
                      <span className="text-[11px]">
                        {moment().format("LLL")}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-end">
                    <span className="font-semibold">$20.00</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-[25%] pt-5">
        <div className="w-full flex flex-col gap-1 items-center">
          <span className="text-sm">Total Balance</span>
          <span className="text-xl font-semibold">$81,900.00</span>

          <div className="mt-5 flex gap-2 items-center">
            <Button size="sm" className="flex items-center bg-black w-24">
              Send <HiOutlineArrowUpTray className="ml-2 text-sm" />
            </Button>
            <Button size="sm" className="flex items-center bg-black w-24">
              Receive <HiOutlineArrowDownTray className="ml-2 text-sm" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyWallet;
