import CustomPagination from "../../components/CustomPagination";
import {
  useGetKpisUserQuery,
  useGetTaskNamesQuery,
} from "../../redux/queries/tasks";
import { kpiValueTypeArr } from "../../route/data";

interface TableTaskProps {
  userId: string;
  access: string;
}

const TasksTable = ({ userId, access }: TableTaskProps) => {
  //const { isTaskLoading, tasks } = useAppSelector((state) => state.taskParams);
  const { data: kpiTaskNames } = useGetTaskNamesQuery();
  const { data: tasks, isLoading } = useGetKpisUserQuery({
    company_uuid: userId,
    user_access_uuid: access,
  });
  //console.log("tasks", tasks);

  const getType = (num: number) => {
    return kpiValueTypeArr.filter((type) => type.value === num);
  };

  const getTaskName = (id: number) => {
    return kpiTaskNames?.data?.find((item) => item.id === id);
  };

  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => {}}
          isLoading={isLoading}
          data={tasks?.data}
          total={tasks?.data.length ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  KPI Task
                </th>
                <th scope="col" className="px-6 py-3">
                  KPI Description
                </th>
                <th scope="col" className="px-6 py-3">
                  KPI Value Type
                </th>
                <th scope="col" className="px-6 py-3">
                  KPI Target
                </th>
                <th scope="col" className="px-3 py-3">
                  KPI Target Assigned
                </th>
              </tr>
            </thead>
            <tbody>
              {tasks?.data?.map((item) => {
                return (
                  <tr
                    key={item.uuid}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">
                      {getTaskName(item.task_id)
                        ? getTaskName(item.task_id)?.name
                        : ""}
                    </td>
                    <td className="px-6 py-4">{item.description}</td>
                    <td className="px-6 py-4">
                      {getType(item.kpi_value_type)[0].name}
                    </td>
                    <td className="px-6 py-4">
                      {item.kpi_value_type === 1
                        ? item.kpi_target + "%"
                        : item.kpi_target}
                    </td>
                    <td className="px-6 py-4">
                      {item.kpi_target_assigned === null
                        ? "Auto generated"
                        : item.kpi_target_assigned}
                    </td>
                    {/* <td className="px-6 py-4">
                      {item.kpi_performance_quantity
                        ? "Auto generated"
                        : item.kpi_performance_quantity}
                    </td>
                    <td className="px-6 py-4">
                      {item.kpi_performance_percentange}%
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CustomPagination>
      </div>
    </div>
  );
};

export default TasksTable;
