import { Button, Label, Select, TextInput } from "flowbite-react";
import React, { FormEvent, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CiUser } from "react-icons/ci";
import { FaPhoneAlt } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { useCreateCompanyMutation } from "../redux/queries/company";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import toast from "react-hot-toast";

const NewCustomer = (props: UI) => {
  const { close } = props;
  const [ createCustomer, { isLoading } ] = useCreateCompanyMutation()

  const [address, setAddress] = useState({
    name: "",
    lat: 0,
    lng: 0,
  });

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if(!address.name) {
      toast.error("Please enter address")
      return
    }
    const formEntries = new FormData(e.target as HTMLFormElement);
    const payload = {
      name: formEntries.get('name') as string,
      email: formEntries.get('email') as string,
      phone: formEntries.get('phone') as string,
      status: formEntries.get('status') as string,
      address
    }

    const res = await createCustomer({ ...payload, type: "pharmacy" })
    if("data" in res){
      if(res.data.status === 200){
        toast.success("Customer Created");
        (e.target as HTMLFormElement).reset();
        close()
        //@ts-ignore
      }else toast.error(res.data?.data?.join("-") ?? "Unable to create customer")
    }else toast.error("Unable to create customer")
  }
  return (
    <div className="h-full flex flex-col bg-white dark:bg-gray-800 min-w-[450px]">
      <div className="flex justify-between items-center px-3 py-3 border-b border-gray-300">
        <h4 className="font-semibold dark:text-white">New Customer</h4>
        <span onClick={close} className="cursor-pointer">
          <AiOutlineCloseCircle className="dark:text-white" />
        </span>
      </div>
      <form onSubmit={handleFormSubmit} className="px-3 mt-3 flex flex-col justify-between flex-1">
        <div>
          <div className="max-w-md mb-3">
            <Label htmlFor="name" value="Company Name" className="text-xs" />
            <TextInput
              id="name"
              name="name"
              disabled={isLoading}
              required
              type="text"
              className="flex-1 mt-1"
              icon={CiUser}
              sizing="sm"
              placeholder="Company Name"
              style={{ borderRadius: 2 }}
            />
          </div>

          <div className="max-w-md mb-3">
            <Label htmlFor="email" value="Email" className="text-xs" />
            <TextInput
              id="email"
              type="email"
              disabled={isLoading}
              required
              name="email"
              icon={MdAlternateEmail}
              className="flex-1 mt-1"
              sizing="sm"
              placeholder="Email"
              style={{ borderRadius: 2 }}
            />
          </div>

          <div className="max-w-md mb-3">
            <Label htmlFor="phone" value="Phone" className="text-xs" />
            <TextInput
              id="phone"
              name="phone"
              disabled={isLoading}
              required
              icon={FaPhoneAlt}
              type="number"
              step="any"
              className="flex-1 mt-1"
              sizing="sm"
              placeholder="Phone"
              style={{ borderRadius: 2 }}
            />
          </div>

          <div className="max-w-md mb-3">
            <div className=" block">
              <Label htmlFor="address" value="Address" />
            </div>
            <ReactGoogleAutocomplete
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              onPlaceSelected={(place) => {
                setAddress({
                  name: place.formatted_address,
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                });
              }}
            />
          </div>

          <div className="max-w-md mb-3">
            <Label htmlFor="status" value="Status" className="text-xs" />
            <Select disabled={isLoading} name="status" required sizing="sm" style={{ borderRadius: 2 }} className="mt-1">
              <option value="1">Active</option>
              <option value="0">InActive</option>
            </Select>
          </div>
        </div>

        <Button disabled={isLoading} isProcessing={isLoading} type="submit" className="rounded-xs mb-4">Save</Button>
      </form>
    </div>
  );
};

export default NewCustomer;
