import { Button, TextInput, ToggleSwitch } from "flowbite-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from "../../redux/queries/company";
import { setDuplicateCart } from "../../redux/slices/cart";
import ReOrder from "./ReOrder";

const Store = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.appUserConfig);
  const { duplicateItem } = useAppSelector((state) => state.cartAndDraft);
  const [markup, setMarkup] = useState(0);
  const { data: company } = useGetCompanyQuery(user?.company.uuid ?? "");
  const [updateCompany, { isLoading }] = useUpdateCompanyMutation();

  useEffect(() => {
    if (company) setMarkup(company.data.markup);
  }, [company]);

  const submit = async () => {
    const res = await updateCompany({
      name: user?.company.name as string,
      phone: user?.company.phone as string,
      email: user?.company.email as string,
      type: user?.company.type as string,
      uuid: user?.company.uuid,
      markup,
    });
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record updted");
      } else toast.error("Unable to update");
    } else toast.error("Unable to update");
  };

  return (
    <div>
      <div className="mt-4">
        <div className="h-11 rounded-t-lg flex justify-start items-center px-4">
          <span className="dark:text-gray-300 font-semibold">
            Store Settings
          </span>
        </div>
        <div className="p-4">
          <div className="mb-8">
            <div>
              <ToggleSwitch
                checked={duplicateItem}
                label="Duplicate item already in list/cart"
                onChange={(val) => dispatch(setDuplicateCart())}
              />
            </div>
          </div>
          <div className="flex gap-3 items-center">
            <span className="dark:text-white">General Markup: </span>{" "}
            <div>
              <TextInput
                id="old"
                type="number"
                step="any"
                disabled={isLoading}
                value={markup?.toString()}
                onChange={(e) => setMarkup(Number(e.target.value))}
                autoComplete="off"
                className="hide-spin-buttons"
              />
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <Button
              onClick={submit}
              size="xs"
              className="rounded-sm"
              disabled={isLoading}
              isProcessing={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <div className="border border-gray-400 " />
      <ReOrder />
    </div>
  );
};

export default Store;
