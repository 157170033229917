import { Button, Label, TextInput } from "flowbite-react";
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { TbCurrencyNaira } from "react-icons/tb";

interface IDiscount extends UI {
  updateDiscount: (val: string)=> void
  discount: string
}
const Discount = (props: IDiscount) => {
  const { close, updateDiscount, discount } = props;
  return (
    <div className="h-full flex flex-col bg-white dark:bg-gray-800 min-w-[450px]">
      <div className="flex justify-between items-center px-3 py-3 border-b border-gray-300">
        <h4 className="font-semibold dark:text-white">Order Discount</h4>
        <span onClick={close} className="cursor-pointer">
          <AiOutlineCloseCircle className="dark:text-white" />
        </span>
      </div>

      <div className="px-3 mt-3 flex flex-col justify-between flex-1">
        <div>
          <div className="max-w-md">
            <div className="mb-2 block">
              <Label
                htmlFor="discount"
                value="Discount Amount"
                className="text-xs"
              />
            </div>
            <div className="flex gap-1">
              <TextInput
                id="discount"
                rightIcon={TbCurrencyNaira}
                value={discount}
                type="number"
                step="any"
                className="flex-1"
                sizing="sm"
                onChange={(e)=> updateDiscount(e.target.value)}
                style={{ borderRadius: 2 }}
              />
              <Button size="xs" className="rounded-xs">Add</Button>
            </div>
          </div>

          <div className="max-w-md mt-5">
            <div className="mb-2 block">
              <Label htmlFor="coupon" value="Coupon" className="text-xs" />
            </div>
            <div className="flex gap-1">
              <TextInput
              sizing="sm"
                id="coupon"
                type="text"
                className="flex-1"
                style={{ borderRadius: 2 }}
              />
              <Button size="xs" className="rounded-xs">Apply</Button>
            </div>
          </div>
        </div>

        <Button onClick={close} className="rounded-xs mb-4">Add Discount</Button>
      </div>
    </div>
  );
};

export default Discount;
