import { Button } from "flowbite-react";
import { useEffect, useState } from "react";
import TimeInput from "../../components/TimeInput";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import { weekDays } from "../../route/data";
import {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from "../../redux/queries/company";
import toast from "react-hot-toast";
import { updateUserCompanyRecord } from "../../redux/slices/appUser";

interface ScheduleType {
  day: number;
  start: string | null;
  end: string | null;
  open: boolean;
  onToggle?: ({ toggle, day }: ToggleType) => void;
  onChange?: ({ day, position, value }: onChangeType) => void;
}

interface ToggleType {
  toggle: boolean;
  day: number;
}

interface onChangeType {
  day: number;
  position: string;
  value: string;
}

const Schedule = () => {
  const { user, defaultBranchUUid } = useAppSelector(
    (state) => state.appUserConfig
  );
  const [schedules, setSchedules] = useState<ScheduleType[]>();

  const [updateCompany, { isLoading }] = useUpdateCompanyMutation();
  const dispatch = useAppDispatch();
  const { data: company } = useGetCompanyQuery(defaultBranchUUid ?? "");
  console.log(company);

  useEffect(() => {
    const fetchedSchedule = () => {
      if (!user?.company?.schedule) return;

      const scheduleData = user.company.schedule.map(({ day, time }) => {
        if (!time.length) {
          return { day: day, start: null, end: null, open: false };
        } else {
          const [start, end] = time[0].split(" - ");
          return { day: day, start: start, end: end, open: true };
        }
      });
      setSchedules(scheduleData);
    };
    fetchedSchedule();
  }, [user?.company?.schedule]);

  const onChange = ({ day, position, value }: onChangeType) => {
    setSchedules(
      schedules?.map((schedule) =>
        schedule.day === day ? { ...schedule, [position]: value } : schedule
      )
    );
  };

  const onToggle = ({ toggle, day }: ToggleType) => {
    if (toggle) {
      setSchedules(
        schedules?.map((schedule) =>
          schedule.day === day
            ? {
                ...schedule,
                start: "00:00",
                end: "12:00",
                open: true,
              }
            : schedule
        )
      );
    } else {
      setSchedules(
        schedules?.map((schedule) =>
          schedule.day === day
            ? { ...schedule, start: null, end: null, open: false }
            : schedule
        )
      );
    }
  };

  const onSubmit = async () => {
    const newSchedule = schedules?.map(({ day, start, end, open }) => {
      if (open) {
        return { day: day, time: [`${start} - ${end}`] };
      } else {
        return { day: day, time: [] };
      }
    });
    const payload = {
      uuid: user?.company?.uuid,
      schedule: newSchedule,
    };
    const res = await updateCompany(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record updted");
        dispatch(updateUserCompanyRecord(res.data.data));
      } else toast.error("Unable to update");
    } else toast.error("Unable to update");
  };

  return (
    <div className="flex items-center justify-center mt-10">
      <div className="px-5 xl:px-20 w-full">
        <h4 className="text-2xl">Set Standard Hours</h4>
        <div className="mt-3 flex flex-col gap-3 mb-1 px-2">
          {schedules?.map(({ day, start, end, open }: ScheduleType) => (
            <EachDay
              key={day}
              day={day}
              start={start}
              end={end}
              open={open}
              onToggle={onToggle}
              onChange={onChange}
            />
          ))}
        </div>
        <div className="mt-10 flex items-center justify-end">
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            size="sm"
            className="rounded-sm"
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Schedule;

const EachDay = ({
  day,
  start,
  end,
  open,
  onToggle,
  onChange,
}: ScheduleType) => {
  return (
    <div className="flex items-center justify-between">
      <span className="text-lg">{weekDays[day]}</span>

      <div className="flex items-center gap-5">
        <div
          onClick={() => onToggle && onToggle({ toggle: !open, day })}
          className={`relative w-14 h-7 flex justify-between items-center rounded-full py-1 px-2 cursor-pointer ${
            open ? "bg-[#167490]" : "bg-gray-300"
          }`}
        >
          <div
            className={`absolute bg-white w-5 h-5 rounded-full shadow-md transform duration-300 ease-in-out ${
              open ? "translate-x-5" : ""
            }`}
          />
        </div>

        <div className="flex items-center gap-3">
          <TimeInput
            disable={!open}
            value={start ? start : ""}
            onChange={(e: any) =>
              onChange &&
              onChange({ day, position: "start", value: e.target.value })
            }
          />
          <span>To</span>
          <TimeInput
            disable={!open}
            value={end ? end : ""}
            onChange={(e: any) =>
              onChange &&
              onChange({ day, position: "end", value: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
};
