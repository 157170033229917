import { api } from "../baseConfig";

interface IPayload {
  name: string;
  company: string;
}

interface IShelfItem {
  shelf_uuid: string;
  items: { quantity: number; uuid: string }[];
}

interface IUpdate {
  data: SingleShelf;
  uuid: string;
}

export const shelfSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    createShelf: builder.mutation<Shelf, IPayload>({
      query: (data) => ({
        url: "/shelf",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Shelfs"],
    }),
    getShelfs: builder.query<IShelfs, { name?: string; company: string }>({
      query: (data) => ({
        url: "/shelf/items",
        method: "GET",
        params: data,
      }),
      providesTags: ["Shelfs"],
    }),
    deleteShelf: builder.mutation<any, string>({
      query: (id) => ({
        url: `/shelf/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Shelfs"],
    }),
    deleteShelfStock: builder.mutation<any, string>({
      query: (id) => ({
        url: `/shelf/stock/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Shelfs"],
    }),
    addItemToShelf: builder.mutation<any, IShelfItem>({
      query: (data) => ({
        url: "/shelf/items",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Shelfs"],
    }),
    updateShelf: builder.mutation<any, IUpdate>({
      query: (payload) => ({
        url: `/shelf/${payload.uuid}`,
        method: "PUT",
        body: payload.data,
      }),
      invalidatesTags: ["Shelfs"],
    }),
    moveItem: builder.mutation<any, any>({
      query: (data) => ({
        url: "/shelf/move_to_another_shelf",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Shelfs"],
    }),
  }),
});

export const {
  useCreateShelfMutation,
  useGetShelfsQuery,
  useDeleteShelfMutation,
  useAddItemToShelfMutation,
  useUpdateShelfMutation,
  useDeleteShelfStockMutation,
  useMoveItemMutation,
} = shelfSlice;
