import { api } from "../baseConfig";

interface PayloadFilter {
  limit: string;
  company?: string;
  user?: string;
  type?: number;
  paid?: number;
  verified?: number;
  invoice?: number;
  buyer?: string;
  order?: number;
}

interface ReturnPayload {
  uuid: string;
  items: {
    uuid: string;
    quantity: string;
    reason: string;
  }[];
  refund_type: string;
}

export const orderSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<OrderResponse, PayloadFilter>({
      query: (filters) => ({
        url: "/v2/company/orders",
        method: "GET",
        params: filters,
      }),
      providesTags: ["Orders"],
    }),
    getOrder: builder.query<SingleOrderResponse, string>({
      query: (id) => ({
        url: `/v2/company/orders/${id}`,
        method: "GET",
      }),
      providesTags: ["Orders"],
    }),
    createOrder: builder.mutation<CreateOrderResponse, OrderPayload>({
      query: (payload) => ({
        url: "/pos/order",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Orders"],
    }),
    returnOrder: builder.mutation<any, ReturnPayload>({
      query: (payload) => ({
        url: "/orders/return",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Orders"],
    }),
    returnWithOutId: builder.mutation<any, any>({
      query: (payload) => ({
        url: "orders/return_without_id",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Orders"],
    }),
    getStockList: builder.query<IStockHistoryResponse, any>({
      query: (payload) => ({
        url: "/v2/company/orders",
        method: "GET",
        params: { type: 12, ...payload },
      }),
      providesTags: ["Stocks"],
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `/orders/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Orders"],
    }),
    assignRider: builder.mutation<any, { order: string, rider: string }>({
      query: (payload) => ({
        url: '/v2/company/orders/assignrider',
        method: "POST",
        params: payload
      }),
      invalidatesTags: ["Orders"],
    }),
    updateOrder: builder.mutation<CreateOrderResponse, OrderPayload>({
      query: (payload) => ({
        url: "/pos/order/edit",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Orders"],
    }),
    partialPayment: builder.mutation({
      query: (payload) => ({
        url: "/pos/order/pay",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Orders"],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useCreateOrderMutation,
  useGetOrderQuery,
  useLazyGetOrderQuery,
  useLazyGetOrdersQuery,
  useDeleteOrderMutation,
  useUpdateOrderMutation,
  useGetStockListQuery,
  useReturnOrderMutation,
  usePartialPaymentMutation,
  useReturnWithOutIdMutation,
  useAssignRiderMutation
} = orderSlice;
