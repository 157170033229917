import Breadcrumb from "../../components/Breadcrumb";
import {
  Button,
  Datepicker,
  Select,
  TextInput,
  Textarea,
} from "flowbite-react";
import { FaPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LiaTimesSolid } from "react-icons/lia";
import { useAppSelector } from "../../lib/hook";
import {
  useAddExpenseMutation,
  useGetExpensesQuery,
} from "../../redux/queries/expenses";
import moment from "moment";
import toast from "react-hot-toast";
import CustomPagination from "../../components/CustomPagination";
import BranchSwitcher from "../../components/BranchSwitcher";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomSelect from "../../components/CustomSelect";
import { useGetCompaniesQuery } from "../../redux/queries/company";

const Expenses = () => {
  const [open, setOpen] = useState(false);
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [active, setActive] = useState(0);

  const [filters, setFilters] = useState({
    limit: "0,10",
    company: user?.company?.uuid,
    start_date: "",
    end_date: "",
    other_party: "",
  });
  const [resetValue, setResetValue] = useState(false);
  const [filterFields, setFilterFields] = useState({
    start_date: "",
    end_date: "",
  });

  const { data: expensesData, isLoading } = useGetExpensesQuery(filters);
  const [companiesFilters, setCompaniesFilters] = useState({
    limit: "0,10",
    status: 1,
    name: "",
  });
  const { data: companies, isLoading: isLoadingCompanies } =
    useGetCompaniesQuery(companiesFilters);

  useEffect(() => {
    if (expensesData?.data.length) {
      setFilterFields((prev) => ({
        ...prev,
        start_date: moment(
          expensesData?.data[expensesData?.data.length - 1]?.crdate
        ).format("YYYY-MM-DD"),
        end_date: moment(expensesData?.data[0]?.crdate).format("YYYY-MM-DD"),
      }));
    }
  }, [expensesData?.data]);

  const todayFn = () => {
    setActive(1);
    if (expensesData?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const yesterdayFn = () => {
    setActive(2);
    if (expensesData?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment()
          .startOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .endOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const lastWeekFn = () => {
    setActive(3);
    if (expensesData?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const reset = () => {
    setActive(0);
    setFilters({
      limit: "0,10",
      company: user?.company?.uuid,
      start_date: "",
      end_date: "",
      other_party: "",
    });
    setResetValue(true);
  };

  return (
    <div>
      <Breadcrumb
        title="Expenses History"
        rightButton={
          <Button size="sm" onClick={() => setOpen(true)}>
            <FaPlus className="mr-2" /> Add Expense
          </Button>
        }
      />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm flex flex-wrap gap-3 items-center">
        <div className="flex items-center justify-between gap-2">
          <Button size="xs" onClick={todayFn} outline={active !== 1}>
            Today
          </Button>
          <Button size="xs" onClick={yesterdayFn} outline={active !== 2}>
            Yesterday
          </Button>
          <Button size="xs" onClick={lastWeekFn} outline={active !== 3}>
            Last Week
          </Button>
        </div>
        <CustomDatePicker
          start={
            filters.start_date.length
              ? filters.start_date
              : filterFields.start_date
          }
          onStartChange={(date) =>
            setFilters((prev) => ({
              ...prev,
              start_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
            }))
          }
          end={
            filters.end_date.length ? filters.end_date : filterFields.end_date
          }
          onEndChange={(date) =>
            setFilters((prev) => ({
              ...prev,
              end_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
            }))
          }
        />

        <BranchSwitcher
          sizing="sm"
          defaultCompany={filters.company}
          onChange={(value) =>
            setFilters((prev) => ({ ...prev, company: value }))
          }
        />
        <div className="w-[200px]">
          <CustomSelect
            isClearable={false}
            disabled={isLoading || isLoadingCompanies}
            loading={isLoading || isLoadingCompanies}
            placeholder="Select Customer..."
            inputClasses="h-9"
            options={
              companies?.data.map((customer) => ({
                label: customer.name,
                value: customer.uuid,
              })) ?? []
            }
            onSelect={(val) => {
              setFilters((prev) => ({
                ...prev,
                other_party: val.value,
              }));
              setResetValue(false);
            }}
            onSearch={(text) => {
              setCompaniesFilters((prev) => ({ ...prev, name: text }));
              setResetValue(false);
            }}
            reset={resetValue}
          />
        </div>
        <div className="flex gap-2 ml-auto items-end justify-end">
          <Button outline size="xs" onClick={reset}>
            Reset
          </Button>
        </div>
      </div>
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={expensesData?.data}
            total={expensesData?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Expenses
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Description
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Withdrawal Account
                  </th>
                </tr>
              </thead>
              <tbody>
                {expensesData?.data?.map((item: Expense) => (
                  <tr
                    key={item.uuid}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">
                      {moment(item.crdate).format("LLL")}
                    </td>
                    <td className="px-6 py-4">
                      {item.name
                        ? item.name?.charAt(0).toUpperCase() +
                          item.name?.slice(1)
                        : ""}
                    </td>
                    <td className="px-6 py-4">
                      {item.description.charAt(0).toUpperCase() +
                        item.description.slice(1)}
                    </td>
                    <td className="px-6 py-4">
                      {Number(item.amount) > 0
                        ? `+${item.amount}`
                        : item.amount}
                    </td>
                    <td className="px-6 py-4">{item.gateway}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={open}
        onClose={() => setOpen(false)}
      >
        <AddExpense
          close={() => setOpen(false)}
          companyId={user?.company?.uuid}
        />
      </Modal>
    </div>
  );
};

export default Expenses;

interface UserCard {
  companyId: string | undefined;
  close: () => void;
}

interface IFormInputExp {
  name: string;
  description: string;
  amount: string;
  gateway: string;
}

const schemaAddUser = yup.object().shape({
  name: yup.string().required("Expense Name is a required field"),
  description: yup
    .string()
    .required("Expenses description is a required field"),
  amount: yup.string().required("Amount is a required field"),
  gateway: yup.string().required("Account is a required field"),
});

const AddExpense = (props: UserCard) => {
  const { companyId, close } = props;
  const [addExpense, { isLoading }] = useAddExpenseMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputExp>({ resolver: yupResolver(schemaAddUser) });

  const onSubmit = async (data: IFormInputExp) => {
    const payload = {
      company: companyId,
      name: data.name,
      amount: Number(data.amount),
      gateway: data.gateway,
      description: data.description,
    };

    try {
      const { data } = (await addExpense(payload)) as any;
      if (data?.status === 200) {
        toast.success("Expense Added!!!");
        close();
      } else if (data?.status === 100) {
        toast.error(data?.data);
      }
    } catch (error) {
      toast.error("Failed to Add!!!");
    }
  };

  return (
    <div className="w-[500px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Add Expense</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <form className="bg-white p-3 pb-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-3 mt-2">
          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Expense Name
            </span>
            <TextInput
              disabled={isLoading}
              sizing="md"
              type="text"
              placeholder="Enter Expense Name"
              {...register("name")}
              color={errors?.name ? "failure" : "gray"}
              helperText={
                errors.name && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.name?.message}!
                  </span>
                )
              }
            />
          </div>

          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Expenses Description
            </span>
            <Textarea
              disabled={isLoading}
              id="description"
              placeholder="Put down a description..."
              rows={5}
              {...register("description")}
              color={errors?.description ? "failure" : "gray"}
              helperText={
                errors.description && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.description?.message}!
                  </span>
                )
              }
            />
          </div>

          <div className="grid grid-cols-2 gap-3 mt-1">
            <div className="">
              <span className="text-xs dark:text-gray-800 font-semibold mb-2">
                Withdrawal Account
              </span>

              <Select
                disabled={isLoading}
                sizing="md"
                {...register("gateway")}
                color={errors?.gateway ? "failure" : "gray"}
                helperText={
                  errors.gateway && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.gateway?.message}!
                    </span>
                  )
                }
              >
                <option value="" hidden>
                  Select a withdrawal account
                </option>
                <option value="cash">Cash</option>
              </Select>
            </div>

            <div>
              <span className="text-xs dark:text-gray-800 font-semibold mb-2">
                Amount
              </span>
              <TextInput
                disabled={isLoading}
                sizing="md"
                type="number"
                step="any"
                placeholder="Enter Expense Amount"
                {...register("amount")}
                color={errors?.amount ? "failure" : "gray"}
                helperText={
                  errors.amount && (
                    <span className="font-medium text-[10px]">
                      {" "}
                      {errors.amount?.message}!
                    </span>
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="mt-5 flex items-center justify-end">
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            size="sm"
            type="submit"
            className="rounded-xs"
          >
            Add
          </Button>
        </div>
      </form>
    </div>
  );
};
