import { Button, Label, Radio, TextInput } from "flowbite-react";
import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCash } from "react-icons/bs";
import { GiReceiveMoney } from "react-icons/gi";
import { MdCreditCard } from "react-icons/md";
import utills from "../../lib/functions";
import { LuWallet } from "react-icons/lu";
import { useGetUserQuery } from "../../redux/queries/users";
import { useAppSelector } from "../../lib/hook";
import { FaEdit, FaTruck } from "react-icons/fa";
import { CgCheckO } from "react-icons/cg";
import { usePartialPaymentMutation } from "../../redux/queries/order";
import toast from "react-hot-toast";
import { HiOutlineTicket } from "react-icons/hi";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import { ChequeData } from "../../POS/PlaceOrder";

interface IPaynow {
  close: () => void;
  total?: number;
  uuid?: string
  order?: CompanyOrder
  customer: {
    label: string;
    value: string;
  } | null;
}
const PayNow = (props: IPaynow) => {
  const { close, customer, total, uuid, order } = props;
  const { cart } = useAppSelector((state) => state.cartAndDraft);
  const { data: user } = useGetUserQuery(customer?.value ?? "");
  const [ partialPayment , { isLoading }] = usePartialPaymentMutation()

  const [paymentMode, setPaymentMode] = useState("0");
  const [fee, setFee] = useState(order?.delivery_price.toString() ?? '');
  const [customerBalance, setCustomerBalance] = useState(0);
  const [amountPaid, setAmountPaid] = useState("");
  const [chequeProps, setChequeProps] = useState({
    cheque_no: "",
    cheque_post_date: "",
    bank_name: ""
  });
  const [chequeModal, setChequeModal] = useState(false);

  const getTotalPrice = () => {
    const totals = utills._total_item_in_cart(cart);
    const formatedPrice = utills._currency_format(
      totals.totalPriceInNaira,
      "NGN"
    );
    return formatedPrice;
  };

  const calCustomerBal = (val: string) => {
    setAmountPaid(val);
    const { totalPriceInNaira } = utills._total_item_in_cart(cart);
    const bal = Number(val) - totalPriceInNaira;
    setCustomerBalance(bal);
  };

  const totalAmount = total ?? getTotalPrice();

  const handlePayment = (val: string) => {
    if (val === "5") {
      setChequeModal(true);
    }
    setPaymentMode(val);
  };

  const submit = async () => {
    if(Number(amountPaid) === 0){
        toast.error("Please enter amount paid")
        utills._play_error_sound()
        return
      }
    const payload = {
        payment_mode: paymentMode,
        amount_paid: amountPaid,
        bank_name: '',
        uuid
    }

    if(paymentMode === "5"){
      //@ts-ignore
      payload.cheque_no = chequeProps.cheque_no
      payload.bank_name = chequeProps.bank_name
      //@ts-ignore
      payload.cheque_post_date = chequeProps.cheque_post_date
    }
    const res = await partialPayment(payload)
    if("data" in res){
        if(res.data.status === 200){
            toast.success(res.data.message ?? "Record Updated")
            close()
        }else toast.error(res.data.message ?? "Unable to update record")
    }else toast.error("Unable to update record")
  } 

  return (
    <div className="h-full flex flex-col bg-white dark:bg-gray-800 min-w-[450px]">
      <div className="flex justify-between items-center px-3 py-3 border-b border-gray-300">
        <h4 className="font-semibold dark:text-white">Payment</h4>
        <span className="cursor-pointer" onClick={close}>
          <AiOutlineCloseCircle className="dark:text-white" />
        </span>
      </div>

      <div className="px-3 flex flex-col justify-between flex-1">
        <div>
          <fieldset className="flex max-w-md flex-col gap-4 mt-2">
            <Label value="Payment Method" />
            <div className="flex items-center gap-2">
              <Radio
                id="cash"
                name="payment"
                value={0}
                defaultChecked
                onChange={(e) => handlePayment(e.target.value)}
              />
              <Label className="flex items-center" htmlFor="cash">
                <BsCash className="mr-2" /> Cash
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="card"
                name="payment"
                value={2}
                onChange={(e) => handlePayment(e.target.value)}
              />
              <Label className="flex items-center" htmlFor="card">
                <MdCreditCard className="mr-2" /> Card
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="cheque"
                name="payment"
                value={5}
                onChange={(e) => handlePayment(e.target.value)}
              />
              <Label className="flex items-center" htmlFor="cheque">
                <HiOutlineTicket className="mr-2" /> Cheque{" "}
                {paymentMode === "5" && <span className="text-xs ml-2">
                  (
                  {`No: ${chequeProps.cheque_no} - Date: ${chequeProps.cheque_post_date}`}
                  )
                </span>}
                {paymentMode === "5" && <button
                  className="text-green-700 ml-2"
                  onClick={() => setChequeModal(true)}
                >
                  <FaEdit />
                </button>}
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="card"
                name="payment"
                value={4}
                onChange={(e) => handlePayment(e.target.value)}
              />
              <Label className="flex items-center" htmlFor="loan">
                <GiReceiveMoney className="mr-2" /> Loan{" "}
                <span className="text-xs ml-2">
                  (
                  {utills._currency_format(user?.data.company.loan ?? 0, "NGN")}
                  )
                </span>
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                id="card"
                name="payment"
                value={3}
                onChange={(e) => handlePayment(e.target.value)}
              />
              <Label className="flex items-center" htmlFor="wallet">
                <LuWallet className="mr-2" /> Wallet{" "}
                <span className="text-xs ml-2">
                  (
                  {utills._currency_format(
                    user?.data.company.wallet ?? 0,
                    "NGN"
                  )}
                  )
                </span>
              </Label>
            </div>
          </fieldset>
          <div className="border-t mt-3 border-gray-300 dark:border-gray-600">
            <Label value="Summary" className="ml-2" />
            <div className="text-[13px] dark:text-gray-300 mt-3">
              <div className="flex justify-between items-center bg-gray-200 dark:bg-gray-700 p-2 mb-1 rounded-sm">
                <span>Tax:</span>
                <span className="font-semibold">
                  {utills._currency_format(0, "NGN")}
                </span>
              </div>

              <div className="flex justify-between items-center bg-gray-200 dark:bg-gray-700 p-2 mb-1 rounded-sm">
                <span>Total Amount:</span>
                <span className="font-semibold">{utills._currency_format(order ? order.total_price - order.amount_paid : 0, "NGN")}</span>
              </div>
            </div>

            <div className="mt-3">
              <div className="mb-2 block">
                <Label htmlFor="amount" value="Amount Paid By Customer" />
              </div>
              <TextInput
                sizing="sm"
                id="amount"
                onChange={(e) => setAmountPaid(e.target.value)}
                value={amountPaid}
                type="number"
                step="any"
                placeholder="Amount Paid By Customer"
                shadow
                style={{ borderRadius: 2 }}
              />
            </div>
            {order && <div className="flex justify-end items-center pt-1 dark:text-gray-300 text-[13px]">
              <span className="mr-2">Balance:</span>
              <span
                className={`font-semibold ${
                  customerBalance <  (total ?? 0) && "text-red-400"
                }`}
              >
                {utills._currency_format((order.total_price - order.amount_paid) - Number(amountPaid), "NGN")}
              </span>
            </div>}

            <div className="max-w-md mb-3">
              <Label htmlFor="fee" value="Delivery fee" className="text-xs" />
              <TextInput
                id="fee"
                icon={FaTruck}
                value={fee}
                onChange={(e) => setFee(e.target.value)}
                type="number"
                step="any"
                className="flex-1 mt-1"
                sizing="sm"
                placeholder="Delivery fee"
                style={{ borderRadius: 2 }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="px-3 mb-2">
        <Button
          isProcessing={isLoading}
          disabled={isLoading}
          className="rounded-sm w-full"
          size="xs"
          onClick={submit}
        >
          <CgCheckO className="mr-2 h-5 w-5" />
          Place Order
        </Button>
      </div>

      <Modal
        open={chequeModal}
        onClose={() => setChequeModal(false)}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        showCloseIcon={false}
      >
        <div className="w-[400px]">
          <div className="bg-[#167490] flex items-center justify-between h-10 px-3">
            <span className="text-white font-semibold text-sm">
              Enter Cheque Info
            </span>
            <span
              className="text-white cursor-pointer"
              onClick={() => setChequeModal(false)}
            >
              <LiaTimesSolid />
            </span>
          </div>
          <ChequeData
            chequeProps={chequeProps}
            handleCheckRecord={(data) =>
              setChequeProps((prev) => ({ ...prev, ...data }))
            }
            close={()=> setChequeModal(false)}
          />
        </div>
      </Modal>

    </div>
  );
};

export default PayNow;
