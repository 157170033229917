import React, { useEffect } from "react";
// import { useAppSelector } from "../lib/hook";
// import utills from "../lib/functions";
import { useLazyGetOrderQuery } from "../redux/queries/order";
// import moment from "moment";
import InvoiceDoc from "../components/Documents/Invoice"

interface InvoicePdfProps {
  order: CompanyOrder | null;
  isOpen: boolean
  close: () => void
}

const InvoicePdf = (props: InvoicePdfProps) => {
    const { order, isOpen, close } = props;
    // const { user } = useAppSelector((state) => state.appUserConfig);

    const [getOrder, { data: postedOrder }] = useLazyGetOrderQuery();

    // console.log(postedOrder);
    useEffect(() => {
      if (order?.uuid) getOrder(order.uuid);
    }, [order]);
    return (
      // <div className="py-4 px-10 bg-white" ref={ref}>
      //   <div className="pt-5">
      //     <h3 className="font-extrabold text-[19px]">{user?.company.name}</h3>
      //   </div>
      //   <div className="flex justify-between mt-8">
      //     <div>
      //       <div className="mb-4 font-semibold text-sm">
      //         {postedOrder?.data.buyer_details.name}
      //       </div>
      //       <div className="text-xs">
      //         <div>
      //           Invoice Date:{" "}
      //           <span>
      //             {moment(postedOrder?.data.created_at).format("LLL")}
      //           </span>
      //         </div>
      //         <div>
      //           Invoice No: <span>#{order?.id}</span>
      //         </div>
      //       </div>
      //     </div>

      //     <div className="flex flex-col items-end text-xs">
      //       <span>{postedOrder?.data.order.buyer.name} - {postedOrder?.data.order.buyer.type}</span>
      //       {/* <span>123 Main street</span>
      //       <span>Lagos nigeria</span> */}
      //       <span>{postedOrder?.data.order.buyer.email}</span>
      //       <span>{postedOrder?.data.order.buyer.phone}</span>
      //     </div>
      //   </div>

      //   <div className="mt-8 w-full ">
      //     <div className="relative overflow-x-auto">
      //       <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
      //         <thead className="text-xs text-gray-700 uppercase bg-gray-50">
      //           <tr>
      //             <th scope="col" className="px-6 py-3 w-5">
      //               QTY
      //             </th>
      //             <th scope="col" className="px-6 py-3">
      //               Description
      //             </th>
      //             <th scope="col" className="px-6 py-3">
      //               Price
      //             </th>
      //             <th scope="col" className="px-6 py-3">
      //               Subtotal
      //             </th>
      //           </tr>
      //         </thead>
      //         <tbody>
      //           {order?.items.map((cart) => (
      //             <tr className="bg-white border-b " key={cart.uuid}>
      //               <th
      //                 scope="row"
      //                 className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
      //               >
      //                 {cart.quantity}
      //               </th>
      //               <td className="px-6 py-4">{cart.name}</td>
      //               <td className="px-6 py-4">
      //                 {utills._currency_format(Number(cart.price), "NGN")}
      //               </td>
      //               <td className="px-6 py-4">
      //                 {utills._currency_format(
      //                   Number(cart.price) * cart.quantity,
      //                   "NGN"
      //                 )}
      //               </td>
      //             </tr>
      //           ))}
      //           <tr className="bg-white border-b ">
      //             <th
      //               scope="row"
      //               className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
      //             ></th>
      //             <td className="px-6 py-4"></td>
      //             <td className="py-4 text-right">
      //               <div className="font-semibold">Total:</div>
      //             </td>
      //             <td className="px-6 py-4">
      //               {utills._currency_format(order?.total_price ?? 0, "NGN")}
      //             </td>
      //           </tr>
      //         </tbody>
      //       </table>
      //     </div>

      //     <div className="mt-10 font-semibold">
      //       <span>Thank You</span>
      //     </div>
      //   </div>
      // </div>
      <InvoiceDoc 
      isOpen={isOpen}
      close={close}
      order={postedOrder?.data}
      />
    );
  }

export default InvoicePdf;
