import { api } from "../baseConfig";

interface IAddToCartApiPayload {
  type?: string;
  payment_mode?: string | number;
  buyer?: string;
  amount_paid?: string | number;
  items: {
    name: string;
    price: number;
    quantity: number;
    company: string;
    uuid: string;
    verified?: number;
    variant_id?: number;
  }[];
}

export const cartSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    addItemToCart: builder.mutation<any, IAddToCartApiPayload>({
      query: (body) => ({
        url: "/pos/order/add_to_cart",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Carts"],
    }),
    getCartItems: builder.query<ICartResponse, any>({
      query: (params) => ({
        url: "/pos/order/add_to_cart",
        method: "GET",
        params,
      }),
      providesTags: ["Carts"],
    }),
  }),
});

export const { useAddItemToCartMutation, useGetCartItemsQuery, useLazyGetCartItemsQuery } = cartSlice;
