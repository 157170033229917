import React, { useState, useEffect } from "react";
import { Alert, Button, Label, TextInput } from "flowbite-react";
import Modal from "react-responsive-modal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useUpdateUserMutation } from "../redux/queries/users";
import { useAppDispatch, useAppSelector } from "../lib/hook";
import toast from "react-hot-toast";
import { HiInformationCircle } from "react-icons/hi";
import {
  setPinStatus,
  updateUserRecord,
} from "../redux/slices/appUser";
import { BsSkipEnd } from "react-icons/bs";

interface IFormInput {
  new_pin: string;
  verify_new_pin: string;
  password: string;
}

const schema = yup.object().shape({
  new_pin: yup
    .string()
    .matches(/^\d{4}$/, "New pin must be a 4-digit number")
    .required("New pin is required"),
  password: yup.string().required("Password is required"),
  verify_new_pin: yup
    .string()
    .required("Pin do not match")
    .test("match", "Pin do not match", function (value) {
      return value === this.parent.new_pin;
    }),
});

const PinApplication = () => {
  const dispatch = useAppDispatch();
  const { user, isPinSet } = useAppSelector((state) => state.appUserConfig);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: IFormInput) => {
    const res = await updateUser({
      uuid: user?.uuid,
      pin: data.new_pin,
      password: data.password,
    });
    if ("data" in res) {
      if (res.data.status !== 200) {
        toast.error(res.data.message);
        flashError(res.data.message);
      } else {
        toast.success("Pin set successfully!!!");
        dispatch(updateUserRecord(res.data.data));
        dispatch(setPinStatus(true));
        setIsOpen(false);
      }
    } else toast.error("Error- Action Failed!!!");
  };

  const flashError = (msg: string) => {
    setError(msg);
    setTimeout(() => {
      setError("");
    }, 4000);
  };

  useEffect(() => {
    // if (!isPinSet) setIsOpen(true);
  }, [isPinSet]);

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      blockScroll={false}
      open={isOpen}
      showCloseIcon={false}
      onClose={() => setIsOpen(false)}
      center
      classNames={{
        modalContainer: "__remove_modal_bg",
      }}
    >
      <form
        className="bg-white dark:bg-gray-800 w-[400px] rounded-lg"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="bg-[#167490] rounded-t-lg flex items-center justify-center h-10">
          <span className="text-white font-semibold text-sm">
            Set A New Pin For Your Account
          </span>
        </div>
        <div className="p-5 px-10">
          {error && (
            <Alert color="failure" icon={HiInformationCircle}>
              <span className="font-medium">Error!</span> {error}.
            </Alert>
          )}

          <div>
            <div className="mb-2 block">
              <Label htmlFor="new1" value="New Pin" />
            </div>
            <TextInput
              sizing="sm"
              id="new1"
              className="hide-spin-buttons"
              type="password"
              disabled={isLoading}
              autoComplete="off"
              {...register("new_pin")}
              color={errors?.new_pin ? "failure" : "gray"}
              helperText={
                errors.new_pin && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.new_pin?.message}!
                  </span>
                )
              }
            />
          </div>

          <div className="mt-2">
            <div className="mb-2 block">
              <Label htmlFor="new2" value="Re-Type Pin" />
            </div>
            <TextInput
              sizing="sm"
              id="new2"
              className="hide-spin-buttons"
              type="password"
              disabled={isLoading}
              autoComplete="off"
              {...register("verify_new_pin")}
              color={errors?.verify_new_pin ? "failure" : "gray"}
              helperText={
                errors.verify_new_pin && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.verify_new_pin?.message}!
                  </span>
                )
              }
            />
          </div>

          <div className="mt-2">
            <div className="mb-2 block">
              <Label htmlFor="password" value="Your Password" />
            </div>
            <TextInput
              sizing="sm"
              id="password"
              className="hide-spin-buttons"
              type="password"
              disabled={isLoading}
              autoComplete="off"
              {...register("password")}
              color={errors?.password ? "failure" : "gray"}
              helperText={
                errors.password && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.password?.message}!
                  </span>
                )
              }
            />
          </div>

          <div className="mt-5 flex justify-end items-end gap-3">
            <button
              type="button"
              disabled={isLoading}
              onClick={() => setIsOpen(false)}
              className="text-blue-700 text-[13px] font-semibold flex items-center gap-1 hover:underline"
              color="failure"
            >
              <BsSkipEnd />
              Skip
            </button>
            <Button
              isProcessing={isLoading}
              disabled={isLoading}
              type="submit"
              className="rounded-md"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default PinApplication;
