import { FormEvent, useState } from "react";
import {
  useCreateCompanyMutation,
  useGetCompaniesQuery,
} from "../../redux/queries/company";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Label,
  Select,
  TextInput,
  useThemeMode,
} from "flowbite-react";
import { IoIosAdd } from "react-icons/io";
import Breadcrumb from "../../components/Breadcrumb";
import { LiaTimesSolid } from "react-icons/lia";
import { MdAlternateEmail, MdSearch } from "react-icons/md";
import ImageView from "../../components/ImageView";
import moment from "moment";
import Modal from "react-responsive-modal";
import toast from "react-hot-toast";
import { CiUser } from "react-icons/ci";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import PhoneInput from "react-phone-input-2";
import { useGetCitiesQuery } from "../../redux/queries/location";
import CustomPagination from "../../components/CustomPagination";

const Hospital = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    limit: "0,10",
    type: "hospital",
    status: 1,
    name: "",
  });
  const { data: users, isLoading } = useGetCompaniesQuery(filters);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Breadcrumb
        title="Hospital List"
        rightButton={
          <Button size="xs" onClick={() => setIsOpen(true)}>
            <IoIosAdd className="mr-2 h-5 w-5" /> New Hospital
          </Button>
        }
      />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="grid grid-cols-4 gap-3">
          <div>
            <TextInput
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, name: e.target.value }))
              }
              type="text"
              icon={MdSearch}
              placeholder="search..."
            />
          </div>
        </div>
      </div>

      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={users?.data}
            total={users?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label htmlFor="checkbox-all-search" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3">
                    #
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Customer Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Customer Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  {/* <th scope="col" className="px-6 py-3">
                  Action
                </th> */}
                </tr>
              </thead>
              <tbody>
                {users?.data?.map((item) => (
                  <tr
                    onClick={() =>
                      navigate(`/customer/${item.uuid}`)
                    }
                    key={item.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td
                      className="w-4 p-4"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="checkbox-table-search-1"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td onClick={(e) => e.stopPropagation()}>
                      {/* <img
                      className="w-10"
                      src={item.avatar_link}
                      alt={item.name}
                    /> */}
                      <ImageView url={item.avatar_link} className="w-10 h-10" />
                    </td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {item.name}
                    </th>
                    <td className="px-6 py-4">{item.email}</td>
                    <td className="px-6 py-4">
                      <Badge
                        className="w-max"
                        color={item.status === 1 ? "success" : "failure"}
                      >
                        {item.status === 1 ? "active" : "inactive"}
                      </Badge>
                    </td>
                    <td className="px-6 py-4">
                      {moment(item.crdate).format("LLL")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>
      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <CustomerForm close={() => setIsOpen(false)} />
      </Modal>
    </div>
  );
};

export default Hospital;

const CustomerForm = ({ close }: { close: () => void }) => {
  const [createCustomer, { isLoading }] = useCreateCompanyMutation();
  const [phone, setPhone] = useState("");
  const { mode } = useThemeMode();

  const [address, setAddress] = useState({
    name: "",
    lat: 0,
    lng: 0,
  });

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!address.name) {
      toast.error("Please enter address");
      return;
    }
    const formEntries = new FormData(e.target as HTMLFormElement);
    const payload = {
      name: formEntries.get("name") as string,
      email: formEntries.get("email") as string,
      phone: phone,
      status: formEntries.get("status") as string,
      city_id: formEntries.get("city_id") as string,
      address,
    };

    const res = await createCustomer({ ...payload, type: "hospital" });
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Customer Created");
        close();
        //@ts-ignore
      } else toast.error("Unable to create customer");
    } else toast.error("Unable to create customer");
  };

  const count = 160;

  const { data: cities } = useGetCitiesQuery({
    country_id: count.toString(),
  });

  return (
    <div className="w-[600px] rounded-t-lg bg-white dark:bg-gray-800">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">New Hospital</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="p-3" onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div className="max-w-md mb-3">
            <Label htmlFor="name" value="Company Name" className="text-xs" />
            <TextInput
              id="name"
              name="name"
              disabled={isLoading}
              required
              type="text"
              className="flex-1 mt-1"
              icon={CiUser}
              sizing="sm"
              placeholder="Company Name"
              style={{ borderRadius: 2 }}
            />
          </div>

          <div className="max-w-md mb-3">
            <Label htmlFor="email" value="Email" className="text-xs" />
            <TextInput
              id="email"
              type="email"
              disabled={isLoading}
              required
              name="email"
              icon={MdAlternateEmail}
              className="flex-1 mt-1"
              sizing="sm"
              placeholder="Email"
              style={{ borderRadius: 2 }}
            />
          </div>

          <div className="max-w-md mb-3">
            <Label htmlFor="phone" value="Phone" className="text-xs" />
            <div className="mt-1">
              <PhoneInput
                country="ng"
                value={phone}
                onChange={(e) => setPhone(e)}
                disabled={isLoading}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                buttonStyle={{
                  backgroundColor:
                    mode === "light" ? "rgb(249 250 251)" : "rgb(75 85 99)",
                  border: `1px solid ${
                    mode === "light" ? "rgb(209 213 219)" : "rgb(75 85 99)"
                  }`,
                  borderRadius: "0px",
                }}
                inputStyle={{
                  borderRadius: "0px",
                  width: "100%",
                  height: "34px",
                  paddingRight: "5px",
                  backgroundColor:
                    mode === "light" ? "rgb(249 250 251)" : "rgb(75 85 99)",
                  border: `1px solid ${
                    mode === "light" ? "rgb(209 213 219)" : "rgb(75 85 99)"
                  }`,
                  color: mode === "light" ? "black" : "white",
                }}
                dropdownStyle={{
                  position: "absolute",
                  top: -20,
                  left: 0,
                }}
              />
            </div>
          </div>

          <div className="max-w-md mb-3">
            <Label htmlFor="status" value="Status" className="text-xs" />
            <Select
              disabled={isLoading}
              name="status"
              required
              sizing="sm"
              style={{ borderRadius: 2 }}
              className="mt-1"
            >
              <option value="1">Active</option>
              <option value="0">InActive</option>
            </Select>
          </div>
          <div className="max-w-md mb-3">
            <Label value="City" htmlFor="city_id" className="text-xs" />
            <Select
              disabled={isLoading}
              name="city_id"
              required
              sizing="sm"
              style={{ borderRadius: 2 }}
              className="mt-1"
            >
              <option value="" hidden>
                Select City
              </option>

              {cities?.data?.map((cityinn: any) => (
                <option key={cityinn?.id} value={cityinn?.id}>
                  {cityinn.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <div className="mb-3">
          <div className=" block">
            <Label htmlFor="address" value="Address" />
          </div>
          <ReactGoogleAutocomplete
            className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            onPlaceSelected={(place) => {
              setAddress({
                name: place.formatted_address,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              });
            }}
          />
        </div>
        <Button
          isProcessing={isLoading}
          type="submit"
          className="w-full my-5 rounded-sm"
          size="sm"
        >
          Save
        </Button>
      </form>
    </div>
  );
};
