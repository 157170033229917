import React, { useState, useRef, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Banner,
  Button,
  Datepicker,
  Label,
  ListGroup,
  TextInput,
  Tooltip,
} from "flowbite-react";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import { useLazyGetProductsQuery } from "../../redux/queries/products";
import toast from "react-hot-toast";
import { VariantComponent } from "../../POS/CartProducts";
import { FaEdit } from "react-icons/fa";
import { GoTrash } from "react-icons/go";
import InvoicePdf from "../../components/InvoicePdf";
import { useReactToPrint } from "react-to-print";
import { useCreateOrderMutation } from "../../redux/queries/order";
import { FcSearch } from "react-icons/fc";
import autocomplete from "autocompleter";
import { useLazyGetUsersQuery } from "../../redux/queries/users";
import { Modal } from "react-responsive-modal";
import { BsBlockquoteLeft } from "react-icons/bs";
import BranchSwitcher from "../../components/BranchSwitcher";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import ProductSearch from "../../components/ProductSearch";
import { addQuotationItem, clearQuotationCart, decreaseQuotationQty, increaseQuotationQty, removeQuotationItem, setQuotationQuantity } from "../../redux/slices/cart";

interface MyInterface {
  label: string;
  value: string;
}

const Quotation = () => {
  const elRef = useRef(null);
  const dispatch = useAppDispatch()
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const { quotationCart } = useAppSelector((state) => state.cartAndDraft);
  const handlePrint = useReactToPrint({
    content: () => elRef.current,
  });
  const [createOrder] = useCreateOrderMutation();
  const [product, setProduct] = useState<Product | undefined>();
  const [id, setId] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [variantModal, setVariantsModal] = useState<boolean>(false);
  const [selectedCart, setSelectedCart] = useState<InvoiceCart | undefined>();
  const [qty, setQty] = useState("1");
  const [enablePrint, setEnablePrint] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState("");
  const [postedOrder, setPostedOrder] = useState<CompanyOrder | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<MyInterface | null>(
    null
  );

  const [branch, setBranch] = useState(defaultBranchUUid);

  const [getCustomers] = useLazyGetUsersQuery();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const el = e.target;
    setSearchKey(el.value);
    const autoColmpleteEl = autocomplete<MyInterface>({
      input: el,
      emptyMsg: "No items found",
      minLength: 1,
      className: "bg-white dark:bg-gray-800",
      fetch: async (text: string, update: (items: MyInterface[]) => void) => {
        const res = await getCustomers({
          limit: "0,10",
          type: "pharmacy",
          status: 1,
          name: text.toLowerCase(),
        });
        const result = res.data?.data.map((item) => ({
          label: `${item.name} - (${item.company.name} Pharmarcy)`,
          value: item.uuid,
        }));
        if (result) update(result);
      },
      onSelect: (item: MyInterface) => {
        setSearchKey("");
        setSelectedCustomer(item);
        utills._play_sound();
        autoColmpleteEl.destroy();
      },
      render: function (
        item: MyInterface,
        currentValue: string
      ): HTMLDivElement | undefined {
        const itemElement = document.createElement("div");
        itemElement.classList.add(
          "p-2",
          "border",
          "border-gray-500",
          "text-xs",
          "hover:bg-gray-300",
          "cursor-pointer"
        );
        itemElement.classList.add("dark:text-white", "dark:hover:bg-gray-700");
        itemElement.textContent = item.label;
        return itemElement;
      },
    });
  };

  const addItemToCart = (
    uuid: string,
    price_in_naira: string | number,
    price_in_dollar: string | number,
    verified: boolean,
    variant_id?: number,
    selectedItem?: Product
  ) => {
    const item = selectedItem ?? product;

    if (!item) return;

    let payload: InvoiceCart = {
      uuid: uuid,
      price: Number(price_in_naira),
      name: item.name,
      quantity: Number(qty),
      company: item.company.uuid,
      image: item.images_links,
    };
    if (variant_id) payload.variant_id = variant_id;
    dispatch(addQuotationItem(payload));
    utills._play_sound();
    setQty("1");
  };

  const createInvoice = async (e: any, type: number) => {
    const payload = {
      type,
      user: selectedCustomer?.value ?? "",
      items: quotationCart,
      payment_mode: 2,
    };
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const res = await createOrder(payload);
          //@ts-ignore
          if (res.error) {
            reject(res);
          } else {
            if ("data" in res && res.data.status === 200) {
              resolve(res);
              setEnablePrint(true);
              setPostedOrder(res.data.data);
              dispatch(clearQuotationCart());
            } else reject(res);
          }
        } catch (error) {
          reject(error);
        }
      }),
      {
        loading: "Processing...",
        success: () => "Record created successfully",
        error: () => "Unable to crete record",
      },
      {
        style: {
          // minWidth: "250px",
        },
        success: {
          duration: 5000,
          icon: "🔥",
        },
      }
    );
    // handlePrint(e)
  };

  const totalPrice = () => {
    let total = 0;
    for (const item of quotationCart) {
      total += item.price * item.quantity;
    }
    return total;
  };


  useEffect(() => {
    if (quotationCart.length > 0) {
      let pickedItemCopy = structuredClone(quotationCart);
      const lastdata = pickedItemCopy.shift();
      if (lastdata) {
        const el = document.getElementById(lastdata.uuid);
        setTimeout(()=> el?.focus(), 1000)
      }
    }
  }, [quotationCart.length]);

  return (
    <div>
      <Breadcrumb title="Create Quotation" />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Create Quotation For Customer.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                Search through you products and create an quotation.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <BsBlockquoteLeft className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="mt-3">
        <div className="flex items-center">
          <div className="max-w-sm">
            <Label value="Quotation Date" className="mb-1" />
            <Datepicker style={{ borderRadius: 3 }} />
          </div>
        </div>

        <div>
          <div className="flex-1 my-4">
            <div className="flex gap-3">
              <TextInput
                // sizing="sm"
                type="text"
                icon={FcSearch}
                placeholder="search customer..."
                className="rounded-sm max-w-xs"
                onChange={handleInput}
                value={searchKey}
                style={{ borderRadius: 2 }}
              />
              <BranchSwitcher
                sizing="md"
                styling={{ borderRadius: 3 }}
                onChange={(text) => setBranch(text)}
              />
            </div>
            <span className="dark:text-white truncate text-[11px] mt-2">
              Customer: {selectedCustomer?.label}{" "}
            </span>
          </div>
        </div>

        <div className="flex items-center mt-5">
          <TextInput
            style={{ borderRadius: 3 }}
            className="w-20"
            placeholder="qty"
            type="number"
            step="any"
            disabled={loading}
            value={qty}
            onChange={(e) => setQty(e.target.value)}
          />
          <div className="flex-1 relative">
            <ProductSearch
              companyId={branch}
              onSelect={(item) => {
                setProduct(item);
                if (utills._display_variant(item.variants)) {
                  setId(item.uuid);
                  setVariantsModal(true);
                } else {
                  addItemToCart(
                    item.uuid,
                    item.selling_price,
                    item.pricein_dollar,
                    item.search_by_barcode as boolean,
                    undefined,
                    item
                  );
                }
              }}
              error="Please Select Branch"
            />
          </div>
        </div>
      </div>

      <div className="mt-10">
      <div className="flex items-center gap-3">
          <span>
            Total Row: {" "}{quotationCart.length}
          </span>

          <span>
            Total:{" "}
            {utills._currency_format(
              totalPrice(),
              "NGN"
            )}
          </span>
          </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-8">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Product
                </th>
                <th scope="col" className="px-6 py-3">
                  Qty
                </th>
                <th scope="col" className="px-6 py-3">
                  Rate
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {quotationCart.map((item) => (
                <tr
                  key={item.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="p-4">
                    <ImageView
                      className="w-8 h-8"
                      url={utills._default_img}
                    />
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {item.name}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <button
                        className="inline-flex items-center justify-center p-1 me-3 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={() => {
                          if (item.quantity === 1) {
                            utills._play_error_sound();
                            return;
                          }
                          dispatch(decreaseQuotationQty(item.uuid))
                          utills._play_sound();
                        }}
                      >
                        <span className="sr-only">Quantity button</span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 2"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1h16"
                          />
                        </svg>
                      </button>
                      <div>
                        <input
                          type="number"
                          step="any"
                          id={item.uuid}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              const element =
                                document.getElementById("searchFields");
                              element?.focus();
                            }
                          }}
                          className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="1"
                          value={item.quantity}
                          onChange={(e) =>
                            dispatch(
                              setQuotationQuantity({
                                uuid: item.uuid,
                                quantity: Number(e.target.value),
                              })
                            )
                          }
                        />
                      </div>
                      <button
                        className="inline-flex items-center justify-center h-6 w-6 p-1 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={() => {
                          dispatch(increaseQuotationQty(item.uuid))
                          utills._play_sound();
                        }}
                      >
                        <span className="sr-only">Quantity button</span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 18"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 1v16M1 9h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {utills._currency_format(item.price, "NGN")}
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {utills._currency_format(item.price * item.quantity, "NGN")}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-3">
                      {/* {item.variant_id && (
                        <Tooltip content="Edit Variant">
                          <span
                            onClick={() => {
                              setSelectedCart(item);
                              setVariantsModal(true);
                            }}
                            className="font-medium text-green-600"
                          >
                            <FaEdit />
                          </span>
                        </Tooltip>
                      )} */}
                      <Tooltip content="Remove Item">
                        <span
                          onClick={() => dispatch(removeQuotationItem(item.uuid))}
                          className="font-medium text-red-600 dark:text-red-500"
                        >
                          <GoTrash />
                        </span>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex-1 flex flex-col items-end">
          <Label value="Quotation Date" className="mb-1 opacity-0" />
          <Button
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              if (quotationCart.length === 0) {
                toast.error("Please add at least a product!!!");
              } else if (!selectedCustomer) {
                toast.error("Please select customer!!!");
              } else createInvoice(e, 10);
            }}
            className="rounded-sm"
          >
            <BsBlockquoteLeft className="mr-2 h-5 w-5" /> Create Quotation
          </Button>
        </div>
      </div>

      <Modal open={variantModal} center onClose={() => setVariantsModal(false)}>
        <div className="p-2">
          <span className="text-[14px] font-semibold">Select Variants</span>
        </div>
        <div className="p-0 rounded-xs">
          <VariantComponent
            product={product}
            addToCart={addItemToCart}
            close={() => setVariantsModal(false)}
            cart={selectedCart}
          />
        </div>
      </Modal>

      {/* <Modal open={enablePrint} onClose={() => setEnablePrint(false)} center>
        <div>
          <span
            onClick={(e) => {
              handlePrint(e);
              setEnablePrint(false);
            }}
            className="text-[#167490] text-xs font-bold hover:underline cursor-pointer"
          >
            Print document
          </span>
        </div>
        <InvoicePdf order={postedOrder} ref={elRef} />
      </Modal> */}

      <InvoicePdf
        isOpen={enablePrint}
        close={() => setEnablePrint(false)}
        order={postedOrder}
      />
    </div>
  );
};

export default Quotation;
