import Breadcrumb from "../../components/Breadcrumb";
import { useGetIncomeStatementQuery } from "../../redux/queries/Payments";
import { useAppSelector } from "../../lib/hook";
import { useEffect, useState } from "react";
import { FaWallet } from "react-icons/fa";
import utills from "../../lib/functions";
import { BsCashStack } from "react-icons/bs";
import { BiSolidBank } from "react-icons/bi";
import { CiMoneyCheck1 } from "react-icons/ci";
import { HiOutlineStatusOnline } from "react-icons/hi";
import CustomPagination from "../../components/CustomPagination";
import BranchSwitcher from "../../components/BranchSwitcher";
import { useGetCompaniesQuery } from "../../redux/queries/company";
import CustomSelect from "../../components/CustomSelect";
import moment from "moment";
import { Button } from "flowbite-react";
import CustomDatePicker from "../../components/CustomDatePicker";

const IncomeStatement = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: user?.company?.uuid,
    other_party: "",
    start_date: "",
    end_date: "",
  });
  const [active, setActive] = useState(0);
  const [filterFields, setFilterFields] = useState({
    start_date: "",
    end_date: "",
  });
  const [resetValue, setResetValue] = useState(false);
  const { data: incomeStatements, isLoading } =
    useGetIncomeStatementQuery(filters);
  console.log(incomeStatements);
  const [companiesFilters, setCompaniesFilters] = useState({
    limit: "0,10",
    status: 1,
    name: "",
  });
  const { data: companies, isLoading: isLoadingCompanies } =
    useGetCompaniesQuery(companiesFilters);

  const todayFn = () => {
    setActive(1);
    if (incomeStatements?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const yesterdayFn = () => {
    setActive(2);
    if (incomeStatements?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment()
          .startOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .endOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const lastWeekFn = () => {
    setActive(3);
    if (incomeStatements?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  // useEffect(() => {
  //   if (incomeStatements?.data.length) {
  //     setFilterFields((prev) => ({
  //       ...prev,
  //       start_date: incomeStatements?.data[incomeStatements?.data.length - 1]
  //         ?.crdate.length
  //         ? moment(
  //             incomeStatements?.data[incomeStatements?.data.length - 1]?.crdate
  //           ).format("YYYY-MM-DD HH:mm:ss")
  //         : "",
  //       end_date: incomeStatements?.data[0]?.crdate.length
  //         ? moment(incomeStatements?.data[0]?.crdate).format(
  //             "YYYY-MM-DD HH:mm:ss"
  //           )
  //         : "",
  //     }));
  //   }
  // }, [incomeStatements?.data]);

  const reset = () => {
    setActive(0);
    setFilters({
      limit: "0,10",
      company: user?.company?.uuid,
      other_party: "",
      start_date: "",
      end_date: "",
    });
    setResetValue(true);
  };

  return (
    <div>
      <Breadcrumb title="Income Statement" />
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex flex-wrap items-center gap-5">
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between flex-1">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Cash
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <BsCashStack className="text-[#167490] text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[20px]">
            <span>
              {utills._currency_format(
                Number(incomeStatements?.cash) ?? 0,
                "NGN"
              )}
            </span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between flex-1">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Wallet
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <FaWallet className="text-[#167490] text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[20px]">
            <span>
              {utills._currency_format(
                Number(incomeStatements?.wallet) ?? 0,
                "NGN"
              )}
            </span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between flex-1">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Loan
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <BiSolidBank className="text-[#167490] text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[20px]">
            <span>
              {utills._currency_format(
                Number(incomeStatements?.loan) ?? 0,
                "NGN"
              )}
            </span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between flex-1">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Cheque
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <CiMoneyCheck1 className="text-[#167490] text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[20px]">
            <span>
              {utills._currency_format(
                Number(incomeStatements?.cheque) ?? 0,
                "NGN"
              )}
            </span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between flex-1 w-auto">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Online
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <HiOutlineStatusOnline className="text-[#167490] text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[20px]">
            <span>{incomeStatements?.online}</span>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm flex gap-5 items-center">
        <div className="flex justify-between items-center flex-wrap gap-3 w-full">
          <div className="flex flex-wrap items-center gap-3">
            <div className="flex items-center gap-2">
              <Button size="xs" onClick={todayFn} outline={active !== 1}>
                Today
              </Button>
              <Button size="xs" onClick={yesterdayFn} outline={active !== 2}>
                Yesterday
              </Button>
              <Button size="xs" onClick={lastWeekFn} outline={active !== 3}>
                Last Week
              </Button>
            </div>
            <CustomDatePicker
              start={
                filters.start_date.length
                  ? filters.start_date
                  : filterFields.start_date
              }
              onStartChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  start_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                }))
              }
              end={
                filters.end_date.length
                  ? filters.end_date
                  : filterFields.end_date
              }
              onEndChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  end_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                }))
              }
            />
            <div>
              <BranchSwitcher
                sizing="sm"
                defaultCompany={filters.company}
                onChange={(value) =>
                  setFilters((prev) => ({ ...prev, company: value }))
                }
              />
            </div>
            <div className="w-[200px]">
              <CustomSelect
                isClearable={false}
                disabled={isLoading || isLoadingCompanies}
                loading={isLoading || isLoadingCompanies}
                placeholder="Select Customer..."
                inputClasses="h-9"
                options={
                  companies?.data.map((customer) => ({
                    label: customer.name,
                    value: customer.uuid,
                  })) ?? []
                }
                onSelect={(val) => {
                  setFilters((prev) => ({
                    ...prev,
                    other_party: val.value,
                  }));
                  setResetValue(false);
                }}
                onSearch={(text) => {
                  setCompaniesFilters((prev) => ({ ...prev, name: text }));
                  setResetValue(false);
                }}
                reset={resetValue}
              />
            </div>
          </div>

          <div className="flex gap-2">
            <Button outline size="xs" onClick={reset}>
              Reset
            </Button>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm flex flex-wrap gap-5 items-center"></div>
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={incomeStatements?.data}
            total={incomeStatements?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Order ID
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Cash
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Wallet
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Loan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Cheque
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Online
                  </th>
                </tr>
              </thead>
              <tbody>
                {incomeStatements?.data?.map((item) => (
                  <tr
                    key={item.order_id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">#{item.order_id}</td>
                    <td className="px-6 py-4">{item.cash}</td>
                    <td className="px-6 py-4">{item.wallet}</td>
                    <td className="px-6 py-4">{item.loan}</td>
                    <td className="px-6 py-4">{item.cheque}</td>
                    <td className="px-6 py-4">{item.online}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>
    </div>
  );
};

export default IncomeStatement;
