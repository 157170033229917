import Breadcrumb from "../../components/Breadcrumb";
import { Banner, Button, Tooltip } from "flowbite-react";
import { LuHistory } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import utills from "../../lib/functions";
import { useEffect, useState } from "react";
import ImageView from "../../components/ImageView";
import toast from "react-hot-toast";
import moment from "moment";
import { FaCheckCircle, FaEdit, FaTimesCircle } from "react-icons/fa";
import { GoTrash } from "react-icons/go";
import { useUpdatePurchaseMutation } from "../../redux/queries/stock";

interface PurchaseItem extends InvoiceCart {
  batch_no?: string;
  expiring_date?: string;
  markup?: number;
  image_link?: string;
  cost_price: string;
  selling_price?: number;
  verified: number;
  total_price?: number
  flat?: number
}
const ManagePurchaseHistory = () => {
  const location = useLocation();
  const { purchase } = location.state;
  const navigate = useNavigate()
  const [ updatePurchase, { isLoading } ] = useUpdatePurchaseMutation()

  const [pickedItem, setPickedItem] = useState<PurchaseItem[] | []>([]);


  const handleCostPriceEntry = (uuid: string, val: string, markup: number, flat: number) => {
    const selling_p = flat === 0 ? ((markup/100) * Number(val)) + Number(val) : markup + Number(val)
    setPickedItem((prev)=> prev.map((item)=> {
      if(item.uuid === uuid){
        return { ...item, selling_price: selling_p, cost_price: val }
      }else return item
    }))
  }

  useEffect(() => {
    if(purchase){
        const { items } = purchase
        //@ts-ignore
        const cart = items.map(item=> ({
            quantity: item.quantity,
            name: item.item.name,
            price: item.price,
            uuid: item.item.uuid,
            verified: 0,
            flat: item.flat,
            batch_no: item.batch_no,
            cost_price: item.cost,
            company: purchase.buyer.uuid,
            selling_price: item.flat === 1 ? item.selling_price : ((item.markup/100) * item.cost_price) + item.cost_price,
            expiring_date: item.expiring_date,
            total_price: item.total_price,
            markup: item.markup,
            image: [utills._default_img]
        }))
        setPickedItem(cart)
    }
  }, []);

  //@ts-ignore
  const total = pickedItem.reduce((acc:number , obj: PurchaseItem) => {
    return acc + (Number(obj.cost_price ?? "1") * obj.quantity);
  }, 0)

  const savePurchase = async () => {
    const res = await updatePurchase({ uuid: purchase.uuid, data:  { items: pickedItem }})
    if("data" in res){
      toast.success("Record updated")
    }else toast.error("Failed to update record")
  }

  return (
    <div>
      <Breadcrumb title="Manage Purchase History" />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Purchase History Management.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                Manage purchase history, add payment and or items.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <LuHistory className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

<div>
</div>
      <div className="mt-10">
       <div className="flex justify-between mb-2">
       <div className="dark:text-white flex items-center gap-5"><Button onClick={()=> navigate(-1)} >Go Back</Button><strong>Total Cost: </strong> <span className="ml-2">{utills._currency_format(total, 'NGN')}</span></div>
       <Button isProcessing={isLoading} disabled={isLoading} onClick={savePurchase}>Save Purchase</Button>
       </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-8">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Product
                </th>
                <th scope="col" className="px-6 py-3">
                  Qty
                </th>
                <th scope="col" className="px-6 py-3">
                  Cost
                </th>
                <th scope="col" className="px-6 py-3">
                  Selling
                </th>
                <th scope="col" className="px-6 py-3">
                  Batch no
                </th>
                <th scope="col" className="px-6 py-3">
                  Markup/Profit
                </th>
                <th scope="col" className="px-6 py-3">
                Markup/Profit
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Expire date
                </th>
                <th scope="col" className="px-6 py-3">
                  Verified
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {pickedItem.map((item) => (
                <tr
                  key={item.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="p-4">
                    <ImageView
                      className="w-8 h-8"
                      url={
                        utills._remove_invalid_link(item.image).length === 0
                          ? utills._default_img
                          : item.image[0]
                      }
                    />
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {item.name}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <button
                        className="inline-flex items-center justify-center p-1 me-3 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={() => {
                          if (item.quantity === 1) {
                            utills._play_error_sound();
                            return;
                          }
                          setPickedItem((prev) =>
                            prev.map((cart) => {
                              if (cart.uuid === item.uuid) {
                                return { ...cart, quantity: cart.quantity - 1 };
                              } else return cart;
                            })
                          );
                          utills._play_sound();
                        }}
                      >
                        <span className="sr-only">Quantity button</span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          fill="none"
                          viewBox="0 0 18 2"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1h16"
                          />
                        </svg>
                      </button>
                      <div>
                        <input
                          type="number"
                          step="any"
                          id="first_product"
                          className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                          placeholder="1"
                          value={item.quantity}
                          min={1}
                          onFocus={(e) => {
                            e.target.select();
                          }}
                          onChange={(e) => {
                            if (e.target.value === "0") {
                              toast.error("Minimum is 1");
                              utills._play_error_sound();
                              return;
                            }
                            setPickedItem((prev) =>
                              prev.map((p) => {
                                if (item.uuid === p.uuid) {
                                  return {
                                    ...p,
                                    quantity: Number(e.target.value),
                                  };
                                } else return p;
                              })
                            );
                          }}
                        />
                      </div>
                      <button
                        className="inline-flex items-center justify-center h-6 w-6 p-1 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={() => {
                          setPickedItem((prev) =>
                            prev.map((cart) => {
                              if (cart.uuid === item.uuid) {
                                return { ...cart, quantity: cart.quantity + 1 };
                              } else return cart;
                            })
                          );
                          utills._play_sound();
                        }}
                      >
                        <span className="sr-only">Quantity button</span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          fill="none"
                          viewBox="0 0 18 18"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 1v16M1 9h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    <input
                      type="number"
                      step="any"
                      id="first_product"
                      className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                      value={item.cost_price}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={(e)=> handleCostPriceEntry(item.uuid, e.target.value, item.markup!, item.flat!)}
                    />
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                  {utills._currency_format(item.selling_price ?? 0, 'NGN')}
                  </td>
                  <td className="px-6 py-4">
                    <input
                      type="text"
                      id="first_product"
                      className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                      value={item.batch_no}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={(e) => {
                        setPickedItem((prev) =>
                          prev.map((x) => {
                            if (x.uuid === item.uuid) {
                              return { ...x, batch_no: e.target.value };
                            } else return x;
                          })
                        );
                      }}
                    />
                  </td>
                  <td className="px-6 py-4">{item.flat === 1 ? 'profit' : 'markup'}</td>
                  <td className="px-6 py-4">
                  <input
                      type="number"
                      step="any"
                      id="markup"
                      className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                      value={item.markup}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={(e)=> {
                        const selling = item.flat === 1 ? Number(e.target.value) + Number(item.cost_price) : ((Number(e.target.value)/100) * Number(item.cost_price)) + Number(item.cost_price)
                        setPickedItem((prev)=> prev.map(x=> {
                          if(x.uuid === item.uuid){
                            return { ...x, selling_price: selling, markup: Number(e.target.value )}
                          }else return x
                        }))
                      }}
                    />
                  </td>
                  <td className="px-6 py-4">{utills._currency_format(item.total_price ?? 0, 'NGN')}</td>
                  <td className="px-6 py-4">
                    <input
                      type="date"
                      id="date"
                      className="bg-gray-50 w-36 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hide-spin-buttons"
                      // value={item.quantity}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      value={moment(item.expiring_date).format('YYYY-MM-DD')}
                      onChange={(e) => {
                        setPickedItem((prev) =>
                          prev.map((x) => {
                            if (x.uuid === item.uuid) {
                              return {
                                ...x,
                                expiring_date: moment(e.target.value).format(
                                  "DD-MM-YYYY"
                                ),
                              };
                            } else return x;
                          })
                        );
                      }}
                    />
                  </td>
                  <td className="px-6 py-4">
                    {item.verified ? (
                      <FaCheckCircle className="text-green-500" />
                    ) : (
                      <FaTimesCircle className="text-red-500" />
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-3">
                      <Tooltip content="Remove Item">
                        <span
                          onClick={() =>
                            setPickedItem((prev) =>
                              prev.filter((cart) => cart.uuid !== item.uuid)
                            )
                          }
                          className="font-medium text-red-600 dark:text-red-500"
                        >
                          <GoTrash />
                        </span>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ManagePurchaseHistory;
