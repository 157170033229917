import { Pagination, Select, Spinner } from "flowbite-react";
import emptyIcon from "../assets/empty.png";
import { useEffect, useState } from "react";

interface ICustomPagination {
  children: JSX.Element;
  isLoading: boolean;
  total?: number;
  data?: any[];
  setPage: (limit: string) => void;
  layout?: "pagination" | "table" | "navigation" | undefined;
  count?: number;
}

const CustomPagination = (props: ICustomPagination) => {
  const {
    children,
    isLoading,
    total,
    setPage,
    layout = "pagination",
    count = 10,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil((total as number) / count);
  const [countOption, setCountOption] = useState(count);

  const onPageChange = (page: number) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    setPage(`${(page - 1) * countOption},${countOption}`);
  };

  useEffect(() => {
    onPageChange(1);
  }, [countOption]);

  useEffect(() => {
    if (!total) return;
    if (total < 11) setCurrentPage(1);
  }, [total]);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const ellipsis = (
      <span className="px-2 text-gray-900 dark:text-gray-100">...</span>
    );

    if (currentPage > 3) {
      pageNumbers.push(
        <Pagination.Button
          key={1}
          onClick={() => onPageChange(1)}
          active={currentPage === 1}
          className={`flex items-center justify-center px-4 h-10 leading-tight ${
            currentPage === 1
              ? "bg-green-100 text-green-500 dark:bg-gray-700 dark:text-white border border-gray-300 dark:border-gray-400"
              : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          }`}
        >
          1
        </Pagination.Button>,
        ellipsis
      );
    }

    for (
      let i = Math.max(1, currentPage - 2);
      i <= Math.min(totalPages, currentPage + 2);
      i++
    ) {
      pageNumbers.push(
        <Pagination.Button
          key={i}
          active={i === currentPage}
          onClick={() => onPageChange(i)}
          className={`flex items-center justify-center px-4 h-10 leading-tight ${
            currentPage === i
              ? "bg-green-100 text-green-500 dark:bg-gray-700 dark:text-white border border-gray-300 dark:border-gray-400"
              : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          }`}
        >
          {i}
        </Pagination.Button>
      );
    }

    if (currentPage < totalPages - 2) {
      pageNumbers.push(
        ellipsis,
        <Pagination.Button
          key={totalPages}
          onClick={() => onPageChange(totalPages)}
          className={`flex items-center justify-center px-4 h-10 leading-tight ${
            currentPage === totalPages
              ? "bg-green-100 text-green-500 dark:bg-gray-700 dark:text-white border border-gray-300 dark:border-gray-400"
              : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          }`}
        >
          {totalPages}
        </Pagination.Button>
      );
    }

    return pageNumbers;
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center my-5">
        <Spinner aria-label="Loading" />
      </div>
    );
  }

  if (total === 0) {
    return (
      <div className="flex items-center justify-center my-5">
        <div className="flex flex-col items-center">
          <img src={emptyIcon} alt="empty record" className="w-20" />
          <span className="dark:text-gray-400 mt-2 text-xs">
            No record found
          </span>
        </div>
      </div>
    );
  }

  return (
    <div>
      {children}
      <div className="flex mt-3 mb-5 mr-2 px-2 justify-end gap-4">
        <div className="flex items-center">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`flex items-center justify-center px-4 h-10 leading-tight rounded-l-md  ${
              currentPage === 1
                ? "bg-gray-200 dark:bg-gray-600 dark:text-white border border-gray-300 dark:border-gray-400 text-gray-50"
                : "text-gray-500 bg-white border border-gray-200 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
          >
            Previous
          </button>
          {layout === "pagination" && renderPageNumbers()}
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`flex items-center justify-center px-4 h-10 leading-tight rounded-e-md  ${
              currentPage === totalPages
                ? "bg-gray-200 dark:bg-gray-600 dark:text-white border border-gray-300 dark:border-gray-400 text-gray-50"
                : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            }`}
          >
            Next
          </button>
        </div>

        {count !== 5 && (
          <Select
            value={countOption}
            id="count"
            onChange={(e) => setCountOption(Number(e.target.value))}
          >
            <option value={10}>1 - 10</option>
            <option value={15}>1 - 15</option>
            <option value={20}>1 - 20</option>
            <option value={30}>1 - 30</option>
            <option value={40}>1 - 40</option>
            <option value={50}>1 - 50</option>
          </Select>
        )}
      </div>
    </div>
  );
};

export default CustomPagination;
