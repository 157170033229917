import { useState, useRef } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { Label, TextInput, Banner, Button } from "flowbite-react";
import { FcSearch } from "react-icons/fc";
import { CiBarcode } from "react-icons/ci";
import emptyIcon from "../../assets/empty.png";
import {
  useLazyGetProductsQuery,
  useUpdateProductMutation,
} from "../../redux/queries/products";
import utills from "../../lib/functions";
import { IoIosPrint } from "react-icons/io";
import BarcodeSvg from "../../components/BarcodeSvg";
import { IoEyeOutline } from "react-icons/io5";
import { RxReset } from "react-icons/rx";
import { useReactToPrint } from "react-to-print";
import { BiLoaderCircle } from "react-icons/bi";
import { useAppSelector } from "../../lib/hook";

const Barcode = () => {
  const elRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => elRef.current,
  });

  const { defaultBranchUUid} = useAppSelector((state)=> state.appUserConfig)


  const [getProducts, { data: products }] = useLazyGetProductsQuery();
  const [updateProduct] = useUpdateProductMutation();
  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [product, setProduct] = useState<Product | null>(null);
  const [screen, setScreen] = useState(1);

  const searchProduct = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    await getProducts({ name: key, limit: "0,10", company: defaultBranchUUid });
    setLoading(false);
  };

  const previewCode = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const inputElement = document.getElementById(
      "count"
    ) as HTMLInputElement | null;
    if (inputElement) {
      const countValue = inputElement.value as string;
      if (countValue) {
        setCount(Number(countValue));
      }
    }
  };

  const generateBarcode = async (record: Product) => {
    const code = utills._generate_barcode();
    const payload = {
      id: record.uuid,
      data: {
        category: record.category.uuid,
        company: record.company.uuid,
        barcode: Number(code),
        description: record.description,
        discount: record.discount,
        name: record.name,
        pricein_dollar: record.pricein_dollar,
        selling_price: record.selling_price,
        quantity: record.quantity,
        status: record.status,
      },
    };
    utills._asynchronous_toast(
      updateProduct,
      "Barcode Generated",
      "Failed to generate barcode",
      payload
    );
  };

  return (
    <div>
      <Breadcrumb title="Create Product Barcode" />
      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Generate and print barcode for any product.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                Search for the item you would like to generate or print its
                barcode and enter how many codes you would like to print.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <CiBarcode className="text-5xl dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      {screen === 1 && (
        <div>
          <div className="max-w-md">
            <div className="mb-2 block">
              <Label htmlFor="product" value="Search Product" />
            </div>
            <form className="flex items-center" onSubmit={searchProduct}>
              <TextInput
                sizing="sm"
                onChange={(e) => setKey(e.target.value)}
                icon={FcSearch}
                id="product"
                type="text"
                className="flex-1"
                placeholder="search..."
                required
              />
              <Button
                size="xs"
                isProcessing={loading}
                disabled={loading}
                type="submit"
                className="rounded-md ml-2"
              >
                Search
              </Button>
            </form>
          </div>
          <span className="text-xs font-semibold text-red-500">
            Note: Only 10 products are displayed at a go
          </span>
        </div>
      )}

      {screen === 2 && (
        <div>
          <div className="flex items-center gap-5 mb-5">
            <img
              className="w-16"
              src={product?.images_links[0]}
              alt={product?.name ?? "product image"}
              onError={utills._fallback_img}
            />
            <div>
              <div className="dark:text-gray-400 text-sm">
                <span className="mr-2">Product Name:</span>{" "}
                <strong>{product?.name}</strong>
              </div>
              <div className="dark:text-gray-400 text-sm">
                <span className="mr-2">Product Price:</span>{" "}
                <strong>
                  {utills._currency_format(product?.selling_price ?? 0, "NGN")}
                </strong>
              </div>
              <div className="dark:text-gray-400 text-sm">
                <span className="mr-2">Product Stock:</span>{" "}
                <strong>{product?.stock}</strong>
              </div>
            </div>
          </div>

          <div className="">
            <div className="mb-2 block">
              <Label htmlFor="count" value="Enter no of barcode to print" />
            </div>
            <form onSubmit={previewCode} className="flex items-center">
              <TextInput
                sizing="sm"
                id="count"
                className="w-60"
                type="number"
                step="any"
                name="count"
                placeholder="0"
                required
              />
              <Button size="xs" type="submit" className="rounded-md ml-2">
                <IoEyeOutline className="mr-2 h-5 w-5" />
                Preview
              </Button>
              {count > 0 && (
                <button
                  onClick={() => {
                    setCount(0);
                    setProduct(null);
                    setScreen(1);
                  }}
                  className="ml-3 inline-flex items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-cyan-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                >
                  <RxReset className="mr-2 h-4 w-4" />
                  Reset
                </button>
              )}
              {count > 0 && (
                <button onClick={(e)=> handlePrint(e)} className="ml-3 inline-flex items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-cyan-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700">
                  <IoIosPrint className="mr-2 h-4 w-4" />
                  Print Barcode
                </button>
              )}
            </form>
          </div>
          <div className="mt-5 bg-white rounded-md dark:bg-gray-700 px-5">
            <div className="flex flex-col items-center gap-4" ref={elRef}>
              {count > 0 &&
                Array.from({ length: count }).map((barcode, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center w-[120px]"
                  >
                    <BarcodeSvg
                      value={product?.barcode.toString() ?? ""}
                    />
                    <span className="text-[8px] mt-[-4px] truncate-2-lines  max-w-[100px] font-semibold text-center leading-[12px] z-50">
                      {product?.name}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      {screen === 1 && (
        <div className="mt-10">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    #
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Product name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Barcode
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Stock
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Price&#40;&#8358;&#41;
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {products?.data?.map((item) => (
                  <tr
                    key={item.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="pl-3">
                      <img
                        className="w-10"
                        src={item.images_links[0]}
                        alt={item.name}
                        onError={utills._fallback_img}
                      />
                    </td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {item.name}
                    </th>
                    <td className="px-6 py-4">
                     <BarcodeEntry generateBarcode={generateBarcode} item={item} isLoading={isLoading} />
                    </td>
                    <td className="px-6 py-4">{item.stock}</td>
                    <td className="px-6 py-4">
                      {utills._currency_format(item.selling_price, "NGN")}
                    </td>
                    <td className="pl-5">
                      {!isNaN(Number(item.barcode)) ? (
                        <button
                          onClick={() => {
                            setProduct(item);
                            setScreen(2);
                          }}
                          className="mr-3 inline-flex items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-cyan-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                        >
                          <IoIosPrint className="mr-2 h-4 w-4" />
                          print
                        </button>
                      ) : (
                        <button
                          disabled={isLoading}
                          onClick={() => generateBarcode(item)}
                          className="mr-3 inline-flex items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-cyan-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                        >
                          <CiBarcode className="mr-2 h-4 w-4" />
                          Generate Code
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {products?.data.length === 0 && (
              <div className="flex items-center justify-center my-5">
                <div className="flex flex-col items-center">
                  <img src={emptyIcon} alt="empty record" className="w-20" />
                  <span className="dark:text-gray-400 mt-2 text-xs">
                    No record found
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Barcode;


interface IBarcodeEntry {
  item: Product
  generateBarcode: (item: Product) => void
  isLoading: boolean
}
const BarcodeEntry = (props: IBarcodeEntry) => {
  const { isLoading, generateBarcode, item } = props
  return (
    <div className="flex w-48 border border-gray-300 dark:border-gray-700 pl-2 rounded-md items-center">
    <span className="flex-1 text-xs font-semibold">{item.barcode}</span>
    <button disabled={isLoading}  onClick={() => generateBarcode(item)} className="bg-gray-300 disabled:bg-gray-100 dark:bg-gray-700 rounded-e-md text-[#0891b2] p-2">{isLoading ? <BiLoaderCircle size={18} className="animate-spin" /> : <CiBarcode size={18} />}</button>
  </div>
  )
}