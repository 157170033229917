import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Label, TextInput } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import {
  useResetPinMutation,
} from "../../redux/queries/users";
import { setPinStatus } from "../../redux/slices/appUser";

interface IFormInput {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

const schema = yup.object().shape({
  new_password: yup
    .string()
    .matches(/^\d{4}$/, "New pin must be a 4-digit number")
    .required("New pin is required"),
  old_password: yup.string().required("Password is required"),
  confirm_password: yup
    .string()
    .required("Pin do not match")
    .test("match", "Pin do not match", function (value) {
      return value === this.parent.new_password;
    }),
});

const PinSetting = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [resetPin, { isLoading }] = useResetPinMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: IFormInput) => {
    const res = await resetPin({
      uuid: user?.uuid,
      pin: data.new_password,
      old_pin: data.old_password,
      pin_confirmation: data.confirm_password,
    });
    if ("data" in res) {
      if (res.data.status !== 200) {
        toast.error(res.data.message);
      } else {
        toast.success("Pin set successfully!!!");
        dispatch(setPinStatus(true));
      }
    } else toast.error("Error- Action Failed!!!");
  };

  return (
    <form className="mt-7 rounded-t-lg" onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-3 gap-3 p-4">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="new2" value="Old Pin" />
          </div>
          <TextInput
            id="new2"
            className="hide-spin-buttons"
            type="password"
            disabled={isLoading}
            autoComplete="off"
            {...register("old_password")}
            color={errors?.old_password ? "failure" : "gray"}
            helperText={
              errors.old_password && (
                <span className="font-medium text-[10px]">
                  {" "}
                  {errors.old_password?.message}!
                </span>
              )
            }
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="old" value="New Pin" />
          </div>
          <TextInput
            id="old"
            type="password"
            disabled={isLoading}
            autoComplete="off"
            className="hide-spin-buttons"
            {...register("new_password")}
            color={errors?.new_password ? "failure" : "gray"}
            helperText={
              errors.new_password && (
                <span className="font-medium text-[10px]">
                  {" "}
                  {errors.new_password?.message}!
                </span>
              )
            }
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="new1" value="Confirm Pin" />
          </div>
          <TextInput
            id="new1"
            className="hide-spin-buttons"
            type="password"
            disabled={isLoading}
            autoComplete="off"
            {...register("confirm_password")}
            color={errors?.confirm_password ? "failure" : "gray"}
            helperText={
              errors.confirm_password && (
                <span className="font-medium text-[10px]">
                  {" "}
                  {errors.confirm_password?.message}!
                </span>
              )
            }
          />
        </div>
      </div>
      <div className="flex justify-end mb-3 p-3">
        <Button
          disabled={isLoading}
          isProcessing={isLoading}
          type="submit"
          className="rounded-md"
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default PinSetting;
