import { api } from "../baseConfig";

interface IQueryLoad {
  company?: string
  name?: string
  description?: string
}

interface ICreateLoad {
  company: string
  name: string
  description?: string
  country_id: string
  markup: string
  uuid?: string
}
export const sellingGroupSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getSellingGroups: builder.query<SellingGroupsResponse, IQueryLoad>({
      query: (data) => ({
        url: "/sellinggroups",
        method: "GET",
        params: data
      }),
      providesTags: ["SellingGroups"],
    }),
    deleteSellingGroup: builder.mutation<any, string>({
        query: (uuid) => ({
          url: `/sellinggroups/${uuid}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SellingGroups"],
      }),
      createSellingGroup: builder.mutation<any, ICreateLoad>({
        query: (data) => ({
          url: "/sellinggroups",
          method: "POST",
          body: data
        }),
        invalidatesTags: ["SellingGroups"],
      }),
      updateSellingGroup: builder.mutation<any, ICreateLoad>({
        query: (data) => ({
          url: `/sellinggroups/${data.uuid}`,
          method: "PUT",
          body: data
        }),
        invalidatesTags: ["SellingGroups"],
      }),
      assignSellingGroups: builder.mutation<any, { selling_group: string, company: string}>({
        query: (data) => ({
          url: "/sellinggroups/add_to_selling_group",
          method: "POST",
          body: data
        }),
        invalidatesTags: ["Companies", "SellingGroups"]
      })

  }),
});

export const { useGetSellingGroupsQuery, useDeleteSellingGroupMutation, useCreateSellingGroupMutation, useUpdateSellingGroupMutation, useAssignSellingGroupsMutation } = sellingGroupSlice;
