import { api } from "../baseConfig";

interface PayloadFilter {
  limit?: string;
  name?: string;
  status?: number;
  company_uuid?: string;
  category?: string;
  user?: string;
  start_date?: string;
  end_date?: string;
  gateway?: string;
  description?: string;
  amount?: number;
}

export const dvaSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getDVA: builder.query<any, PayloadFilter>({
      query: (filters) => ({
        url: `/dva`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["DVA"],
    }),
    createDVA: builder.mutation<any, any>({
      query: (data) => ({
        url: "/dva/create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["DVA"],
    }),
  }),
});

export const { useCreateDVAMutation, useGetDVAQuery } = dvaSlice;
