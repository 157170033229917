import { api } from "../baseConfig";

interface PayloadFilter {
  limit?: string;
  name?: string;
  status?: number | null;
  company?: string;
  category?: string;
  user?: string;
  start_date?: string;
  end_date?: string;
  loaner?: string;
  uuid?: string
}

interface Loan {
  amount: number;
  amount_refund: number;
  company?: { name?: string; uuid: string };
  crdate: string;
  id: number;
  interest: number;
  loaner?: { name?: string; uuid: string };
  period: number;
  status: number;
  user?: { name?: string; type: string; uuid: string; company: string };
  uuid: string;
}

interface LoanResponse {
  data: Loan[];
  status: number;
  count: number;
}

export const loansSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getLoans: builder.query<LoanResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/loans`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Loans"],
    }),
    AddLoan: builder.mutation<any, any>({
      query: (data) => ({
        url: "/loans",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Loans"],
    }),
    changeLoanStatus: builder.mutation<any, PayloadFilter>({
      query: (data) => ({
        url: `/loans/${data.uuid}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Loans"],
    }),
  }),
});

export const { useGetLoansQuery, useAddLoanMutation, useChangeLoanStatusMutation } = loansSlice;
