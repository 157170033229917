import { Sidebar } from "flowbite-react";
import { twMerge } from "tailwind-merge";
import { sideBarMenu } from "./data";
import { HiMiniChevronDown, HiMiniChevronUp } from "react-icons/hi2";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "../lib/hook";
import utills from "../lib/functions";
import { useGetUserQuery } from "../redux/queries/users";

const LeftBar = ({ close }: { close: () => void }) => {
  const { pathname } = useLocation();
  const { user, defaultBranchUUid } = useAppSelector(
    (state) => state.appUserConfig
  );
  const { data: loggedInUser } = useGetUserQuery(user!.uuid, { skip: !user?.uuid });
  const [collapseIndex, setCollapseIndex] = useState<number | null>(null);

  return (
    <div className="h-screen overflow-auto">
      <div className="dark:bg-gray-800 bg-white">
        <Sidebar
          aria-label="Sidebar with multi-level dropdown example"
          className="__sidebar"
        >
          <div className="flex items-center mb-8">
            <img
              width={40}
              src="https://cdn.pixabay.com/photo/2016/09/16/19/15/drug-bottle-1674888_960_720.png"
              alt="logo"
              className="me-2 rounded-full"
            />
            <h4 className="text-lg font-bold dark:text-gray-200">
              GoHealthy POS
            </h4>
          </div>
          <Sidebar.Items>
            <Sidebar.ItemGroup className="">
              {sideBarMenu
                .filter((x) =>
                  !user?.type ? true : x.type.includes(user.type)
                )
                .map((item, index) => {
                  const isUserAuthorize = utills.checkPermmission(item.code, loggedInUser?.data.access.permissions)
                  if(!isUserAuthorize) return null
                  if (item.children) {
                    return (
                      <Sidebar.Collapse
                        className=""
                        key={item.title}
                        icon={item.icon}
                        label={item.title}
                        open={collapseIndex === index}
                        onClick={() =>
                          setCollapseIndex(
                            collapseIndex === index ? null : index
                          )
                        }
                        renderChevronIcon={(theme, open) => {
                          const IconComponent = open
                            ? HiMiniChevronUp
                            : HiMiniChevronDown;

                          return (
                            <IconComponent
                              aria-hidden
                              className={twMerge(
                                theme.label.icon.open[open ? "on" : "off"]
                              )}
                            />
                          );
                        }}
                      >
                        {item.children
                          .filter((x) =>
                            !user?.type ? true : x.type.includes(user.type)
                          )
                          .map((childItem) => {

                            const subLinkAccess = utills.checkSubLinkAccess(childItem.code, loggedInUser?.data.access.permissions)
                            if(!subLinkAccess) return null
                            return (<Sidebar.Item
                              as="span"
                              key={childItem.title}
                              icon={childItem.icon}
                              onClick={close}
                              className={`${
                                pathname.includes(childItem.route) &&
                                "bg-[#1f91b2] hover:bg-[#1f92b2ae] text-white"
                              }`}
                            >
                              <Link
                                to={
                                  childItem.title === "Discards"
                                    ? `/customer/${defaultBranchUUid}/${"discards"}`
                                    : childItem.route
                                }
                              >
                                {childItem.title}
                              </Link>
                            </Sidebar.Item>)
                  })}
                      </Sidebar.Collapse>
                    );
                  } else
                    return (
                      <Sidebar.Item
                        as="span"
                        icon={item.icon}
                        key={item.title}
                        onClick={close}
                        className={`${
                          pathname.includes(item.route) &&
                          "bg-[#1f91b2] hover:bg-[#1f92b2ae] text-white"
                        }`}
                      >
                        <Link to={item.route}>{item.title}</Link>
                      </Sidebar.Item>
                    );
                })}
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
      </div>
    </div>
  );
};

export default LeftBar;
