import React from "react";

interface Breadcrumbs {
  title: string;
  rightButton?: JSX.Element;
  leftButton?: JSX.Element;
}
const Detailscrumb = (props: Breadcrumbs) => {
  const { title, rightButton, leftButton } = props;
  return (
    <div className="my-3 flex justify-between items-center">
      <div>
        <h3 className="text-lg font-semibold dark:text-white">{title}</h3>
        <div className="mt-2">{leftButton}</div>
      </div>
      <div>{rightButton}</div>
    </div>
  );
};

export default Detailscrumb;
