import { MdSearch } from "react-icons/md";

interface CustomInputType {
  label: string;
  value: string;
  onChange: (e: any) => void;
}

const CustomInput = ({ label, value, onChange }: CustomInputType) => {
  return (
    <form className="max-w-sm mx-auto">
      {label && (
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          {label}
        </label>
      )}
      <div className="flex h-8">
        <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-e-0 border-gray-300 rounded-s-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
          <MdSearch />
        </span>
        <input
          type="text"
          id="website-admin"
          className="rounded-none rounded-e-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Search Customer..."
          value={value}
          onChange={(e: any) => onChange(e)}
        />
      </div>
    </form>
  );
};

export default CustomInput;
