import { Button, Label, Select, Textarea, TextInput } from 'flowbite-react'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreateSellingGroupMutation, useUpdateSellingGroupMutation } from '../../redux/queries/sellingGroup';
import { useGetCountriesQuery } from '../../redux/queries/settings';
import BranchSwitcher from '../../components/BranchSwitcher';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../lib/hook';
import toast from 'react-hot-toast';



interface IFormInput {
    company?: string;
    country_id: string;
    name: string;
    description?: string;
    markup: string;
  }
  
  const schema = yup.object().shape({
    company: yup.string(),
    name: yup.string().required("Name is a required field"),
    description: yup.string(),
    markup: yup.string().required("Markup is a required field"),
    country_id: yup.string().required("Country is a required field"),
  });

  interface ISellingGroupForm {
    close: ()=> void
    sellingGroup?: ISellingGroup
  }

const SellingGroupForm = (props: ISellingGroupForm) => {
  const { close, sellingGroup } = props
    const [ createSellingGroup , { isLoading: loading }] = useCreateSellingGroupMutation()
    const [ updateSellingGroup, { isLoading: uLoading} ] = useUpdateSellingGroupMutation()
    const { data: countries } = useGetCountriesQuery()
    const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
    const [company, setCompany] = useState(defaultBranchUUid)

    const isLoading  = sellingGroup ? uLoading : loading
    const { register, handleSubmit, formState: { errors }, setValue, } = useForm<IFormInput>({ resolver: yupResolver(schema) });
    const onSubmit = async (data: IFormInput) => {
        const payload = { ...data, company}
        const res = sellingGroup ? await updateSellingGroup({...payload, uuid: sellingGroup.uuid}) : await createSellingGroup(payload)
        if("data" in res){
          if(res.data.status === 200){
            toast.success(sellingGroup ? "Record Updated": "Selling Group Created")
            close()
          }else toast.error(res.data.data.join(",") ?? "Unable to update record")
        }else toast.error("Unable to create update record")
    }


    useEffect(()=> {
      if(sellingGroup){
        setValue("name", sellingGroup.name)
        setValue("description", sellingGroup.description)
        setValue("markup", sellingGroup.markup.toString())
        setValue("country_id", sellingGroup.country_id)
        setCompany(sellingGroup.company)
      }
    },[])
  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-sm">
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white dark:bg-gray-800 rounded-lg">
        <div className="dark:bg-gray-800 rounded-t-lg p-2">
          <span className="dark:text-white">Enter Branch Information</span>
        </div>

        <div className="grid grid-cols-2 gap-4 p-4">
        <div>
            <div className="mb-2 block">
              <Label htmlFor="name" value="Branch" />
            </div>
            <BranchSwitcher
            disabled={true}
            onChange={(val)=> setCompany(val)}
            styling={{ borderRadius: 3  }}
            />
          </div>


          <div>
            <div className="mb-2 block">
              <Label htmlFor="name" value="Selling group Name" />
            </div>
            <TextInput
              disabled={isLoading}
              {...register("name")}
              id="name"
              type="text"
              className="min-w-[200px]"
              sizing="sm"
              placeholder="Selling group Name"
              style={{ borderRadius: 3 }}
              color={errors?.name ? "failure" : "gray"}
              helperText={
                errors.name && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.name?.message}!
                  </span>
                )
              }
            />
          </div>

          <div className="">
            <div className="mb-2 block">
              <Label htmlFor="markup" value="Markup" />
            </div>
            <TextInput
              disabled={isLoading}
              {...register("markup")}
              id="markup"
              type="number"
              step="any"
              sizing="sm"
              className="min-w-[200px]"
              style={{ borderRadius: 3 }}
              placeholder="Markup"
              color={errors?.markup ? "failure" : "gray"}
              helperText={
                errors.markup && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.markup?.message}!
                  </span>
                )
              }
            />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="stu" value="Country" />
            </div>

            <Select
              disabled={isLoading}
              style={{ borderRadius: 3 }}
              sizing="sm"
              id="stu"
              {...register("country_id")}
            >
                {countries?.data.map(country=> (
                 <option key={country.id} value={country.id}>{country.name}</option>
                ))}
            </Select>
          </div>

        </div>

        <div className="px-4">
            <div className="mb-2 block">
              <Label htmlFor="description" value="Description" />
            </div>
            <Textarea
              disabled={isLoading}
              {...register("description")}
              id="description"
              className="min-w-[200px]"
              style={{ borderRadius: 3 }}
              placeholder="Description"
            />
          </div>

        <div className="p-4">
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            type="submit"
            className="rounded-sm w-full"
            size="xs"
          >
            Submit
          </Button>
        </div>
      </div>
    </form>
  </div>
  )
}

export default SellingGroupForm