import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { LiaTimesSolid } from "react-icons/lia";
import { useGetProductEditHistoryQuery } from "../../redux/queries/products";
import moment from "moment";
import CustomPagination from "../../components/CustomPagination";

interface EditHistoryProps {
  close: () => void;
  item_uuid: string;
}

const ModelEditHistory = ({ close, item_uuid }: EditHistoryProps) => {
  const [filters, setFilters] = useState({
    limit: "0,10",
    item_uuid: item_uuid,
  });

  const { data: editedProductHistory, isLoading } =
    useGetProductEditHistoryQuery(filters);

  return (
    <div className="w-[100%] pt-4 pb-2 px-3 bg-gray-200 dark:bg-gray-900">
      <Breadcrumb
        title="Product Edit History"
        rightButton={
          <span
            className="text-gray-700 dark:text-gray-400 font-bold cursor-pointer text-md"
            onClick={close}
          >
            <LiaTimesSolid />
          </span>
        }
      />
      <div>
        <div className="shadow-md sm:rounded-lg mt-5 w-full">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={editedProductHistory?.data}
            total={editedProductHistory?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Original
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Adjustment
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Adjustment Title
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Done By
                  </th>
                </tr>
              </thead>
              <tbody>
                {editedProductHistory?.data?.map((item) =>
                  item.differences.length ? (
                    <tr
                      key={item.created_at}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="px-6 py-4">
                        {moment(item.created_at).format("LLL")}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex flex-wrap gap-2">
                          {item.differences.map((i, index) => (
                            <span key={i.old}>
                              {i.old}
                              {index < item.differences.length - 1 && ", "}
                            </span>
                          ))}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex flex-wrap gap-2">
                          {item.differences.map((i, index) => (
                            <span key={i.new}>
                              {i.new}
                              {index < item.differences.length - 1 && ", "}
                            </span>
                          ))}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex flex-wrap gap-2">
                          {item.differences.map((i, index) => (
                            <span key={i.key}>
                              {i.key}
                              {index < item.differences.length - 1 && ", "}
                            </span>
                          ))}
                        </div>
                      </td>
                      <td className="px-6 py-4">{item.staff.name}</td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>
    </div>
  );
};

export default ModelEditHistory;
