import { Banner, Button } from "flowbite-react";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { useGetSettingsQuery } from "../../redux/queries/settings";
import { useSyncDataMutation } from "../../redux/queries/offline";
import toast from "react-hot-toast";

const SyncData = () => {
  const { data: settings } = useGetSettingsQuery();
  console.log(settings);

  const [syncData, { isLoading }] = useSyncDataMutation();

  const handleSyncData = async () => {
    const res = await syncData({
      table: [
        "settings",
        "selling_groups",
        "useraccess",
        "generics",
        "items",
        "item_category",
        "location",
        "order",
        "company",
        "users",
        "order",
      ],
    });

    console.log('res', res)
    if ("status" in res) {
      if (res.status === 200) {
        toast.success("Sync Data Done");
      }
    }
  };

  return (
    <div className="mt-10">
      <Banner className="mb-5">
        <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
          <div className="mb-4 md:mb-0 md:mr-4">
            <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
              Sync Data
            </h2>
            {/* <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
              See all user permissions.
            </p> */}
          </div>
          <div className="flex items-end justify-end">
            <Button
              size="xs"
              isProcessing={isLoading}
              onClick={() => handleSyncData()}
            >
              Sync Data
              <MdOutlineArrowRightAlt className="ml-2 h-3 w-3" />
            </Button>
          </div>
        </div>
      </Banner>
    </div>
  );
};

export default SyncData;
