import { useState } from "react";
import PaginationControl from "../../components/PaginationControl";
import { useGetKpiPayoutQuery } from "../../redux/queries/tasks";
import { useAppSelector } from "../../lib/hook";
import { monthsList } from "../../route/data";
import Modal from "react-responsive-modal";
import PayoutTasks from "./PayoutTasks";

interface EachPayoutProps {
  staff: string;
}

const EachPayout = ({ staff }: EachPayoutProps) => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [dailyTask, setDailyTask] = useState({
    open: false,
    company_uuid: user?.company?.uuid,
    staff: staff,
    month: 0,
    year: 0,
  });
  const [filters, setFilters] = useState({
    limit: "0,10",
    company_uuid: user?.company?.uuid,
    staff: staff,
  });

  const { data: payoutData, isLoading } = useGetKpiPayoutQuery(filters);
  const getMonth = (num: number) => {
    return monthsList.filter((type) => type.value === num);
  };

  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <PaginationControl
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={payoutData?.data}
          total={payoutData?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  EXPECTED SALARY
                </th>
                <th scope="col" className="px-6 py-3">
                  PERFORMANCE
                </th>
                <th scope="col" className="px-6 py-3">
                  PAYOUT
                </th>
                <th scope="col" className="px-6 py-3">
                  STATUS
                </th>
                <th scope="col" className="px-6 py-3">
                  Number of Tasks
                </th>
                <th scope="col" className="px-6 py-3">
                  Extrapay
                </th>
              </tr>
            </thead>
            <tbody>
              {payoutData?.data?.map((item) => {
                return (
                  <tr
                    key={item.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    onClick={() =>
                      setDailyTask((prev) => ({
                        ...prev,
                        open: true,
                        month: item.month,
                        year: item.year,
                      }))
                    }
                  >
                    <td className="px-6 py-4">
                      {getMonth(item.month)[0].month} {item.year}
                    </td>
                    <td className="px-6 py-4">{item.salary}</td>
                    <td className="px-6 py-4">{item.performance}</td>
                    <td className="px-6 py-4">{item.payout}</td>
                    <td className="px-6 py-4">
                      {item.status === 1 ? "Active" : "Inactive"}
                    </td>
                    <td className="px-6 py-4">{item.tasks.length}</td>
                    <td className="px-6 py-4">{item.extrapay}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </PaginationControl>
      </div>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__history_modal_bg",
        }}
        center
        open={dailyTask.open}
        onClose={() =>
          setDailyTask((prev) => ({
            ...prev,
            open: false,
            month: 0,
            year: 0,
          }))
        }
      >
        <PayoutTasks
          close={() =>
            setDailyTask((prev) => ({
              ...prev,
              open: false,
              month: 0,
              year: 0,
            }))
          }
          companyUuid={dailyTask.company_uuid}
          staff={dailyTask.staff}
          month={dailyTask.month}
          year={dailyTask.year}
        />
      </Modal>
    </div>
  );
};

export default EachPayout;
