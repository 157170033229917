import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  isTaskLoading: boolean;
  tasks : Task[]
}

const initialState: InitialState = {
  isTaskLoading: false,
  tasks : []
};

const taskParamsSlice = createSlice({
  name: "TASK PARAMS",
  initialState,
  reducers: {
    applyTaskParams: (state, action: PayloadAction<any>) => {
      state.isTaskLoading = action.payload.loading
      state.tasks = action.payload.tasks
    },
  },
});

export const { applyTaskParams } = taskParamsSlice.actions;
export default taskParamsSlice.reducer;