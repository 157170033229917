import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import utills from "../../lib/functions";
import moment from "moment";
import ImageView from "../../components/ImageView";
import { Button, Label, Textarea, TextInput } from "flowbite-react";
import { TbTruckReturn } from "react-icons/tb";
import emptyIcon from "../../assets/empty.png";
import { LiaTimesSolid } from "react-icons/lia";
import { FormEvent, useState } from "react";
import { useReturnPurchasedItemMutation } from "../../redux/queries/stock";
import Modal from "react-responsive-modal";
import toast from "react-hot-toast";

interface PurchaseItem {
  batch_no?: string;
  expiring_date?: string;
  markup?: number;
  selling_price?: number;
  uuid: string;
  quantity: string;
  item: {
    name: string;
  };
}

const PurchaseItems = () => {
  const location = useLocation();
  const { id } = useParams();
  const { purchase } = location.state;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [item_id, setItem_id] = useState("");

  return (
    <div>
      <Breadcrumb title="Purchase Items" />
      <div className="mt-8">
        <Button size="xs" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </div>
      {purchase?.items.length > 0 ? (
        <div className="relative overflow-x-auto mt-5">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-9">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Product name
                </th>
                <th scope="col" className="px-6 py-3">
                  Qty
                </th>
                <th scope="col" className="px-6 py-3">
                  Markup
                </th>
                <th scope="col" className="px-6 py-3">
                  Selling Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Batch No
                </th>
                <th scope="col" className="px-6 py-3">
                  Expiring Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {purchase?.items?.map((product: PurchaseItem) => (
                <tr
                  key={product.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td
                    onClick={(e) => e.stopPropagation()}
                    className="px-6 py-4"
                  >
                    <ImageView url={utills._default_img} className="w-8 h-8" />
                  </td>
                  <td className="px-6 py-4">{product.item.name}</td>
                  <td className="px-6 py-4">{product.quantity}</td>
                  <td className="px-6 py-4">{product.markup}</td>
                  <td className="px-6 py-4">
                    {utills._currency_format(product.selling_price ?? 0, "NGN")}
                  </td>
                  <td className="px-6 py-4">{product.batch_no}</td>
                  <td className="px-6 py-4">
                    {moment(product.expiring_date).format("LLL")}
                  </td>
                  <td className="px-6 py-4">
                    <Button
                      className=""
                      outline
                      size="xs"
                      onClick={() => {
                        setOpen(true);
                        setItem_id(product.uuid);
                      }}
                    >
                      <TbTruckReturn className="mr-2 h-4 w-4" />
                      Return
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex items-center justify-center my-5">
          <div className="flex flex-col items-center">
            <img src={emptyIcon} alt="empty record" className="w-20" />
            <span className="dark:text-gray-400 mt-2 text-xs">
              No record found
            </span>
          </div>
        </div>
      )}

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setItem_id("");
        }}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        showCloseIcon={false}
      >
        <ReturnItem
          close={() => {
            setOpen(false);
            setItem_id("");
          }}
          itemId={item_id}
          stockId={id}
        />
      </Modal>
    </div>
  );
};

export default PurchaseItems;

interface IRefunds {
  close: () => void;
  itemId: string;
  stockId: string | undefined;
}

const ReturnItem = (props: IRefunds) => {
  const { close, itemId, stockId } = props;
  const [fields, setFields] = useState({
    reason: "",
    quantity: "",
  });
  const [returnPurchaseItem, { isLoading }] = useReturnPurchasedItemMutation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = {
      items: [
        {
          uuid: itemId,
          reason: fields.reason,
          quantity: fields.quantity,
        },
      ],
      stock_uuid: stockId,
    };

    const res = await returnPurchaseItem(payload);
    console.log("res", payload, res);
    // if ("data" in res) {
    //   if (res.data.status === 200) {
    //     toast.success("Record Updated");
    //     close();
    //   } else toast.error(res.data.data.message ?? res.data.data.error);
    // } else toast.error("Unable to return item!!!");
  };
  return (
    <div className="w-[400px] bg-white dark:bg-gray-800">
      <div className="bg-[#167490] flex items-center justify-between h-10 px-3">
        <span className="text-white font-semibold text-sm">Return Item</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="p-5" onSubmit={handleSubmit}>
        <div className="mt-3">
          <div className="mb-2 block">
            <Label htmlFor="stu" value="Quantity" />
          </div>

          <TextInput
            value={fields.quantity}
            type="number"
            step="any"
            onChange={(e) =>
              setFields((prev) => ({ ...prev, quantity: e.target.value }))
            }
          />
        </div>
        <div className="mt-5">
          <div className="mb-2 block">
            <Label htmlFor="comment" value="Return reason" />
          </div>
          <Textarea
            disabled={isLoading}
            onChange={(e) =>
              setFields((prev) => ({ ...prev, reason: e.target.value }))
            }
            id="comment"
            placeholder="Return reason..."
            rows={3}
          />
        </div>
        <Button
          disabled={isLoading}
          isProcessing={isLoading}
          type="submit"
          className="rounded-sm w-full mt-5"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};
