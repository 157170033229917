import { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useAppSelector } from "../../lib/hook";
import { Button, Label, Select, TextInput } from "flowbite-react";
import {
  useAddLoanMutation,
  useChangeLoanStatusMutation,
  useGetLoansQuery,
} from "../../redux/queries/loans";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import { useGetCompaniesQuery } from "../../redux/queries/company";
import toast from "react-hot-toast";
import utills from "../../lib/functions";
import CustomPagination from "../../components/CustomPagination";
import CustomDatePicker from "../../components/CustomDatePicker";
import BranchSwitcher from "../../components/BranchSwitcher";
import CustomSelect from "../../components/CustomSelect";

interface statusPayload {
  uuid: string;
  status: number;
}

const Loans = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({
    limit: "0,10",
    loaner: user?.company?.uuid,
    start_date: "",
    end_date: "",
    other_party: "",
  });
  const [filterFields, setFilterFields] = useState({
    start_date: "",
    end_date: "",
  });
  const [resetValue, setResetValue] = useState(false);
  const { data: loans, isLoading } = useGetLoansQuery(filters);
  const [companiesFilters, setCompaniesFilters] = useState({
    limit: "0,10",
    status: 1,
    name: "",
  });
  const { data: companies, isLoading: isLoadingCompanies } =
    useGetCompaniesQuery(companiesFilters);
  const [changeLoanStatus, { isLoading: isChangingStatus }] =
    useChangeLoanStatusMutation();
  const [active, setActive] = useState(0);

  const levels = [
    { name: "All", value: 3 },
    { name: "Pending", value: 0 },
    { name: "Approved", value: 1 },
    { name: "Rejected", value: 2 },
  ];

  const changeStatus = async (data: statusPayload) => {
    const res = await changeLoanStatus(data);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success(res.data.message);
      } else {
        toast.error("Failed to Update!!!");
      }
    } else toast.error("Failed to Update!!!");
  };

  useEffect(() => {
    if (loans?.data.length) {
      setFilterFields((prev) => ({
        ...prev,
        start_date: moment(loans?.data[loans?.data.length - 1]?.crdate).format(
          "YYYY-MM-DD"
        ),
        end_date: moment(loans?.data[0]?.crdate).format("YYYY-MM-DD"),
      }));
    }
  }, [loans?.data]);

  const openAddLoan = () => {
    if (user?.company?.loaner === 1) {
      setIsOpen(true);
    } else {
      toast.error("Please complete your loan settings to continue");
    }
  };

  const todayFn = () => {
    setActive(1);
    if (loans?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const yesterdayFn = () => {
    setActive(2);
    if (loans?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment()
          .startOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .endOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const lastWeekFn = () => {
    setActive(3);
    if (loans?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const reset = () => {
    setActive(0);
    setFilters({
      limit: "0,10",
      loaner: user?.company?.uuid,
      other_party: "",
      start_date: "",
      end_date: "",
    });
    setResetValue(true);
  };

  return (
    <div>
      <Breadcrumb
        title="Loans History"
        rightButton={
          <Button
            size="xs"
            className="flex items-center"
            onClick={() => openAddLoan()}
          >
            <FaPlus className="mr-2" /> Add Loan
          </Button>
        }
      />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm flex flex-wrap items-center gap-5">
        <div className="flex items-center gap-2">
          <Button size="xs" onClick={todayFn} outline={active !== 1}>
            Today
          </Button>
          <Button size="xs" onClick={yesterdayFn} outline={active !== 2}>
            Yesterday
          </Button>
          <Button size="xs" onClick={lastWeekFn} outline={active !== 3}>
            Last Week
          </Button>
        </div>

        <CustomDatePicker
          start={
            filters.start_date.length
              ? filters.start_date
              : filterFields.start_date
          }
          onStartChange={(date) =>
            setFilters((prev) => ({
              ...prev,
              start_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
            }))
          }
          end={
            filters.end_date.length ? filters.end_date : filterFields.end_date
          }
          onEndChange={(date) =>
            setFilters((prev) => ({
              ...prev,
              end_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
            }))
          }
        />
        <BranchSwitcher
          sizing="sm"
          defaultCompany={filters.loaner}
          onChange={(value) =>
            setFilters((prev) => ({ ...prev, loaner: value }))
          }
        />

        <div className="w-[200px]">
          <CustomSelect
            isClearable={false}
            disabled={isLoading || isLoadingCompanies}
            loading={isLoading || isLoadingCompanies}
            placeholder="Select Customer..."
            inputClasses="h-9"
            options={
              companies?.data.map((customer) => ({
                label: customer.name,
                value: customer.uuid,
              })) ?? []
            }
            onSelect={(val) => {
              setFilters((prev) => ({
                ...prev,
                other_party: val.value,
              }));
              setResetValue(false);
            }}
            onSearch={(text) => {
              setCompaniesFilters((prev) => ({ ...prev, name: text }));
              setResetValue(false);
            }}
            reset={resetValue}
          />
        </div>

        <div className="flex items-center justify-center">
          <Select
            name="status"
            required
            sizing="sm"
            onChange={(e) => {
              if (Number(e.target.value) === 3) {
                setFilters({
                  limit: "0,10",
                  loaner: user?.company?.uuid,
                  start_date: "",
                  end_date: "",
                  other_party: "",
                });
              } else {
                setFilters((prev) => ({
                  ...prev,
                  status: Number(e.target.value),
                }));
              }
            }}
          >
            <option value="" hidden>
              Select Status
            </option>

            {levels?.map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
        </div>

        <div className="flex gap-2 ml-auto items-end justify-end">
          <Button outline size="xs" onClick={reset}>
            Reset
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={loans?.data}
          total={loans?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Pharmacy
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount / Period
                </th>
                <th scope="col" className="px-6 py-3">
                  Interest
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {loans?.data?.map((item, i) => (
                <tr
                  key={i}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4">{i + 1}</td>
                  <td className="px-6 py-4">{item?.company?.name ?? "-"}</td>
                  <td className="px-6 py-4">
                    {utills._currency_format(Number(item.amount), "NGN")} /{" "}
                    {item.period} months
                  </td>
                  <td className="px-6 py-4">{item?.interest}</td>
                  <td className="px-6 py-4">
                    {moment(item.crdate).format("LLL")}
                  </td>

                  <td className="px-6 py-4 flex gap-2 items-center">
                    <Button
                      disabled={item.status === 1}
                      isProcessing={isChangingStatus}
                      size="xs"
                      onClick={() =>
                        changeStatus({ uuid: item.uuid, status: 1 })
                      }
                      color="success"
                    >
                      Approve
                    </Button>
                    <Button
                      disabled={item.status === 2}
                      size="xs"
                      isProcessing={isChangingStatus}
                      onClick={() =>
                        changeStatus({ uuid: item.uuid, status: 2 })
                      }
                      color="failure"
                    >
                      Reject
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CustomPagination>
      </div>
      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <AddLoan close={() => setIsOpen(false)} user={user} />
      </Modal>
    </div>
  );
};

export default Loans;

interface UserCard {
  close: () => void;
  user: AppUser | null;
}

const AddLoan = ({ close, user }: UserCard) => {
  const data = {
    type: "",
    status: 1,
  };
  const { data: companies } = useGetCompaniesQuery(data);
  const [AddLoan, { isLoading }] = useAddLoanMutation();

  const [payload, setPayload] = useState({
    company: "",
    loaner: user?.company?.uuid,
    amount: "",
    period: "",
    interest: user?.company?.loan_setting?.interest,
  });

  const onSubmit = async () => {
    const res = await AddLoan(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success(res.data.message);
        close()
      } else {
        toast.error(res.data.message);
      }
    } else toast.error("Error- Adding Loan Failed!!!");
  };

  return (
    <div className="w-[500px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-semibold">Add Loan</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <div className="bg-white p-3 pb-5">
        <div className="grid grid-cols-2 gap-3 mt-2">
          <div className="">
            <Label
              htmlFor="loan-prov"
              value="Loan Provider"
              className="text-sm dark:text-gray-800"
            />
            <TextInput
              disabled={isLoading}
              sizing="md"
              type="text"
              placeholder="Enter "
              value={user?.company?.name}
              style={{ borderRadius: 0 }}
              className="mt-1"
              readOnly
            />
          </div>

          <div className="">
            <Label
              htmlFor="company"
              value="Company"
              className="text-sm dark:text-gray-800"
            />
            <Select
              name="company"
              required
              sizing="md"
              className="mt-1"
              disabled={isLoading}
              style={{ borderRadius: 0 }}
              onChange={(e) =>
                setPayload((prev) => ({
                  ...prev,
                  company: e.target.value,
                }))
              }
            >
              <option value="" hidden>
                Select Company Name
              </option>

              {companies?.data?.map((item) => (
                <option key={item.id} value={item.uuid}>
                  {item.name}
                </option>
              ))}
            </Select>
          </div>

          <div className="">
            <Label
              htmlFor="amount"
              value="Amount"
              className="text-sm dark:text-gray-800"
            />
            <TextInput
              disabled={isLoading}
              sizing="md"
              type="number"
              placeholder="Enter Amount"
              value={payload?.amount}
              style={{ borderRadius: 0 }}
              className="mt-1"
              onChange={(e) =>
                setPayload((prev) => ({
                  ...prev,
                  amount: e.target.value,
                }))
              }
            />
          </div>

          <div className="">
            <Label
              htmlFor="period"
              value="Maximum Period"
              className="text-sm dark:text-gray-800"
            />
            <TextInput
              disabled={isLoading}
              sizing="md"
              type="number"
              placeholder="Enter Period in Month"
              value={payload?.period}
              style={{ borderRadius: 0 }}
              className="mt-1"
              onChange={(e) =>
                setPayload((prev) => ({
                  ...prev,
                  period: e.target.value,
                }))
              }
            />
          </div>
        </div>

        <div className="mt-7 flex items-center justify-end">
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            size="md"
            className="rounded-sm"
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
