import { FormEvent, useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import {
  useGetChequesQuery,
  useUpdateChequeMutation,
  useUpdateChequeStatusMutation,
} from "../../redux/queries/Payments";
import { useAppSelector } from "../../lib/hook";
import moment from "moment";
import {
  Badge,
  Button,
  Datepicker,
  Label,
  Select,
  TextInput,
} from "flowbite-react";
import toast from "react-hot-toast";
import CustomPagination from "../../components/CustomPagination";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import type { CustomFlowbiteTheme } from "flowbite-react";
import CustomDropdown from "../../components/CustomDropdown";
import { CiCircleMore } from "react-icons/ci";
import { FaRegEdit } from "react-icons/fa";
import { GiNuclear } from "react-icons/gi";
import { TbBounceLeftFilled } from "react-icons/tb";
import { TfiBackLeft } from "react-icons/tfi";
import { FaUndoAlt } from "react-icons/fa";
import utills from "../../lib/functions";
import { CiMoneyCheck1 } from "react-icons/ci";
import BranchSwitcher from "../../components/BranchSwitcher";
import CustomDatePicker from "../../components/CustomDatePicker";
import CustomSelect from "../../components/CustomSelect";
import { useGetCompaniesQuery } from "../../redux/queries/company";

interface PayloadFilter {
  cheque_no: number;
  payment_uuid: string;
  status: string;
  cheque_post_date: string;
}

const Cheques = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [open, setOpen] = useState(false);
  const [uuid, setUuid] = useState("");
  const [filterFields, setFilterFields] = useState({
    start_date: "",
    end_date: "",
  });
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: defaultBranchUUid,
    other_party: "",
    start_date: "",
    end_date: "",
  });
  const [resetValue, setResetValue] = useState(false);
  const { data: chequeData, isLoading } = useGetChequesQuery(filters);
  const [companiesFilters, setCompaniesFilters] = useState({
    limit: "0,10",
    status: 1,
    name: "",
  });
  const { data: companies, isLoading: isLoadingCompanies } =
    useGetCompaniesQuery(companiesFilters);
  const [updateChequeStatus] = useUpdateChequeStatusMutation();
  const [active, setActive] = useState(0);

  const handleUpdateCheque = async (payload: PayloadFilter) => {
    const res = (await updateChequeStatus(payload)) as any;
    if (res.data.status === 200) {
      toast.success(res.data.message);
    } else {
      toast.error("Something went wrong");
    }
  };

  const getStatus = (status: number) => {
    switch (status) {
      case 1:
        return "Cleared";
      case 11:
        return "Bounced";
      case 12:
        return "Retrieved";
      default:
        return "Pending";
    }
  };

  const getColor = (status: number) => {
    switch (status) {
      case 1:
        return "success";
      case 11:
        return "failure";
      case 12:
        return "indigo";
      default:
        return "warning";
    }
  };

  useEffect(() => {
    if (chequeData?.data.length) {
      setFilterFields((prev) => ({
        ...prev,
        start_date: chequeData?.data[chequeData?.data.length - 1]?.crdate.length
          ? moment(
              chequeData?.data[chequeData?.data.length - 1]?.crdate
            ).format("YYYY-MM-DD HH:mm:ss")
          : "",
        end_date: chequeData?.data[0]?.crdate.length
          ? moment(chequeData?.data[0]?.crdate).format("YYYY-MM-DD HH:mm:ss")
          : "",
      }));
    }
  }, [chequeData?.data]);

  const reset = () => {
    setActive(0);
    setFilters({
      limit: "0,10",
      company: defaultBranchUUid,
      other_party: "",
      start_date: "",
      end_date: "",
    });
    setResetValue(true);
  };

  const statuses = [
    { name: "Pending", value: 0 },
    { name: "Cleared", value: 1 },
    { name: "Bounced", value: 11 },
    { name: "Retrieved", value: 12 },
  ];

  const todayFn = () => {
    setActive(1);
    if (chequeData?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const yesterdayFn = () => {
    setActive(2);
    if (chequeData?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment()
          .startOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .endOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const lastWeekFn = () => {
    setActive(3);
    if (chequeData?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  return (
    <div>
      <Breadcrumb title="Cheques" />
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex items-center gap-5">
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Cheques
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <CiMoneyCheck1 className="text-red-500 text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>{utills._figure_format(Number(chequeData?.total) ?? 0)}</span>
          </div>
        </div>
      </div>

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm flex gap-5 items-center">
        <div className="flex flex-wrap items-center gap-3 w-full">
          <div className="flex items-center gap-2">
            <Button size="xs" onClick={todayFn} outline={active !== 1}>
              Today
            </Button>
            <Button size="xs" onClick={yesterdayFn} outline={active !== 2}>
              Yesterday
            </Button>
            <Button size="xs" onClick={lastWeekFn} outline={active !== 3}>
              Last Week
            </Button>
          </div>
          <CustomDatePicker
            start={
              filters.start_date.length
                ? filters.start_date
                : filterFields.start_date
            }
            onStartChange={(date) =>
              setFilters((prev) => ({
                ...prev,
                start_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
              }))
            }
            end={
              filters.end_date.length ? filters.end_date : filterFields.end_date
            }
            onEndChange={(date) =>
              setFilters((prev) => ({
                ...prev,
                end_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
              }))
            }
          />
          <Select
            name="status"
            required
            sizing="sm"
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                status: Number(e.target.value),
              }));
            }}
          >
            <option value="" hidden>
              Select Status
            </option>

            {statuses?.map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
          <div>
            <Select
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  orderby: e.target.value,
                }))
              }
              id="sort"
              sizing="sm"
            >
              <option value="" disabled hidden>
                Sort Order
              </option>
              <option value="">Cheque Date</option>
              <option value="">Cheque Post Date</option>
            </Select>
          </div>

          <div>
            <BranchSwitcher
              sizing="sm"
              defaultCompany={filters.company}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, company: value }))
              }
            />
          </div>

          <div className="w-[200px]">
            <CustomSelect
              isClearable={false}
              disabled={isLoading || isLoadingCompanies}
              loading={isLoading || isLoadingCompanies}
              placeholder="Select Customer..."
              inputClasses="h-9"
              options={
                companies?.data.map((customer) => ({
                  label: customer.name,
                  value: customer.uuid,
                })) ?? []
              }
              onSelect={(val) => {
                setFilters((prev) => ({
                  ...prev,
                  other_party: val.value,
                }));
                setResetValue(false);
              }}
              onSearch={(text) => {
                setCompaniesFilters((prev) => ({ ...prev, name: text }));
                setResetValue(false);
              }}
              reset={resetValue}
            />
          </div>

          <div className="ml-5 flex gap-2">
            <Button outline size="xs" onClick={reset}>
              Reset
            </Button>
          </div>
        </div>
      </div>

      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={chequeData?.data}
            total={chequeData?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Cheque no
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Bank
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Cheque Value
                  </th>
                  <th scope="col" className="px-6 py-3 flex items-center gap-2">
                    Staff
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Pharmacy
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Cheque Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Cheque Post-Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {chequeData?.data?.map((item: ChequeDetails) => {
                  const list =
                    item.status !== 1
                      ? [
                          {
                            icon: FaRegEdit,
                            title: "Edit",
                            action: () => {
                              setOpen(true);
                              setUuid(item.uuid);
                            },
                          },
                          {
                            icon: GiNuclear,
                            title: "Cleared",
                            action: () => {
                              handleUpdateCheque({
                                cheque_no: item.json_init.cheque_no,
                                payment_uuid: item.uuid,
                                status: "1",
                                cheque_post_date:
                                  item.json_init.cheque_post_date,
                              });
                            },
                          },
                          {
                            icon: TbBounceLeftFilled,
                            title: "Bounced",
                            action: () => {
                              handleUpdateCheque({
                                cheque_no: item.json_init.cheque_no,
                                payment_uuid: item.uuid,
                                status: "11",
                                cheque_post_date:
                                  item.json_init.cheque_post_date,
                              });
                            },
                          },
                          {
                            icon: TfiBackLeft,
                            title: "Retrieved",
                            action: () => {
                              handleUpdateCheque({
                                cheque_no: item.json_init.cheque_no,
                                payment_uuid: item.uuid,
                                status: "12",
                                cheque_post_date:
                                  item.json_init.cheque_post_date,
                              });
                            },
                          },
                        ]
                      : [
                          {
                            icon: FaRegEdit,
                            title: "Edit",
                            action: () => {
                              setOpen(true);
                              setUuid(item.uuid);
                            },
                          },
                          {
                            icon: FaUndoAlt,
                            title: "Undo",
                            action: () => {
                              handleUpdateCheque({
                                cheque_no: item.json_init.cheque_no,
                                payment_uuid: item.uuid,
                                status: "0",
                                cheque_post_date:
                                  item.json_init.cheque_post_date,
                              });
                            },
                          },
                        ];
                  return (
                    <tr
                      key={item.id}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="px-6 py-4">#{item.json_init.cheque_no}</td>
                      <td className="px-6 py-4">{item?.bank?.name ?? "-"}</td>
                      <td className="px-6 py-4">
                        {utills._figure_format(Number(item.amount))}
                      </td>
                      <td className="px-6 py-4">
                        {item.staff === null ? "-" : item.staff?.name}
                      </td>
                      <td className="px-6 py-4">{item.company.name}</td>
                      <td className="px-6 py-4">
                        {moment(item.crdate).format("LLL")}
                      </td>
                      <td className="px-6 py-4">
                        {moment(item.json_init.cheque_post_date).format("LLL")}
                      </td>
                      <td className="px-6 py-4 flex items-center justify-center">
                        <Badge color={getColor(item.status)}>
                          {getStatus(item.status)}
                        </Badge>
                      </td>
                      <td className="px-6 py-4">
                        <CustomDropdown
                          trigger={
                            <div className="">
                              <CiCircleMore className="text-2xl" />
                            </div>
                          }
                          menu={list}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        showCloseIcon={false}
      >
        <EditCheque
          close={() => {
            setOpen(false);
            setUuid("");
          }}
          uuid={uuid}
        />
      </Modal>
    </div>
  );
};

export default Cheques;

interface IRefunds {
  close: () => void;
  uuid: string;
}

const EditCheque = (props: IRefunds) => {
  const { close, uuid } = props;
  const [fields, setFields] = useState({
    cheque_no: "",
    cheque_post_date: "",
  });
  const [updateCheque, { isLoading }] = useUpdateChequeMutation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = {
      payment_uuid: uuid,
      cheque_no: fields.cheque_no,
      cheque_post_date: fields.cheque_post_date,
      uuid: uuid,
    };
    const res = await updateCheque(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record Updated");
        close();
      } else if (res.data.status === 100) {
        toast(res.data.message);
        close();
      }
    } else toast.error("Unable to update cheque!!!");
  };

  const customTheme: CustomFlowbiteTheme["datepicker"] = {
    popup: {
      root: {
        base: "absolute z-50 block pt-2",
        inline: "relative top-[-20px] z-50",
        inner:
          "inline-block rounded-lg bg-white p-4 shadow-lg bg-red-500 dark:bg-gray-700",
      },
    },
  };
  return (
    <div className="w-[400px] bg-white dark:bg-gray-800">
      <div className="bg-[#167490] flex items-center justify-between h-10 px-3">
        <span className="text-white font-semibold text-sm">Return Item</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="p-5" onSubmit={handleSubmit}>
        <div className="">
          <div className="mb-2 block">
            <Label htmlFor="chq_post_date" value="Post Date" />
          </div>
          <Datepicker
            theme={customTheme}
            value={fields.cheque_post_date}
            onSelectedDateChanged={(date) =>
              setFields((prev) => ({
                ...prev,
                cheque_post_date: moment(date).format("YYYY-MM-DD"),
              }))
            }
            name="date"
            datepicker-format={"YYYY-MM-DD"}
            className="z-10 overflow-visible"
          />
        </div>

        <div className="mt-14">
          <div className="mb-2 block">
            <Label htmlFor="chq_no" value="Cheque Number" />
          </div>

          <TextInput
            value={fields.cheque_no}
            type="number"
            step="any"
            onChange={(e) =>
              setFields((prev) => ({ ...prev, cheque_no: e.target.value }))
            }
          />
        </div>
        <Button
          disabled={isLoading}
          isProcessing={isLoading}
          type="submit"
          className="rounded-sm w-full mt-40"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};
