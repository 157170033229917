import { api } from "../baseConfig";

interface PayloadFilter {
  company?: string;
  limit?: string;
  customer_company_uuid?: string;
  name?: string;
  start_date?: string;
  end_string?: string;
}

export const dashboardSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<DashboardResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/dashboard`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Dashboard"],
    }),
    getCustomerOverview: builder.query<CustomerOverviewResponse, PayloadFilter>(
      {
        query: (filters) => ({
          url: `/dashboard/customer_overview`,
          method: "GET",
          params: filters,
        }),
        providesTags: ["Dashboard"],
      }
    ),
  }),
});

export const { useGetDashboardQuery, useGetCustomerOverviewQuery } =
  dashboardSlice;
