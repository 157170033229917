import { useEffect, useState } from "react";
import {
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
} from "../../redux/queries/settings";
import CustomPagination from "../../components/CustomPagination";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import { Badge, Button, Label, Select, TextInput } from "flowbite-react";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../lib/hook";
import toast from "react-hot-toast";

const Categories = () => {
  const [filters, setFilters] = useState({
    limit: "0,10",
    type: "manufacturer",
  });
  const { data: categories, isLoading } = useGetCategoriesQuery(filters);
  const [deleteCategory, { isLoading: isDeleting }] =
    useDeleteCategoryMutation();
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState<Category | undefined>(undefined);
  const onDelete = async (id: string) => {
    const res = await deleteCategory(id);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success(res.data.message);
      } else toast.error(`${res.data.data} - ${res.data.message as string}`);
    } else toast.error("Unable to update record");
  };

  return (
    <div className="px-4 mt-5">
      <Button
        size="xs"
        className="rounded-none"
        onClick={() => setModal(!modal)}
      >
        Add New Category
      </Button>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading || isDeleting}
          data={categories?.data}
          total={categories?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Number
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {categories?.data?.map((item) => {
                return (
                  <tr
                    key={item.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="pl-3" onClick={(e) => e.stopPropagation()}>
                      <ImageView
                        url={utills._default_img}
                        className="w-8 h-8"
                      />
                    </td>
                    <td className="px-6 py-4">{item.name}</td>
                    <td className="px-6 py-4">{item.order}</td>
                    <td className="px-6 py-4">{item.type}</td>
                    <td className="px-6 py-4">
                      {item.status === 1 ? (
                        <Badge color="info" className="w-fit">
                          Active
                        </Badge>
                      ) : (
                        <Badge color="gray" className="w-fit">
                          Inactive
                        </Badge>
                      )}
                    </td>

                    <td className="px-6 py-4">
                      <div className="flex items-center gap-1">
                        <Button
                          size="xs"
                          onClick={() => {
                            setModal(!modal);
                            setDetails(item);
                          }}
                          color="blue"
                          className="rounded-none"
                        >
                          Edit
                        </Button>
                        <Button
                          className="rounded-none"
                          size="xs"
                          onClick={() => onDelete(item.uuid)}
                          color="failure"
                        >
                          Delete
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CustomPagination>
      </div>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={modal}
        onClose={() => setModal(false)}
      >
        <FormCategory close={() => setModal(false)} category={details} />
      </Modal>
    </div>
  );
};

export default Categories;

interface CategoryCard {
  close: () => void;
  category: Category | undefined;
}

interface IFormInput {
  name: string;
  order?: string;
  status: string;
}

const schema = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  status: yup.string().required("Status is a required field"),
  order: yup.string(),
});

const FormCategory = ({ close, category }: CategoryCard) => {
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [createCategory, { isLoading: isCreating }] =
    useCreateCategoryMutation();
  const [updateCategory, { isLoading: isUpdating }] =
    useUpdateCategoryMutation();

  const onSubmit = async (data: IFormInput) => {
    const payload = {
      ...data,
      type: user?.type,
    };
    const res = category
      ? await updateCategory({ ...payload, id: category.uuid })
      : await createCategory({ ...payload });
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success(res.data.message);
        close();
      } else toast.error(`${res.data.data} - ${res.data.message as string}`);
    } else toast.error("Unable to update record");
  };

  useEffect(() => {
    if (category) {
      setValue("name", category.name);
      setValue("status", category.status.toString());
      setValue("order", category.order.toString());
    }
  }, [category, setValue]);

  return (
    <div className="w-[500px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-semibold">
          {category ? "Edit Category" : "Create Category"}
        </span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <form className="bg-white p-3 pb-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-3 mt-2">
          <div className="">
            <Label
              value="Category Name"
              className="text-sm dark:text-gray-800"
            />
            <TextInput
              sizing="sm"
              type="text"
              disabled={isCreating || isUpdating}
              placeholder="Name"
              {...register("name")}
              color={errors?.name ? "failure" : "gray"}
              helperText={
                errors.name && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.name?.message}!
                  </span>
                )
              }
            />
          </div>

          <div className="">
            <Label
              value="Category Nmuber"
              className="text-sm dark:text-gray-800"
            />
            <TextInput
              sizing="sm"
              type="text"
              disabled={isCreating || isUpdating}
              placeholder="Nmuber"
              {...register("order")}
            />
          </div>

          <div>
            <Label value="Status" />
            <Select
              sizing="sm"
              disabled={isCreating || isUpdating}
              {...register("status")}
              color={errors?.status ? "failure" : "gray"}
              helperText={
                errors.status && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.status?.message}!
                  </span>
                )
              }
            >
              <option value={1}>Active</option>
              <option value={0}>InActive</option>
            </Select>
          </div>
        </div>

        <div className="mt-7 flex items-center justify-end">
          <Button
            disabled={isCreating || isUpdating}
            isProcessing={isCreating || isUpdating}
            size="sm"
            className="rounded-sm"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};
