import moment from "moment";
import { useAppSelector } from "../../lib/hook";
import { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useGetProductEditHistoryQuery } from "../../redux/queries/products";
import { Datepicker } from "flowbite-react";
import CustomPagination from "../../components/CustomPagination";

const StockMovement = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: user?.company?.uuid,
    start_date: "",
    end_date: "",
  });

  const { data: stockMovement, isLoading } =
    useGetProductEditHistoryQuery(filters);

  useEffect(() => {
    if (stockMovement?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment(stockMovement?.data[0]?.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date: moment(
          stockMovement?.data[stockMovement?.data.length - 1]?.created_at
        ).format("YYYY-MM-DD"),
      }));
    }
  }, [stockMovement?.data]);

  return (
    <div>
      <Breadcrumb title="Stock Movement" />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm flex gap-5 items-center">
        <div className="flex items-center gap-2 border border-gray-200 dark:bg-gray-800 rounded-md px-3 text-gray-700 dark:text-gray-400">
          <span className="text-sm font-medium">
            Select Start Date and End Date :
          </span>
          <Datepicker
            value={filters.start_date}
            onSelectedDateChanged={(date) =>
              setFilters((prev) => ({
                ...prev,
                start_date: moment(date).format("YYYY-MM-DD"),
              }))
            }
            style={{
              borderWidth: "0",
              backgroundColor: "transparent",
              width: "130px",
              outline: "none",
            }}
            name="date"
            datepicker-format={"YYYY-MM-DD"}
          />

          <Datepicker
            value={filters.end_date}
            onSelectedDateChanged={(date) =>
              setFilters((prev) => ({
                ...prev,
                end_date: moment(date).format("YYYY-MM-DD"),
              }))
            }
            style={{
              borderWidth: "0",
              backgroundColor: "transparent",
              width: "130px",
            }}
            name="date"
            datepicker-format={"YYYY-MM-DD"}
          />
        </div>
      </div>

      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={stockMovement?.data}
            total={stockMovement?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Product Name
                  </th>
                  {/* <th scope="col" className="px-6 py-3">
                    Location
                  </th> */}
                  <th scope="col" className="px-6 py-3">
                    Old Oty
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Change Qty
                  </th>
                  <th scope="col" className="px-6 py-3">
                    New Qty
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Stock Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action Type
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Details
                  </th>
                  {/* <th scope="col" className="px-6 py-3">
                    Recipient
                  </th> */}
                  <th scope="col" className="px-6 py-3">
                    User
                  </th>
                </tr>
              </thead>
              <tbody>
                {stockMovement?.data?.map((item) => {
                  const quantity = item?.new?.quantity ?? item?.item?.quantity;
                  const displayQuantity =
                    quantity < 0
                      ? "Out of Stock"
                      : quantity ?? "Unknown Quantity";
                  return (
                    <tr
                      key={item.created_at}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="px-6 py-4">
                        {moment(item.created_at).format("LLL")}
                      </td>
                      <td className="px-6 py-4">{item.item.name}</td>
                      <td className="px-6 py-4">
                        {item?.old?.quantity ?? item.item.quantity}
                      </td>

                      <td className="px-6 py-4">
                        {item?.new?.quantity && item?.old?.quantity
                          ? item.old.quantity - item.new.quantity
                          : 0}
                      </td>

                      <td className="px-6 py-4">{displayQuantity}</td>

                      <td className="px-6 py-4">
                        {item.new.status === 1 ? "In Stock" : "Out of Stock"}
                      </td>

                      <td className="px-6 py-4">{item.action}</td>

                      <td className="px-6 py-4">{item.description}</td>

                      <td className="px-6 py-4">{item.staff.name ?? "-"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>
    </div>
  );
};

export default StockMovement;
