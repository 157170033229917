import { FormEvent, useState } from "react";
import { useMoveItemMutation } from "../../redux/queries/products";
import { Button, Label, Textarea, TextInput } from "flowbite-react";
import { LiaTimesSolid } from "react-icons/lia";
import toast from "react-hot-toast";

interface MoveItemProps {
  close: () => void;
  item_uuid: string;
  type: number;
}

const MoveProduct = (props: MoveItemProps) => {
  const { close, item_uuid, type } = props;
  const [fields, setFields] = useState({
    reason: "",
    quantity: "",
  });
  const [moveItem, { isLoading }] = useMoveItemMutation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = {
      item_uuid: item_uuid,
      type: type,
      reason: fields.reason,
      quantity: Number(fields.quantity),
    };

    const res = await moveItem(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record Updated");
        close();
      } else if (res.data.status === 100) {
        toast.error(res.data.message);
      } else toast.error("Unable to Move item!");
    } else toast.error("Unable to Move item!");
  };

  return (
    <div className="w-[400px] bg-white dark:bg-gray-800">
      <div className="bg-[#167490] flex items-center justify-between h-10 px-3">
        <span className="text-white font-semibold text-sm">
          Move Item to {type === 3 ? "Scrap" : "Expire"}
        </span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="p-5" onSubmit={handleSubmit}>
        <div className="mt-3">
          <div className="mb-2 block">
            <Label htmlFor="stu" value="Quantity" />
          </div>

          <TextInput
            value={fields.quantity}
            type="number"
            step="any"
            onChange={(e) =>
              setFields((prev) => ({ ...prev, quantity: e.target.value }))
            }
          />
        </div>
        <div className="mt-5">
          <div className="mb-2 block">
            <Label htmlFor="comment" value="Move Reason" />
          </div>
          <Textarea
            disabled={isLoading}
            onChange={(e) =>
              setFields((prev) => ({ ...prev, reason: e.target.value }))
            }
            id="comment"
            placeholder="Move reason..."
            rows={3}
          />
        </div>
        <Button
          disabled={
            isLoading || !fields.quantity.length || !fields.reason.length
          }
          isProcessing={isLoading}
          type="submit"
          className="rounded-sm w-full mt-5"
        >
          Submit
        </Button>
      </form>
    </div>
  );
};

export default MoveProduct;
