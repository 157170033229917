import { Button, Label, TextInput } from "flowbite-react";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import { useState } from "react";
import toast from "react-hot-toast";
import { useUpdateCompanyMutation } from "../../redux/queries/company";
import { updateUserRecord } from "../../redux/slices/appUser";
import { useUpdateUserMutation } from "../../redux/queries/users";

const Loan = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [payload, setPayload] = useState({
    amount: user?.company?.loan_setting?.amount ?? "",
    interest: user?.company?.loan_setting?.interest ?? "",
    period: user?.company?.loan_setting?.period ?? "",
  });
  const [updateCompany, { isLoading }] = useUpdateCompanyMutation();
  const dispatch = useAppDispatch();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  const onSubmit = async (num: number) => {
    const result = {
      loan_setting: {
        amount: Number(payload.amount),
        period: Number(payload.period),
        interest: Number(payload.interest),
      },
      loaner: num,
      uuid: user?.company?.uuid,
    };
    const res = await updateCompany(result);
    if ("data" in res) {
      if (res.data.status === 200) {
        const res2 = await updateUser({
          uuid: user?.uuid,
        });
        if ("data" in res2) {
          if (res2.data.status !== 200) {
            toast.error(res.data.message);
          } else {
            toast.success("Loan Settings Successfully added");
            dispatch(updateUserRecord(res2.data.data));
          }
        } else toast.error("Error- Action Failed!!!");
      } else {
        toast.error(res.data.message);
      }
    } else toast.error("Error- Adding Loan Failed!!!");
  };

  return (
    <div className="flex items-center justify-center mt-10">
      <div className="xl:max-w-xl w-full px-2 xl:px-0">
        <form className="p-3 pb-5">
          <div className="flex-1">
            <div className="grid grid-cols-2 gap-3">
              <div>
                <Label value="Loan Amount" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading || isUpdating}
                  placeholder="Amount"
                  value={payload?.amount}
                  style={{ borderRadius: 0 }}
                  className="mt-1"
                  onChange={(e) =>
                    setPayload((prev) => ({
                      ...prev,
                      amount: e.target.value,
                    }))
                  }
                />
              </div>
              <div>
                <Label value="Interest" />
                <TextInput
                  sizing="sm"
                  type="number"
                  disabled={isLoading || isUpdating}
                  placeholder="interest"
                  value={payload?.interest}
                  style={{ borderRadius: 0 }}
                  className="mt-1"
                  onChange={(e) =>
                    setPayload((prev) => ({
                      ...prev,
                      interest: e.target.value,
                    }))
                  }
                />
              </div>
              <div>
                <Label value="Maximum Period" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading || isUpdating}
                  placeholder="Period"
                  value={payload?.period}
                  style={{ borderRadius: 0 }}
                  className="mt-1"
                  onChange={(e) =>
                    setPayload((prev) => ({
                      ...prev,
                      period: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="mt-10 flex items-center justify-end">
            <Button
              disabled={isLoading || isUpdating}
              isProcessing={isLoading || isUpdating}
              size="xs"
              className="rounded-sm"
              color={user?.company?.loaner === 1 ? "failure" : "success"}
              onClick={() =>
                user?.company?.loaner === 1 ? onSubmit(0) : onSubmit(1)
              }
            >
              {user?.company?.loaner === 1 ? "CANCEL" : "APPLY"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Loan;
