import React from 'react'

interface Breadcrumbs {
  title?: string
  rightButton?: JSX.Element
}
const Breadcrumb = (props: Breadcrumbs) => {
  const { title, rightButton } = props
  return (
    <div className='my-3 flex justify-between items-center'>
        <h3 className="text-lg font-semibold dark:text-white">{title}</h3>
        <div>{rightButton}</div>
    </div>
  )
}

export default Breadcrumb