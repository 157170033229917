import { configureStore, Middleware } from "@reduxjs/toolkit";
import { ThunkMiddleware, thunk } from "redux-thunk";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { api } from "../baseConfig";
import appUser from "../slices/appUser";
import cart from "../slices/cart";
import utills from "../slices/utills";
import taskParams from "../slices/taskParams";

const persistConfig = {
  key: "root",
  storage,
};

const appUserConfig = persistReducer(persistConfig, appUser);
const appCartAndDraft = persistReducer(persistConfig, cart);

export const store = configureStore({
  reducer: {
    appUserConfig: appUserConfig,
    cartAndDraft: appCartAndDraft,
    utills: utills,
    taskParams: taskParams,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore the same set of actions as before
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE],
      } as any,
    }).concat(thunk as ThunkMiddleware, api.middleware as Middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
