import { useEffect } from "react";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { useLazyGetOrderQuery } from "../../redux/queries/order";
import { PagePrint, PagePrint2 } from "./Invoice";

const PrintInvoiceAfterCreating = ({ orderId, close }: { orderId?: string; close: () => void }) => {
  const [getOrder, { data: order, isLoading }] = useLazyGetOrderQuery();

  useEffect(() => {
    if (orderId) {
      getOrder(orderId);
    }
  }, [orderId, getOrder]);

  if (isLoading)
    return (
      <div className="min-w-[500px] flex items-center justify-center h-20 bg-white">
        <FaSpinner size={25} />
      </div>
    );

  if (!order?.data) return null;

  return (
    <div className="bg-white p-5 w-full ">
      <div className="flex justify-between items-center">
        <div></div>
        <button onClick={close}>
          <FaTimes />
        </button>
      </div>
      <PagePrint order={order?.data} items={order?.data?.items?.slice(0, 36)} totalPage={Math.ceil((order?.data?.items?.length ?? 0) / 36)} />

      {order?.data?.items?.length > 36 &&
        Array(Math.ceil((order.data.items.length - 36) / 40))
          .fill(0)
          .map((_, index) => {
            const start = 36 + index * 40;
            const end = start + 40;
            return <PagePrint2 page={index + 2} key={index} order={order.data} items={order.data.items.slice(start, end)} totalPage={Math.ceil((order?.data?.items?.length ?? 0) / 36)} />;
          })}
    </div>
  );
};

export default PrintInvoiceAfterCreating;
