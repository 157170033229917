import { Button, Checkbox, Datepicker, Label, TextInput } from "flowbite-react";
import { useState } from "react";

const Performance = () => {
  const [check, setCheck] = useState(false)
  return (
    <div className="flex items-center justify-center mt-5">
      <div className="xl:max-w-xl w-full px-2 xl:px-0">
        <form className="p-3 pb-5">
          <div className="flex-1 flex flex-col gap-5">
            <div className="flex flex-row justify-between gap-3 items-center">
              <Label>Performance cut off mark :</Label>
              <div className="">
                <TextInput sizing="md" type="number" />
              </div>
            </div>
            <div className="flex flex-row justify-between gap-3 items-center">
              <Label>Set Payout Date :</Label>
              <div>
                <Datepicker
                  style={{
                    width: "205px",
                    outline: "none",
                    height: "40px",
                    fontSize: "13px",
                  }}
                  name="date"
                  datepicker-format={"YYYY-MM-DD"}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between gap-3 items-center">
              <Label>Enable Automatic Payout :</Label>
              <div>
                <Checkbox
                  id="remember"
                  checked={check}
                  onChange={() => setCheck((prev) => !prev)}
                  style={{
                    width: "30px",
                    outline: "none",
                    height: "30px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mt-10 flex items-center justify-end">
            <Button
              //   disabled={isLoading}
              //   isProcessing={isLoading}
              size="xs"
              className="rounded-sm"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Performance;
