import { useGetOrdersQuery } from "../../redux/queries/order";
import { useAppSelector } from "../../lib/hook";
import Breadcrumb from "../../components/Breadcrumb";
import CustomPagination from "../../components/CustomPagination";
import { useState } from "react";

const ReturnHistory = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    company: defaultBranchUUid,
    verified: 1,
    limit: "0,10",
    type: 14,
  });
  const { data: orders, isLoading } = useGetOrdersQuery(filters);

  console.log(orders);
  return (
    <div>
      <Breadcrumb title="Return History" />

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={orders?.data}
          total={orders?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Total
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </CustomPagination>
      </div>
    </div>
  );
};

export default ReturnHistory;
