import { Button, Label, Select, TextInput, useThemeMode } from "flowbite-react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { lowerCase } from "lodash";
import { useUpdateCompanyMutation } from "../../redux/queries/company";
import { useGetCountriesQuery } from "../../redux/queries/settings";
import { useGetCitiesQuery } from "../../redux/queries/location";
import { updateUserCompanyRecord } from "../../redux/slices/appUser";

interface IFormInput {
  name: string;
  email?: string;
  phone: string;
  status: string;
  countryName?: string;
  whatsapp?: string;
}

const schema = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  email: yup.string(),
  phone: yup.string().required("Phone number is a required field"),
  status: yup.string().required("Status is a required field"),
  whatsapp: yup.string(),
  countryName: yup.string(),
});

const CompanyProfile = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [updateCompany, { isLoading }] = useUpdateCompanyMutation();
  const { data: countries } = useGetCountriesQuery();
  const { mode } = useThemeMode();
  const [city, setCity] = useState(null);
  const dispatch = useAppDispatch();

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const count = 160;

  const { data: cities } = useGetCitiesQuery({
    country_id: count.toString(),
  });

  //   useEffect(() => {
  //     const initalCity = cities?.data.map

  //   }, [])

  //console.log(cities?.data, user?.company);

  const onSubmit = async (data: IFormInput) => {
    const { countryName, ...rest } = data;
    const getCountry = countries?.data?.filter(
      (country) => country?.name === data?.countryName
    );
    const payload = {
      ...rest,
      uuid: user?.company?.uuid,
      city_id: 2,
      country: getCountry ? getCountry[0]?.id : null,
    };
    const res = await updateCompany(payload);
    console.log(payload, res);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record updted");
        dispatch(updateUserCompanyRecord(res.data.data));
      } else toast.error("Unable to update");
    } else toast.error("Unable to update");
  };

  useEffect(() => {
    if (user) {
      setValue("name", user?.company?.name);
      setValue("email", user?.company?.email);
      setValue("phone", user?.company?.phone);
      setValue("status", user?.company?.status.toString());
      setValue(
        "whatsapp",
        user?.company?.whatsapp === null ? "" : user?.company?.whatsapp
      );
      setValue("countryName", user?.company?.country.name);
    }
  }, []);

  return (
    <div className="flex items-center justify-center mt-10">
      <div className="xl:max-w-xl w-full px-2 xl:px-0">
        <form className="p-3 pb-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-1">
            <div
              className="grid grid-cols-2 gap-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Label value="Name" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Name"
                  {...register("name")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Email" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Email"
                  {...register("email")}
                  color={errors?.email ? "failure" : "gray"}
                  helperText={
                    errors.email && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.email?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Phone" />
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div>
                      <PhoneInput
                        {...field}
                        country={lowerCase(user?.country?.code)}
                        disabled={isLoading}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                        }}
                        inputStyle={{
                          width: "100%",
                          paddingRight: "5px",
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                          color: mode === "light" ? "black" : "white",
                        }}
                        dropdownStyle={{
                          position: "absolute",
                          top: -20,
                          left: 0,
                        }}
                      />
                      {errors.phone && (
                        <span className="font-medium text-[10px] text-red-500">
                          {" "}
                          {errors.phone?.message}!
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>
              <div>
                <Label value="status" />
                <Select
                  sizing="sm"
                  disabled={isLoading}
                  {...register("status")}
                  color={errors?.status ? "failure" : "gray"}
                  helperText={
                    errors.status && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.status?.message}!
                      </span>
                    )
                  }
                >
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                </Select>
              </div>
              <div>
                <Label value="Whatsapp" />
                <Controller
                  name="whatsapp"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div>
                      <PhoneInput
                        {...field}
                        country={lowerCase(user?.country?.code)}
                        disabled={isLoading}
                        inputProps={{
                          name: "whatsapp",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          backgroundColor: errors?.whatsapp
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.whatsapp
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                        }}
                        inputStyle={{
                          width: "100%",
                          paddingRight: "5px",
                          backgroundColor: errors?.whatsapp
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.whatsapp
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                          color: mode === "light" ? "black" : "white",
                        }}
                        dropdownStyle={{
                          position: "absolute",
                          top: -20,
                          left: 0,
                        }}
                      />
                      {errors.whatsapp && (
                        <span className="font-medium text-[10px] text-red-500">
                          {" "}
                          {errors.whatsapp?.message}!
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>

              <div>
                <Label value="Country" />
                <Select
                  sizing="sm"
                  {...register("countryName")}
                  disabled={isLoading}
                >
                  <option value={user?.country?.name} hidden disabled>
                    {user?.country?.name}
                  </option>

                  {countries?.data?.map((country) => (
                    <option key={country?.id} value={country?.name}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="mt-10 flex items-center justify-end">
            <Button
              disabled={isLoading}
              isProcessing={isLoading}
              size="xs"
              className="rounded-sm"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyProfile;
