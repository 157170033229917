import { useState } from "react";
import { useGetKpiDailyQuery } from "../../redux/queries/tasks";
import Breadcrumb from "../../components/Breadcrumb";
import { LiaTimesSolid } from "react-icons/lia";
import moment from "moment";
import CustomPagination from "../../components/CustomPagination";

interface PayoutTasksProps {
  close: () => void;
  month: number;
  year: number;
  companyUuid: string | undefined;
  staff: string;
}

const PayoutTasks = ({
  close,
  month,
  year,
  companyUuid,
  staff,
}: PayoutTasksProps) => {
  const [filters, setFilters] = useState({
    company_uuid: companyUuid,
    staff: staff,
    month: month,
    year: year,
  });

  const { data: payoutTasks, isLoading } = useGetKpiDailyQuery(filters);

  return (
    <div className="w-[100%] pt-5 pb-3 px-5 bg-gray-200 dark:bg-gray-900">
      <Breadcrumb
        title="Payout Tasks"
        rightButton={
          <span
            className="text-gray-700 dark:text-gray-400 font-bold cursor-pointer text-md"
            onClick={close}
          >
            <LiaTimesSolid />
          </span>
        }
      />
      <div>
        <div className="shadow-md sm:rounded-lg mt-5 w-full">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            total={10}
          >
            <div>
              {payoutTasks?.data?.map((item, i) => {
                return (
                  <div key={i}>
                    {item.kpi_task.length > 0 ? (
                      <div className="">
                        <Breadcrumb title={moment(item.date).format("LLL")} />

                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Denotaition
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Performance (%)
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Performance (Q)
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Target
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Target Assigned
                              </th>
                              <th scope="col" className="px-6 py-3">
                                Value Type
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.kpi_task?.map((task, _) => (
                              <tr
                                key={_}
                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                              >
                                <td className="px-6 py-4">
                                  {task.denotaition}
                                </td>
                                <td className="px-6 py-4">
                                  {task.kpi_performance}
                                </td>
                                <td className="px-6 py-4">
                                  {task.kpi_performance_quantity}
                                </td>
                                <td className="px-6 py-4">{task.kpi_target}</td>
                                <td className="px-6 py-4">
                                  {task.kpi_target_assigned}
                                </td>
                                <td className="px-6 py-4">
                                  {task.kpi_value_type === 1
                                    ? "By Percentage"
                                    : "By Quantity"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                );
              })}
            </div>
          </CustomPagination>
        </div>
      </div>
    </div>
  );
};

export default PayoutTasks;
