import { Button, Label, TextInput } from "flowbite-react";
import { useState } from "react";
import { useUpdateCompanyMutation } from "../../redux/queries/company";
import AddressSearch from "../../components/AddressSearch";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import toast from "react-hot-toast";
import { updateUserCompanyRecord } from "../../redux/slices/appUser";

const Address = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [address, setAddress] = useState({
    name: "",
    lat: 0,
    lng: 0,
  });
  const [addressDetails, setAddressDetails] = useState(user?.company?.address2);
  const [updateCompany, { isLoading }] = useUpdateCompanyMutation();
  const dispatch = useAppDispatch();

  const updateAddress = async () => {
    const result = await updateCompany({
      uuid: user?.company?.uuid,
      address,
      address2: addressDetails,
    });
    if ("data" in result) {
      if (result.data.status === 200) {
        toast.success("Address Updated");
        dispatch(updateUserCompanyRecord(result.data.data));
      } else toast.error(result.data.message ?? "Unable to save address");
    } else toast.error("Unable to save address");
  };

  return (
    <div className="flex items-center justify-center mt-5">
      <div className="xl:max-w-xl w-full px-2 xl:px-0">
        <div className="bg-white dark:bg-gray-700 p-3 pb-5">
          <div className="">
            <div className="mb-2 block">
              <Label
                htmlFor="address"
                value={
                  user?.company?.address.name
                    ? `Current Address: ${user?.company.address.name}`
                    : "Address"
                }
              />
            </div>

            <AddressSearch sizing="md" onSelect={(val) => setAddress(val)} />
          </div>
          <div className=" mt-3">
            <div className="mb-2 block">
              <Label htmlFor="address" value={"Address Details"} />
            </div>

            <TextInput
              id="address-details"
              value={addressDetails}
              type="text"
              className="flex-1"
              sizing="md"
              onChange={(e) => setAddressDetails(e.target.value)}
            />
          </div>
          <div className="mt-7 flex items-center justify-end">
            <Button
              disabled={isLoading}
              isProcessing={isLoading}
              size="xs"
              className="rounded-sm"
              onClick={updateAddress}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
