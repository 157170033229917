import { api } from "../baseConfig";

interface PayloadFilter {
  limit: string;
  name?: string;
  status?: number;
  company?: string;
  category?: string;
  user?: string;
}

export const productStatisticsSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getProductsStatistics: builder.query<
      ProductStatisticsResponse,
      PayloadFilter
    >({
      query: (filters) => ({
        url: `/items/statistics`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Statistics"],
    }),
  }),
});

export const { useGetProductsStatisticsQuery } = productStatisticsSlice;
