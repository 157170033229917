import { useState } from "react";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import { Button, Label } from "flowbite-react";
import CustomSelect from "../../components/CustomSelect";
import { useGetRidersQuery } from "../../redux/queries/users";
import { useAppSelector } from "../../lib/hook";
import { useAssignRiderMutation } from "../../redux/queries/order";
import toast from "react-hot-toast";

interface AssignRiderType {
  isOpen: boolean;
  close: () => void;
  orderUuid?: string;
}

const AssignRider = (props: AssignRiderType) => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const { isOpen, close, orderUuid } = props;
  const [riderUuid, setRiderUuid] = useState("");
  const [assignRider, { isLoading: assignRiderLoading }] =
    useAssignRiderMutation();

  const { data: riders } = useGetRidersQuery({
    company: defaultBranchUUid,
    limit: "0,5",
    status: 1,
  });

  const assignRide = async () => {
    if (!riderUuid) {
      toast.error("Please select a rider");
      return;
    }
    const res = await assignRider({
      order: orderUuid ?? "",
      rider: riderUuid,
    });
    if ("data" in res) {
      toast.success("Rider assigned to order");
      close()
    } else toast.error("Unable to assign rider");
  };

  console.log(riders);
  return (
    <Modal
      showCloseIcon={false}
      blockScroll={false}
      classNames={{
        modalContainer: "__remove_modal_bg",
      }}
      center
      open={isOpen}
      onClose={close}
    >
      <div className="w-[500px] rounded-t-md">
        <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
          <span className="text-white text-sm font-samibold">Assign Rider</span>
          <span className="text-white cursor-pointer" onClick={close}>
            <LiaTimesSolid />
          </span>
        </div>
        <div className="p-5 bg-white pb-40 dark:bg-gray-900">
          <div className="mb-4 grid grid-cols-1 gap-4">
            <div>
              <Label value="Assign rider to order" />
              <CustomSelect
                className="top-0"
                onSelect={(item) => setRiderUuid(item.value)}
                options={
                  riders?.data?.map((item) => ({
                    label: item.rider.name,
                    value: item.rider.uuid,
                  })) ?? []
                }
              />
            </div>
            <div className="flex flex-col justify-end items-end">
              <Button
                disabled={assignRiderLoading || !riderUuid}
                isProcessing={assignRiderLoading}
                onClick={assignRide}
              >
                Assign
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AssignRider;
