import call from "../../assets/call.webp";
import { IoCall } from "react-icons/io5";
import { FaAddressCard } from "react-icons/fa";
import { Button, Label, Radio, TextInput, Textarea } from "flowbite-react";
import { IoIosSend } from "react-icons/io";
import { useAppSelector } from "../../lib/hook";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useSendMessageMutation } from "../../redux/queries/support";
import toast from "react-hot-toast";

interface IFormInput {
  message: string;
  category: string;
}

const schema = yup.object().shape({
  message: yup.string().required("Message is a required field"),
  category: yup.string().required("Category is a required field"),
});

const ContactUs = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [sendMessage, { isLoading }] = useSendMessageMutation();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: IFormInput) => {
    const payload = {
      name: user?.name,
      email: user?.email,
      phone: user?.phone,
      ...data,
    };
    const res = await sendMessage(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success(res.data.message);
        reset();
      } else toast.error("Unable Forward Message, Try Again Later");
    } else toast.error("Unable Forward Message, Try Again Later");
  };

  return (
    <div className="mt-4 flex flex-col gap-6">
      <h3 className="text-2xl font-semibold dark:text-white text-center">
        Ask Support
      </h3>

      <div className="flex-1">
        <div className="bg-white dark:bg-gray-700 shadow-xl rounded-lg flex">
          <div className="w-[40%] 2xl:w-[35%] bg-blue-100 flex flex-col justify-between items-center py-20 rounded-tl-lg rounded-bl-lg">
            <img src={call} alt="customer-care" className="mt-20" />

            <div className="mt-20 flex flex-col gap-3 px-10">
              <div className="flex items-center gap-5">
                <IoCall className="text-red-400" />
                <p className="text-black font-medium">07065137000</p>
              </div>
              <div className="flex items-center gap-5">
                <FaAddressCard className="text-red-400" />
                <p className="text-black font-medium">
                  28 Ogunlowo Street, Off Obafemi Awolowo Way, Ikeja
                </p>
              </div>
            </div>
          </div>

          <div className="py-10 flex-1 flex justify-center">
            <form
              className="2xl:w-[70%] px-10 2xl:px-0"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h4 className="text-3xl font-semibold text-blue-300">
                Get in Touch
              </h4>

              <p className="mt-3 text-xl font-medium dark:text-white">
                Send us a message and our team will get back to you in 24 to 48
                hours !
              </p>

              <div className="mt-10 flex-1">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col gap-2">
                    <Label value="Name" className="text-lg" />
                    <TextInput
                      sizing="md"
                      type="text"
                      value={user?.name}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <Label value="Phone" className="text-lg" />
                    <TextInput
                      sizing="md"
                      type="text"
                      value={user?.phone}
                      readOnly
                    />
                  </div>
                </div>
                <div className="mt-4 flex flex-col gap-2">
                  <Label value="Email" className="text-lg" />
                  <TextInput
                    sizing="md"
                    type="text"
                    value={user?.email}
                    readOnly
                  />
                </div>
                <div className="mt-4 flex flex-col gap-2">
                  <Label value="Message" className="text-lg" />
                  <Textarea
                    id="message"
                    placeholder="Write a message..."
                    rows={5}
                    disabled={isLoading}
                    {...register("message")}
                    color={errors?.message ? "failure" : "gray"}
                    helperText={
                      errors.message && (
                        <span className="font-medium text-[10px]">
                          {" "}
                          {errors.message?.message}!
                        </span>
                      )
                    }
                  />
                </div>
                <div className="mt-4 flex flex-col gap-2">
                  <Label
                    value="What category does your message fall into ?"
                    className="text-lg"
                  />
                  <fieldset
                    className={`flex justify-around items-center gap-4 border border-gray-300 dark:border-gray-600 p-3 rounded-md ${
                      errors?.category && "border-red-500 border-2 bg-red-50"
                    }`}
                    disabled={isLoading}
                  >
                    <div className="flex items-center gap-2">
                      <Radio
                        id="product"
                        value="Product"
                        {...register("category")}
                      />
                      <Label htmlFor="product">Product</Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <Radio
                        id="finance"
                        value="Finance"
                        {...register("category")}
                      />
                      <Label htmlFor="finance">Finance</Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <Radio
                        id="delivery"
                        value="Delivery"
                        {...register("category")}
                      />
                      <Label htmlFor="delivery">Delivery</Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <Radio
                        id="partnership"
                        value="Partnership"
                        {...register("category")}
                      />
                      <Label htmlFor="partnership">Partnership</Label>
                    </div>
                  </fieldset>
                  {errors.category && (
                    <span className="font-medium text-[10px] text-red-500">
                      {" "}
                      {errors.category?.message}!
                    </span>
                  )}
                </div>
              </div>

              <div className="mt-10 flex justify-end items-end">
                <Button
                  size="md"
                  type="submit"
                  disabled={isLoading}
                  isProcessing={isLoading}
                >
                  <div className="flex items-center">
                    Send Message <IoIosSend className="mr-2 h-5 w-5" />
                  </div>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
