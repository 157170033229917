import { Pagination, Spinner } from "flowbite-react";
import emptyIcon from "../assets/empty.png";
import { useEffect, useState } from "react";
interface IPaginationControl {
  children: JSX.Element;
  isLoading: boolean;
  total?: number;
  data?: any[];
  setPage: (limit: string) => void;
  layout?: "pagination" | "table" | "navigation" | undefined;
  count?: number;
}
const PaginationControl = (props: IPaginationControl) => {
  const {
    children,
    isLoading,
    total,
    data,
    setPage,
    layout = "pagination",
    count = 10,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (page: number) => {
    setCurrentPage(page);

    setPage(`${(page - 1) * count},${count}`);
  };

  useEffect(() => {
    if (!total) return;
    if (total < 11) setCurrentPage(1);
  }, [total]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center my-5">
        <Spinner aria-label="Default status example" />
      </div>
    );
  }

  if (total === 0) {
    return (
      <div className="flex items-center justify-center my-5">
        <div className="flex flex-col items-center">
          <img src={emptyIcon} alt="empty record" className="w-20" />
          <span className="dark:text-gray-400 mt-2 text-xs">
            No record found
          </span>
        </div>
      </div>
    );
  }

  return (
    <div>
      {children}
      <div className="flex mt-3 mb-5 mr-2 px-2 justify-end">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil((total as number) / 10)}
          onPageChange={onPageChange}
          layout={layout}
        />
      </div>
    </div>
  );
};

export default PaginationControl;
