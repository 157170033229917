import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import utills from "../../lib/functions";
import { useAppSelector } from "../../lib/hook";
import { useGetKpiPayoutQuery } from "../../redux/queries/tasks";
import CustomPagination from "../../components/CustomPagination";

const Payout = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company_uuid: user?.company?.uuid,
  });

  const { data: payoutData, isLoading } = useGetKpiPayoutQuery(filters);

  return (
    <div>
      <Breadcrumb
        rightButton={
          <h5 className="text-2xl text-gray-700 uppercase dark:text-gray-400">
            {utills._currency_format(Number(200000), "NGN")}
          </h5>
        }
      />
      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={payoutData?.data}
            total={payoutData?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    EMPLOYEES
                  </th>
                  <th scope="col" className="px-6 py-3">
                    EXPECTED SALARY
                  </th>
                  <th scope="col" className="px-6 py-3">
                    PERFORMANCE
                  </th>
                  <th scope="col" className="px-6 py-3">
                    PAYOUT
                  </th>
                  <th scope="col" className="px-6 py-3">
                    STATUS
                  </th>
                  <th scope="col" className="px-6 py-3">
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody>
                {payoutData?.data?.map((item) => {
                  return (
                    <tr
                      key={item.id}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="px-6 py-4">{item.staff.name}</td>
                      <td className="px-6 py-4">{item.salary}</td>
                      <td className="px-6 py-4">{item.performance}</td>
                      <td className="px-6 py-4">{item.payout}</td>
                      <td className="px-6 py-4">
                        {item.status === 1 ? "Active" : "Inactive"}
                      </td>
                      <td className="px-6 py-4"></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>
    </div>
  );
};

export default Payout;
