import React, { useState } from "react";
import Detailscrumb from "../../components/DetailsCrumb";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteProductMutation,
  useGetProductQuery,
} from "../../redux/queries/products";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import { Badge } from "flowbite-react";
import { FaEdit, FaHistory } from "react-icons/fa";
import { GoTrash } from "react-icons/go";
import toast from "react-hot-toast";
import Modal from "react-responsive-modal";
import ModelEditHistory from "./ModelEditHistory";
import { MdOutlineMotionPhotosPause } from "react-icons/md";
import { PiWarningDiamondFill } from "react-icons/pi";
import MoveProduct from "./MoveProduct";

const ManageProduct = () => {
  const [page, setPage] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const [deleteProduct] = useDeleteProductMutation();
  const { data: product } = useGetProductQuery(id ?? "0", { skip: !id });

  const handleDelete = async (id: string) => {
    const action = window.confirm("Are you sure you want to delete");
    if (action) {
      const res = await deleteProduct(id);
      if ("data" in res) {
        toast.success("Item Deleted");
        navigate("/products");
      } else toast.error("Unable to delete product");
    }
  };

  return (
    <div>
      <Detailscrumb
        title={product?.data.name ?? "--"}
        leftButton={
          <button
            onClick={() => navigate(-1)}
            className="mr-3 inline-flex items-center justify-center rounded-md border border-gray-200 bg-white px-3 py-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-cyan-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
          >
            <IoMdArrowRoundBack className="mr-2 h-4 w-4" />
            Back
          </button>
        }
      />

      <div className="flex mt-5">
        <aside className="bg-white w-72 dark:bg-gray-800 rounded-md flex flex-col justify-center rounded-t-lg">
          <div className="bg-gray-200 dark:bg-gray-700 p-2 py-3 rounded-t-lg flex justify-center items-center">
            <span className="text-[15px] font-semibold dark:text-gray-300">
              {product?.data.name}
            </span>
          </div>
          <div className="p-3">
            <div className="flex justify-center items-center">
              <ImageView
                className="h-60 w-60"
                url={product?.data.images_links[0]}
              />
            </div>

            <div className="flow-root">
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                <li className="py-3 sm:py-4">
                  <div className="flex items-center space-x-4">
                    <div className="min-w-0 flex-1">
                      <p className="truncate font-medium text-gray-900 dark:text-white">
                        Cost Price
                      </p>
                    </div>
                    <div className="inline-flex text-[11px] items-center text-base text-gray-900 dark:text-white">
                      {utills._currency_format(
                        Number(product?.data.price) ?? 0,
                        "NGN"
                      )}
                    </div>
                  </div>
                </li>
                <li className="py-3 sm:py-4">
                  <div className="flex items-center space-x-4">
                    <div className="min-w-0 flex-1">
                      <p className="truncate font-medium text-gray-900 dark:text-white">
                        Selling Price
                      </p>
                    </div>
                    {utills._currency_format(
                      product?.data.selling_price ?? 0,
                      "NGN"
                    )}
                  </div>
                </li>
                <li className="py-3 sm:py-4">
                  <div className="flex items-center space-x-4">
                    <div className="min-w-0 flex-1">
                      <p className="truncate font-medium text-gray-900 dark:text-white">
                        Status
                      </p>
                    </div>
                    <Badge
                      color={product?.data.status === 1 ? "success" : "failure"}
                    >
                      {product?.data.status === 1 ? "Active" : "InActive"}
                    </Badge>
                  </div>
                </li>
                <li className="py-3 sm:py-4">
                  <div className="flex items-center space-x-4">
                    <div className="min-w-0 flex-1">
                      <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                        Sold
                      </p>
                    </div>
                    <div className="inline-flex text-[11px] items-center text-base font-semibold text-gray-900 dark:text-white">
                      {product?.data.sold}
                    </div>
                  </div>
                </li>

                <li className="py-3 sm:py-4">
                  <div className="flex items-center space-x-4">
                    <div className="min-w-0 flex-1">
                      <p className="truncate text-sm font-medium text-gray-900 dark:text-white">
                        Stock
                      </p>
                    </div>
                    <div className="inline-flex text-[11px] items-center text-base font-semibold text-gray-900 dark:text-white">
                      {product?.data.stock}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </aside>
        <main className="flex-1  px-4">
          <div className="flex gap-4">
            <div
              onClick={() => navigate(`/products/edit/${product?.data.uuid}`)}
              className="cursor-pointer bg-white dark:bg-gray-800 hover:scale-[1.1] transition-all w-40 h-40 flex justify-center items-center rounded-lg"
            >
              <FaEdit className="text-5xl text-[#167490]" />
            </div>
            <div
              onClick={() => setPage("history")}
              className="cursor-pointer bg-white dark:bg-gray-800 hover:scale-[1.1] transition-all w-40 h-40 flex justify-center items-center rounded-lg"
            >
              <FaHistory className="text-5xl text-[#167490]" />
            </div>

            <div
              onClick={() => handleDelete(product?.data.uuid ?? "")}
              className="cursor-pointer bg-white dark:bg-gray-800 hover:scale-[1.1] transition-all w-40 h-40 flex justify-center items-center rounded-lg"
            >
              <GoTrash className="text-5xl text-red-500" />
            </div>
            <div
              onClick={() => setPage("scrap")}
              className="cursor-pointer bg-white dark:bg-gray-800 hover:scale-[1.1] transition-all w-40 h-40 flex justify-center items-center rounded-lg"
            >
              <MdOutlineMotionPhotosPause className="text-5xl text-[#167490]" />
            </div>

            <div
              onClick={() => setPage("expired")}
              className="cursor-pointer bg-white dark:bg-gray-800 hover:scale-[1.1] transition-all w-40 h-40 flex justify-center items-center rounded-lg"
            >
              <PiWarningDiamondFill className="text-5xl text-red-500" />
            </div>
          </div>
        </main>
      </div>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer:
            page === "history" ? "__history_modal_bg" : "__remove_modal_bg",
        }}
        center
        open={page.length ? true : false}
        onClose={() => setPage("")}
      >
        {page === "history" && (
          <ModelEditHistory
            close={() => setPage("")}
            item_uuid={product?.data?.uuid ?? ""}
          />
        )}

        {page === "scrap" && (
          <MoveProduct close={() => setPage("")} item_uuid={product?.data?.uuid ?? ""} type={3} />
        )}

        {page === "expired" && (
          <MoveProduct close={() => setPage("")} item_uuid={product?.data?.uuid ?? ""} type={5} />
        )}
      </Modal>
    </div>
  );
};

export default ManageProduct;
