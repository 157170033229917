import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { Banner, Button, Label, TextInput } from "flowbite-react";
import { LuHistory } from "react-icons/lu";
import utills from "../../lib/functions";
import { useApprovePaymentMutation, useGetPaymentQuery } from "../../redux/queries/Payments";
import { useGetSettingsQuery } from "../../redux/queries/settings";
import moment from "moment";
import Modal from "react-responsive-modal";
import { useState } from "react";
import { LiaTimesSolid } from "react-icons/lia";
import toast from "react-hot-toast";
import { useGetOrderQuery } from "../../redux/queries/order";
import { useAppSelector } from "../../lib/hook";

const ManageOnlinePurchases = () => {
  const { uuid } = useParams();
  const { data: order } = useGetOrderQuery(uuid as string, { skip: !uuid });
  const { data: settings } = useGetSettingsQuery();

  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);

  const [filters, setFilters] = useState({
    limit: "0,5",
    order_company_uuid: uuid,
    user: defaultBranchUUid,
  });
  const { data: paymentHistories, isLoading: ispaymentLoading } = useGetPaymentQuery(filters);

  const order_status = settings?.data.order_status ?? [];

  return (
    <div>
      <Breadcrumb title={"Online Purchase" + " " + order?.data.id} />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">Online Purchase #{order?.data.id}.</h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">Online Purchase records.</p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <LuHistory className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="bg-white p-4 dark:!text-white rounded-lg dark:bg-gray-700 shadow-sm flex justify-between">
        <div>
          <div>
            <strong>Date: </strong>
            {moment(order?.data.created_at).format("LLL")}
          </div>
          <div>
            <strong>Delivery Confirmation Code: </strong>
            {order?.data.pin}
          </div>

          <div>Buyer</div>
          <div>
            <strong>Name: </strong>
            {order?.data.buyer_details?.name}
          </div>
          <div>
            <strong>Email: </strong>
            {order?.data.buyer_details?.email}
          </div>
          <div>
            <strong>Address: </strong>
            {order?.data.buyer_details?.address?.name ?? order?.data.buyer_details?.address2?.join(", ")}
          </div>
         
        </div>
        <div className="text-end">
          <div>
            <strong>Amount: </strong>
            {utills._currency_format(Number(order?.data.total_price), "NGN")}
          </div>
          <div>
            <strong>Amount Paid: </strong>
            {utills._currency_format(Number(order?.data.amount_paid), "NGN")}
          </div>
        </div>
      </div>


      <div className="mt-3 bg-white p-4 rounded-lg dark:bg-gray-700 dark:!text-white shadow-sm">
        <div className="font-semibold">Payments</div>
        <div className="flex items-center gap-3 flex-wrap">
          {paymentHistories?.data.map((payment) => (
            <OnlinePurchasePayment key={crypto.randomUUID()} {...payment} order_status={order_status} />
          ))}
        </div>
      </div>

      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-[#15738e]">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Unit
                </th>
                <th scope="col" className="px-6 py-3">
                  Product
                </th>
                <th scope="col" className="px-6 py-3">
                  Qty
                </th>
                <th scope="col" className="px-6 py-3">
                  Discount
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {order?.data.items.map((item) => (
                <tr
                  key={crypto.randomUUID()}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4">{item.unit}</td>
                  <td className="px-6 py-4">{item.name}</td>
                  <td className="px-6 py-4">{item.quantity}</td>
                  <td className="px-6 py-4">{item.discount}</td>
                  <td className="px-6 py-4">{utills._currency_format(Number(item.price), "NGN")}</td>
                </tr>
              ))}
            </tbody>
          </table>

      {/* {order?.data.companyorders.map((comp) => (
        <div key={crypto.randomUUID()} className="mt-5">
          <div className="bg-white p-4 dark:!text-white rounded-lg dark:bg-gray-700 shadow-sm flex justify-between mb-4">
            <div>
              <div>
                <strong>Company: </strong> {comp.company.name}
              </div>
              <div>
                <strong>Delivery Fee: </strong> {utills._currency_format(comp.delivery_fee, "NGN")}
              </div>
              <div>
                <strong>Service Fee: </strong> {utills._currency_format(comp.service_fee, "NGN")}
              </div>
              <div>
                <strong>Total Amount: </strong> {utills._currency_format(comp.total_price, "NGN")}
              </div>
              <div>
                <strong>Status: </strong>{" "}
                <span className="bg-green-200 border border-green-500 text-green-500 text-xs font-semibold px-3 py-0.5 rounded-sm">
                  {utills._get_status_value(comp.status, order_status)}
                </span>
              </div>
            </div>
            <div></div>
          </div>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-[#15738e]">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Unit
                </th>
                <th scope="col" className="px-6 py-3">
                  Product
                </th>
                <th scope="col" className="px-6 py-3">
                  Qty
                </th>
                <th scope="col" className="px-6 py-3">
                  Discount
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {comp.items.map((item) => (
                <tr
                  key={crypto.randomUUID()}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4">{item.unit}</td>
                  <td className="px-6 py-4">{item.name}</td>
                  <td className="px-6 py-4">{item.quantity}</td>
                  <td className="px-6 py-4">{utills._currency_format(item.discount, "NGN")}</td>
                  <td className="px-6 py-4">{utills._currency_format(Number(item.price), "NGN")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))} */}
    </div>
  );
};

export default ManageOnlinePurchases;

interface IOnlinePurchasePayment extends Payment {
  order_status: OrderStatus[];
}

const OnlinePurchasePayment = (props: IOnlinePurchasePayment) => {
  const [ approvePayment, { isLoading }] = useApprovePaymentMutation()
  const { other_party, crdate, amount, balance, transaction_balance, description, gateway, status, order_status, id } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [pin, setPin] = useState("");

  const submitPin = async () => {
    if(!pin) return toast.error("Please enter wallet pin")
    const res = await approvePayment({ status: 1, pin, uuid: props.uuid });
    if('data' in res){
      if(res.data.data) return toast.success(res.data.message)
        else return toast.error(res.data.message)
    }
  }
  return (
    <div className="mt-3 border w-max rounded-md p-5">
      <div>
        <div>
          <strong>Company: </strong> {other_party?.name}
        </div>
        <div>
          <strong>Date: </strong> {moment(crdate).format("LLL")}
        </div>
        <div>
          <strong>Payment Method: </strong> {gateway}
        </div>
        <div>
          <strong>Amount: </strong> {utills._currency_format(Number(amount), "NGN")}
        </div>
        <div>
          <strong>Balance: </strong> {utills._currency_format(Number(balance), "NGN")}
        </div>
        <div>
          <strong>Transaction Bal: </strong> {utills._currency_format(Number(transaction_balance), "NGN")}
        </div>
        <div>
          <strong>Status: </strong>{" "}
          <span className="bg-green-200 border border-green-500 text-green-500 text-xs font-semibold px-3 py-0.5 rounded-sm">
            {utills._get_status_value(status, order_status)}
          </span>
        </div>
        <div>
          <strong>Description: </strong> {description}
        </div>
      </div>
      <Button outline className="w-full mt-3" onClick={() => setIsOpen(true)}>
        Confirm Payment
      </Button>

      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        showCloseIcon={false}
      >
        <div className="w-[400px]">
          <div className="bg-[#167490] flex items-center justify-between h-10 px-3">
            <span className="text-white font-semibold text-sm">Enter Wallet Pin To Confirm Transaction</span>
            <span className="text-white cursor-pointer" onClick={() => setIsOpen(false)}>
              <LiaTimesSolid />
            </span>
          </div>
          <div className="bg-white p-5">
            <Label htmlFor="pin" value="Enter Pin" />
            <TextInput value={pin} onChange={(e) => setPin(e.target.value)} type="password" id="pin" className="mt-2" />
            <Button type="button" isProcessing={isLoading} onClick={submitPin} disabled={isLoading} className="mt-4 w-full">Confirm</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
