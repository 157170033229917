import React, { useState } from "react";
import ImageViewer from "react-simple-image-viewer";

interface ImageViewerType {
  className?: string;
  url?: string;
}
const ImageView = (props: ImageViewerType) => {
  const { className, url } = props;
  const [openModal, setOpenModal] = useState(false);

  const closeImageViewer = () => setOpenModal(false);
  return (
    <React.Fragment>
      <div
        onClick={() => setOpenModal(true)}
        className={`rounded-md bg-white  border border-gray-300 dark:border-gray-600 ${className}`}
        style={{
          backgroundImage: `url(${url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      {openModal && (
        <ImageViewer
          src={[url ?? ""]}
          backgroundStyle={{ zIndex: 20 }}
          currentIndex={0}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </React.Fragment>
  );
};

export default ImageView;
