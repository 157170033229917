import { TextInput } from "flowbite-react";
import Breadcrumb from "../../components/Breadcrumb";
import { MdSearch } from "react-icons/md";
import CustomPagination from "../../components/CustomPagination";
import { useState } from "react";
import { useGetAttendanceQuery } from "../../redux/queries/attendance";
import CustomSelect from "../../components/CustomSelect";

const Attendance = () => {
  const [filters, setFilters] = useState({
    limit: "0,10",
    staff: "",
  });

  const { data: attendance, isLoading } = useGetAttendanceQuery(filters);
  console.log(attendance);
  return (
    <div>
      <Breadcrumb title="Attendance" />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="grid grid-cols-4 gap-3">
          <div className="w-[200px]">
            <CustomSelect
              isClearable={false}
              disabled={isLoading}
              loading={isLoading}
              placeholder="Select Staff..."
              inputClasses="h-9"
              options={[]}
              onSelect={(val) => {
                setFilters((prev) => ({
                  ...prev,
                  other_party: val.value,
                }));
                // setResetValue(false);
              }}
              onSearch={(text) => {
                // setCompaniesFilters((prev) => ({ ...prev, name: text }));
                // setResetValue(false);
              }}
              //reset={resetValue}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={attendance?.data}
            total={attendance?.count}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Staff
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Started Work
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Taking a break
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Off from Break
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Closed for the day
                  </th>
                </tr>
              </thead>
              <tbody>
                {attendance?.data?.map((item) => (
                  <tr
                    key={item.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">{item.staff.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
