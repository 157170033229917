import { Badge, Button, TextInput } from "flowbite-react";
import Breadcrumb from "../../components/Breadcrumb";
import { IoIosAdd } from "react-icons/io";
import { useState } from "react";
import { MdSearch } from "react-icons/md";
import CustomPagination from "../../components/CustomPagination";
import { useNavigate } from "react-router-dom";
import ImageView from "../../components/ImageView";
import moment from "moment";
import Modal from "react-responsive-modal";
import { useGetUsersQuery } from "../../redux/queries/users";

const PatientsList = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    limit: "0,10",
    type: "user",
    status: 1,
    name: "",
  });
  const { data: users, isLoading } = useGetUsersQuery(filters);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <Breadcrumb
        title="Patient List"
        rightButton={
          <Button size="xs" onClick={() => setIsOpen(true)}>
            <IoIosAdd className="mr-2 h-5 w-5" /> New Patient
          </Button>
        }
      />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="grid grid-cols-4 gap-3">
          <div>
            <TextInput
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, name: e.target.value }))
              }
              type="text"
              icon={MdSearch}
              placeholder="search..."
            />
          </div>
        </div>
      </div>

      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={users?.data}
            total={users?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label htmlFor="checkbox-all-search" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3">
                    #
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Customer Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Customer Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  {/* <th scope="col" className="px-6 py-3">
                Action
              </th> */}
                </tr>
              </thead>
              <tbody>
                {users?.data?.map((item) => (
                  <tr
                    onClick={() => navigate(`/patients/${item.uuid}`)}
                    key={item.id}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td
                      className="w-4 p-4"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="checkbox-table-search-1"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td>
                    <td onClick={(e) => e.stopPropagation()}>
                      {/* <img
                    className="w-10"
                    src={item.avatar_link}
                    alt={item.name}
                  /> */}
                      <ImageView url={item.avatar_link} className="w-10 h-10" />
                    </td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {item.name}
                    </th>
                    <td className="px-6 py-4">{item.email}</td>
                    <td className="px-6 py-4">
                      <Badge
                        className="w-max"
                        color={item.status === 1 ? "success" : "failure"}
                      >
                        {item.status === 1 ? "active" : "inactive"}
                      </Badge>
                    </td>
                    <td className="px-6 py-4">
                      {moment(item.crdate).format("LLL")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>
      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {/* <CustomerForm close={() => setIsOpen(false)} /> */}
      </Modal>
    </div>
  );
};

export default PatientsList;
