import { Button } from "flowbite-react";
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

const Settings = (props: UI) => {
  const { close } = props;
  return (
    <div className="h-full flex flex-col bg-white dark:bg-gray-800 min-w-[450px]">
      <div className="flex justify-between items-center px-3 py-3 border-b border-gray-300">
        <h4 className="font-semibold dark:text-white">POS Settings</h4>
        <span onClick={close} className="cursor-pointer">
          <AiOutlineCloseCircle className="dark:text-white" />
        </span>
      </div>
      <div className="px-3 mt-3 flex flex-col justify-between flex-1">
        <div>

        </div>


      <Button className="rounded-xs mb-4">Save Address</Button>
      </div>
    </div>
  );
};

export default Settings;
