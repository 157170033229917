import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useAppSelector } from "../../lib/hook";
import { useGetProductsStatisticsQuery } from "../../redux/queries/productStatistics";
import { Datepicker, Label, Select, TextInput } from "flowbite-react";
import { MdSearch } from "react-icons/md";
import moment from "moment";
import { useGetCountriesQuery } from "../../redux/queries/settings";
import utills from "../../lib/functions";
import BranchSwitcher from "../../components/BranchSwitcher";
import CustomPagination from "../../components/CustomPagination";

const ProductsStatistics = () => {
  const { user, defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: user?.company?.uuid,
    staff: "",
    date: "",
    country: "",
    region: "",
  });
  const { data: statistics, isLoading } =
    useGetProductsStatisticsQuery(filters);

  const { data: countries } = useGetCountriesQuery();

  return (
    <div>
      <Breadcrumb title="Product Statistics" />
      <div className="mt-5 mb-10 flex flex-wrap items-center gap-5 2xl:gap-10">
        <div className="flex justify-between h-32 flex-1">
          <div className="bg-green-100 flex-1 flex gap-2 justify-center flex-col items-center rounded-tl-xl rounded-bl-xl shadow-md">
            <span className="text-sm font-semibold text-green-900">
              Total Purchases
            </span>
            <span className="text-gray-800 font-semibold text-[25px]">
              {utills._currency_format(Number(statistics?.total_purchase_price) ?? 0, "NGN")}
            </span>
          </div>
          <div className="bg-green-500 py-4 px-4 2xl:px-8 flex gap-2 justify-center flex-col items-center rounded-tr-xl rounded-br-xl shadow-md">
            <span className="text-sm font-semibold text-gray-800">
              Total Items
            </span>
            <span className="text-white font-semibold text-[25px]">{statistics?.total_items}</span>
          </div>
        </div>

        <div className="flex justify-between h-32 flex-1">
          <div className="bg-orange-100 flex-1 flex gap-2 justify-center flex-col items-center rounded-tl-xl rounded-bl-xl shadow-md">
            <span className="text-sm font-semibold text-orange-900">
              Pharmacy Sales
            </span>
            <span className="text-gray-800 font-semibold text-[25px]">
              {utills._currency_format(Number(statistics?.total_items) ?? 0, "NGN")}
            </span>
          </div>
          <div className="bg-orange-500 py-4 px-4 2xl:px-8 flex gap-2 justify-center flex-col items-center rounded-tr-xl rounded-br-xl shadow-md">
            <span className="text-sm font-semibold text-gray-800">
              Total Pharmacy
            </span>
            <span className="text-white font-semibold text-[25px]">{statistics?.total_pharmacy}</span>
          </div>
        </div>

        <div className="flex justify-between h-32 flex-1">
          <div className="bg-blue-100 flex-1 flex gap-2 justify-center flex-col items-center rounded-tl-xl rounded-bl-xl shadow-md">
            <span className="text-sm font-semibold text-blue-900">
              Total Sales Price
            </span>
            <span className="text-gray-800 font-semibold text-[25px]">
              {utills._currency_format(Number(statistics?.total_sales_price) ?? 0, "NGN")}
            </span>
          </div>
          <div className="bg-blue-500 py-4 px-4 2xl:px-8 flex gap-2 justify-center flex-col items-center rounded-tr-xl rounded-br-xl shadow-md">
            <span className="text-sm font-semibold text-gray-800">
              Total Sales
            </span>
            <span className="text-white font-semibold text-[25px]">{statistics?.total_sales}</span>
          </div>
        </div>
      </div>

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm flex justify-between items-end gap-3">
        <div>
          <Label value="Staff Name" className="text-sm" />
          <TextInput
            sizing="md"
            type="text"
            value={filters.staff}
            icon={MdSearch}
            placeholder="search..."
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, staff: e.target.value }))
            }
          />
        </div>
        <div>
          <Label value="Date" className="text-sm" />
          <Datepicker
            value={filters.date}
            onSelectedDateChanged={(date) =>
              setFilters((prev) => ({
                ...prev,
                date: moment(date).format("YYYY-MM-DD"),
              }))
            }
            name="date"
            datepicker-format={"YYYY-MM-DD"}
          />
        </div>
        <div className="">
          <Label value="Country" className="text-sm" />
          <Select
            disabled={isLoading}
            sizing="md"
            value={filters.country}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, country: e.target.value }))
            }
          >
            <option value="" hidden>
              Select a Country
            </option>
            {countries?.data?.map((country) => (
              <option value={country?.id}>{country.name}</option>
            ))}
          </Select>
        </div>
        <div className="">
          <Label value="Region" className="text-sm" />
          <Select
            disabled={isLoading}
            sizing="md"
            value={filters.region}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, region: e.target.value }))
            }
          >
            <option value="" hidden>
              Select a Region
            </option>
            {countries?.data?.map((country) => (
              <option value={country?.id}>{country.name}</option>
            ))}
          </Select>
        </div>
        <div>
            <BranchSwitcher
              sizing="md"
              defaultCompany={filters.company}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, company: value }))
              }
            />
          </div>
      </div>

      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={isLoading}
            data={statistics?.data}
            total={statistics?.count ?? 0}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Product
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Purchases
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Sales
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Pharmacy Sales
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Purchase Price
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Sales Price
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Profit/Loss
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Expected Sales
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Sales rate
                  </th>
                </tr>
              </thead>
              <tbody>
                {statistics?.data?.map((item) => {
                  return (
                    <tr
                      key={item.id}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="px-6 py-4">{item.name}</td>
                      <td className="px-6 py-4">{item.purchase}</td>
                      <td className="px-6 py-4">{item.sales}</td>
                      <td className="px-6 py-4">{item.pharmacy_sales}</td>
                      <td className="px-6 py-4">{item.purchase_price}</td>
                      <td className="px-6 py-4">{item.sales_price}</td>
                      <td className="px-6 py-4">{item.profit_loss}</td>
                      <td className="px-6 py-4">{item.expected_sales}</td>
                      <td className="px-6 py-4">{item.sales_rate}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CustomPagination>
        </div>
      </div>
    </div>
  );
};

export default ProductsStatistics;
