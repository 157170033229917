import { api } from "../baseConfig";

interface PayloadFilter {
  limit?: string;
  name?: string;
  status?: number;
  company?: string;
  category?: string;
  user?: string;
  company_uuid?: string;
  year?: number;
  month?: number;
  staff?: string;
  user_access_uuid?: string;
}

interface KpiDailyResponse {
  data: KpiDaily[];
  message: string;
  status: number;
  total: number;
}

interface TaskUserResponse {
  data: TaskUser[];
  status: number
}

export const tasksSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getKpis: builder.query<TaskListResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/kpi`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Tasks"],
    }),
    getKpisUser: builder.query<TaskUserResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/kpi/taskpermissons`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Tasks"],
    }),
    getKpiPayout: builder.query<TaskListResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/kpi/result`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Tasks"],
    }),
    getKpiDaily: builder.query<KpiDailyResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/kpi/daily`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Tasks"],
    }),
    getTaskNames: builder.query<TaskNamesResponse, void>({
      query: () => ({
        url: `/kpi/tasks`,
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),
    createTask: builder.mutation<any, any>({
      query: (data) => ({
        url: "/kpi/store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Tasks"],
    }),
    createTaskV2: builder.mutation<any, any>({
      query: (data) => ({
        url: "/kpi/taskpermissons",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Tasks"],
    }),
  }),
});

export const {
  useGetKpisQuery,
  useGetKpisUserQuery,
  useGetKpiPayoutQuery,
  useGetKpiDailyQuery,
  useGetTaskNamesQuery,
  useCreateTaskMutation,
  useCreateTaskV2Mutation,
} = tasksSlice;
