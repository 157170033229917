import { HashRouter } from "react-router-dom";
import AppRoute from "./route";
import "react-modern-drawer/dist/index.css";
import "react-responsive-modal/styles.css";
import { DarkThemeToggle } from "flowbite-react";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <HashRouter>
      <div className="hidden">
        <DarkThemeToggle />
      </div>
      <Toaster
        toastOptions={{
          style: {
            background: "#167490",
            color: "#fff",
          },
        }}
      />
      <AppRoute />
    </HashRouter>
  );
}

export default App;
