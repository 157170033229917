import { TextInput } from "flowbite-react";
import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import { useLazyGetProductsQuery } from "../redux/queries/products";
import ImageView from "./ImageView";
import utills from "../lib/functions";
import toast from "react-hot-toast";
import _ from "lodash";

interface SearchProps {
  branchId?: string;
  companyId: string;
  onSelect: (item: Product) => void;
  error?: string;
  styling?: React.CSSProperties;
  sizing?: "sm" | "md" | "lg";
}
const ProductSearch = (props: SearchProps) => {
  const stopSerch = useRef<boolean>(false);
  const count = useRef<number>(0);
  const { companyId, onSelect, error, styling, sizing, branchId } = props;
  const [getProduct] = useLazyGetProductsQuery();
  const [value, setValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Product[]>([]);
  const [highlighted, setHighlighted] = useState("");

  const onChange = (
    event: ChangeEvent<HTMLInputElement>,
    { newValue }: Autosuggest.ChangeEvent
  ) => {
    count.current = newValue.length
    if (newValue.length === 1) stopSerch.current = false;
    setValue(newValue);
  };

  const inputProps: Autosuggest.InputProps<Product> = {
    placeholder: "Search Product Name or Scan Product Barcode...",
    value,
    onBlur: () => setValue(""),
    onChange: onChange as any,
  };

  const onSuggestionsFetchRequested = async ({ value:eventValue }: { value: string }) => {
    if (eventValue.trim().length < 2) return;
    if (!companyId) {
      toast.error(error ?? "Store Id not provided");
      return;
    }
    if(eventValue.length !== count.current) return
    const payload = branchId ? {
      supplier: companyId,
      company: branchId
    } : { 
      company: companyId
    }
    const res = await getProduct({
      limit: "0,10",
      name: eventValue.trim(),
      ...payload
    });
    if (stopSerch.current) return;
    if (res.data?.status === 404) {
      toast.error("No product found");
      setSuggestions([]);
      return;
    }
    if (res.data?.count === 1) {
      const [singleItem] = res.data.data;
      onSelect(singleItem);
      setValue("");
      count.current = 0
      setSuggestions([]);
      stopSerch.current = true;
    } else {
      // console.log("count > 1", value.length)
      setSuggestions(res.data?.data ?? []);
    }
  };

  const debouncedOnSuggestionsFetchRequested = _.debounce(
    onSuggestionsFetchRequested,
    1000, { leading: false, trailing: true }
  );

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
    stopSerch.current = false;
    count.current = 0
    // setValue('')
  };

  const getSuggestionValue = (suggestion: Product): string => suggestion.name;

  const renderSuggestion = (suggestion: Product) => (
    <div
      className={`p-1  border border-1 dark:border-gray-600 flex ${
        highlighted === suggestion.uuid && "bg-[#167490] text-gray-200"
      }`}
    >
      <ImageView url={suggestion.images_links[0]} className="w-8 h-8" />
      <div className="flex-1 pl-3 dark:text-gray-200 hover:text-white">
        <div className="text-xs font-bold">{suggestion.name}</div>
        <div className="text-[10px] flex justify-between items-center">
          <span>
            {utills._currency_format(Number(suggestion.price), "NGN")}
          </span>{" "}
          <span>Quantity: {suggestion.quantity}</span>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    const element = document.getElementById("searchFields");
    element?.focus();
  }, []);

  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionSelected={(event, { suggestion }) => {
          onSelect(suggestion);
          setSuggestions([]);
          count.current = 0
          setValue("");
        }}
        onSuggestionHighlighted={(props) =>
          setHighlighted(props.suggestion?.uuid ?? "")
        }
        shouldRenderSuggestions={(value) => value.trim().length > 1}
        onSuggestionsFetchRequested={debouncedOnSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestionsContainer={({ containerProps, children, query }) => (
          <div
            {...containerProps}
            className="absolute z-10 w-full bg-white dark:bg-gray-800 right-0 overflow-y-auto max-h-48 shadow-md"
          >
            {children}
          </div>
        )}
        renderSuggestion={renderSuggestion}
        renderInputComponent={(props) => {
          return (
            <TextInput
              id="searchFields"
              sizing={sizing ?? "sm"}
              style={{ borderRadius: 3, ...styling }}
              {...props}
            />
          );
        }}
        inputProps={inputProps}
        theme={{
          container: "m-2",
          input: "p-2 w-full",
        }}
      />
    </div>
  );
};

export default ProductSearch;
