import { api } from "../baseConfig";

interface PayloadFilter {
  company_uuid?: string;
  limit?: string;
  buyer_uuid?: string;
  order_company_uuid?: string;
}

interface SendReviewPayload {
  rate: number;
  review: string;
  order_company_uuid: string;
}

interface Review {
  company: { uuid: string; name: string };
  id: number;
  order_company_uuid: string;
  rate: number;
  review: string;
  user: {
    uuid: string;
    name: string;
    type: string;
    avatar: string;
  };
}

interface ReviewResponse {
  avarage_rate: number;
  count: number;
  data: Review[];
  status: number;
}

export const reviewSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getReviews: builder.query<ReviewResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/reviews`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Reviews"],
    }),
    sendReview: builder.mutation<any, SendReviewPayload>({
      query: (data) => ({
        url: "/reviews",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Reviews"],
    }),
  }),
});

export const { useSendReviewMutation, useGetReviewsQuery } = reviewSlice;
