import Breadcrumb from "../../components/Breadcrumb";
import { useAppSelector } from "../../lib/hook";
import { useGetPaymentQuery } from "../../redux/queries/Payments";
import { useEffect, useState } from "react";
import moment from "moment";
import utills from "../../lib/functions";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { Button } from "flowbite-react";
import CustomPagination from "../../components/CustomPagination";
import BranchSwitcher from "../../components/BranchSwitcher";
import CustomDatePicker from "../../components/CustomDatePicker";
import { useGetCompaniesQuery } from "../../redux/queries/company";
import CustomSelect from "../../components/CustomSelect";

const Cash = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    user: "",
    company_uuid: defaultBranchUUid,
    other_party: "",
    gateway: "cash",
    start_date: "",
    end_date: "",
  });
  const [active, setActive] = useState(0);
  const [filterFields, setFilterFields] = useState({
    start_date: "",
    end_date: "",
  });
  const [resetValue, setResetValue] = useState(false);
  const { data: paymentHistories, isLoading } = useGetPaymentQuery(filters);
  const [companiesFilters, setCompaniesFilters] = useState({
    limit: "0,10",
    status: 1,
    name: "",
  });
  const { data: companies, isLoading: isLoadingCompanies } =
    useGetCompaniesQuery(companiesFilters);

  const todayFn = () => {
    setActive(1);
    if (paymentHistories?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const yesterdayFn = () => {
    setActive(2);
    if (paymentHistories?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment()
          .startOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .endOf("day")
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  const lastWeekFn = () => {
    setActive(3);
    if (paymentHistories?.data.length) {
      setFilters((prev) => ({
        ...prev,
        start_date: moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  };

  useEffect(() => {
    if (paymentHistories?.data.length) {
      setFilterFields((prev) => ({
        ...prev,
        start_date: paymentHistories?.data[paymentHistories?.data.length - 1]
          ?.crdate.length
          ? moment(
              paymentHistories?.data[paymentHistories?.data.length - 1]?.crdate
            ).format("YYYY-MM-DD HH:mm:ss")
          : "",
        end_date: paymentHistories?.data[0]?.crdate.length
          ? moment(paymentHistories?.data[0]?.crdate).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
      }));
    }
  }, [paymentHistories?.data]);

  const reset = () => {
    setActive(0);
    setFilters({
      limit: "0,10",
      user: "",
      company_uuid: defaultBranchUUid,
      other_party: "",
      gateway: "cash",
      start_date: "",
      end_date: "",
    });
    setResetValue(true);
  };

  return (
    <div>
      <Breadcrumb title="Cash History" />
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex items-center gap-5">
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Transactions
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <GrTransaction className="text-red-500 text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>
              {utills._figure_format(Number(paymentHistories?.count) ?? 0)}
            </span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Credit
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <FaArrowUp className="text-[#167490] text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>
              {utills._currency_format(
                Number(paymentHistories?.credit) ?? 0,
                "NGN"
              )}
            </span>
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-gray-800 h-24 rounded-lg p-3 flex flex-col justify-between w-60">
          <div className="flex justify-between items-center">
            <span className="text-sm font-semibold text-gray-700 dark:text-white">
              Total Debit
            </span>
            <div className="bg-white rounded-lg p-1 flex items-center justify-center">
              <FaArrowDown className="text-red-500 text-xl" />
            </div>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-[25px]">
            <span>
              {utills._currency_format(
                Number(paymentHistories?.debit) ?? 0,
                "NGN"
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm flex gap-5 items-center">
        <div className="flex justify-between flex-wrap gap-3 w-full">
          <div className="flex flex-wrap items-center gap-3">
            <div className="flex items-center gap-2">
              <Button size="xs" onClick={todayFn} outline={active !== 1}>
                Today
              </Button>
              <Button size="xs" onClick={yesterdayFn} outline={active !== 2}>
                Yesterday
              </Button>
              <Button size="xs" onClick={lastWeekFn} outline={active !== 3}>
                Last Week
              </Button>
            </div>
            <CustomDatePicker
              start={
                filters.start_date.length
                  ? filters.start_date
                  : filterFields.start_date
              }
              onStartChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  start_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                }))
              }
              end={
                filters.end_date.length
                  ? filters.end_date
                  : filterFields.end_date
              }
              onEndChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  end_date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                }))
              }
            />
            <BranchSwitcher
              sizing="sm"
              defaultCompany={filters.company_uuid}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, company_uuid: value }))
              }
            />
            <div className="w-[200px]">
              <CustomSelect
                isClearable={false}
                disabled={isLoading || isLoadingCompanies}
                loading={isLoading || isLoadingCompanies}
                placeholder="Select Customer..."
                inputClasses="h-9"
                options={
                  companies?.data.map((customer) => ({
                    label: customer.name,
                    value: customer.uuid,
                  })) ?? []
                }
                onSelect={(val) => {
                  setFilters((prev) => ({
                    ...prev,
                    other_party: val.value,
                  }));
                  setResetValue(false);
                }}
                onSearch={(text) => {
                  setCompaniesFilters((prev) => ({ ...prev, name: text }));
                  setResetValue(false);
                }}
                reset={resetValue}
              />
            </div>
          </div>

          <div className="flex gap-2">
            <Button outline size="xs" onClick={reset}>
              Reset
            </Button>
          </div>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={paymentHistories?.data}
          total={paymentHistories?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Trans. Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Description
                </th>
                <th scope="col" className="px-6 py-3">
                  Debit(₦)
                </th>
                <th scope="col" className="px-6 py-3">
                  Credit(₦)
                </th>
                <th scope="col" className="px-6 py-3">
                  Balance(₦)
                </th>
                <th scope="col" className="px-6 py-3">
                  Transaction Ref.
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentHistories?.data?.map((item) => (
                <tr
                  key={item.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4">
                    {moment(item.crdate).format("YYYY-MM-DD")}
                  </td>

                  <td className="px-6 py-4">{item.description}</td>
                  <td className="px-6 py-4">
                    {Number(item.amount) < 0
                      ? utills._figure_format(
                          Number(item.amount.replace("-", ""))
                        )
                      : "-"}
                  </td>
                  <td className="px-6 py-4">
                    {Number(item.amount) >= 0
                      ? utills._figure_format(Number(item.amount))
                      : "-"}
                  </td>
                  <td className="px-6 py-4">
                    {utills._figure_format(Number(item?.transaction_balance))}
                  </td>
                  <td className="px-6 py-4">{item.uuid}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </CustomPagination>
      </div>
    </div>
  );
};

export default Cash;
