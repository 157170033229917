import { api } from "../baseConfig";

interface PayloadFilter {
  limit: string;
  name?: string;
  company?: string;
  email?: string;
  user?: string;
  type?: string;
  status?: number;
  phone?: string;
  phone_code?: string;
  whatsapp_code?: string;
}

interface SingleUser {
  status: number;
  message: string;
  data: User;
}
export const usersSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<UserResponse, PayloadFilter>({
      query: (filters) => ({
        url: "/users",
        method: "GET",
        params: filters,
      }),
      providesTags: ["Users"],
    }),
    getRiders: builder.query<RiderResponse, PayloadFilter>({
      query: (filters) => ({
        url: "/companies/riders",
        method: "GET",
        params: filters,
      }),
      providesTags: ["Riders"],
    }),
    getUser: builder.query<SingleUser, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: "GET",
      }),
      providesTags: ["Users"],
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: `/users/${data.uuid}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    createUser: builder.mutation<any, any>({
      query: (data) => ({
        url: "/users",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    resetPin: builder.mutation<any, any>({
      query: (data) => ({
        url: "/users/reset_pin",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    addSalary: builder.mutation<any, any>({
      query: (data) => ({
        url: "users/add_salary",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useGetRidersQuery,
  useResetPinMutation,
  useAddSalaryMutation,
} = usersSlice;
