import React from 'react'
import { useGetStocksQuery } from '../../redux/queries/stock'
import { useAppSelector } from '../../lib/hook'

const AddStock = () => {
    const { user } = useAppSelector((state)=> state.appUserConfig)
    const { data: stocks } = useGetStocksQuery(user?.company.uuid as string)

    // console.log(stocks)
  return (
    <div>Add Stock</div>
  )
}

export default AddStock