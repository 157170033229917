import { useEffect, useState } from "react";
import Select from "react-tailwindcss-select";

interface Option {
  label: string;
  value: string;
  disabled: boolean;
}
interface ISelect {
  inputClasses?: string;
  className?: string;
  options: {
    label: string;
    value: string;
  }[];
  onSelect: (value: Option) => void;
  onSearch?: (text: string) => void;
  placeholder?: string;
  reset?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  defaultValue?: Option;
  disabled?: boolean;
  loading?: boolean
}
const CustomSelect = (props: ISelect) => {
  const {
    options,
    inputClasses,
    onSelect,
    onSearch,
    placeholder,
    reset,
    isClearable = true,
    className,
    isSearchable = true,
    defaultValue,
    disabled = false,
    loading
  } = props;
  const [value, setValue] = useState<Option | null>(null);

  useEffect(() => {
    if (reset) {
      setValue(null);
    }
  }, [reset]);

  useEffect(() => {
    if (defaultValue?.value) setValue(defaultValue);
    else setValue(null);
  }, [defaultValue]);

  return (
    <Select
      isSearchable={isSearchable}
      isClearable={isClearable}
      loading={loading}
      isDisabled={disabled}
      primaryColor="#167490"
      classNames={{
        menu:
          "absolute z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" +
          " " +
          className,
        menuButton: () =>
          "flex bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" +
          ` ${disabled ? "bg-stone-200 cursor-not-allowed" : ""} ` +
          inputClasses,
      }}
      value={reset ? null : value}
      onChange={(val) => {
        onSelect(val as Option);
        setValue(val as Option);
      }}
      options={options}
      placeholder={placeholder}
      onSearchInputChange={(e) =>
        onSearch ? onSearch(e.target.value) : console.log("")
      }
    />
  );
};

export default CustomSelect;
