import { TextInput } from "flowbite-react";
import { CSSProperties, useEffect } from "react";

interface IAddressSearch {
  onSelect: (address: IAddress) => void;
  sizing: string;
  style?: CSSProperties
}
const AddressSearch = (props: IAddressSearch) => {
  const { onSelect, sizing, style } = props;

  const prepareAddressField = () => {
    //@ts-ignore
    const pickupArea = new window.google.maps.places.Autocomplete(
      document.getElementById("comp_geo_address")
    );
    pickupArea.addListener("place_changed", async () => {
      const plc = pickupArea.getPlace();
      onSelect({
        //@ts-ignore
        lat: `${plc.geometry.location.lat()}`,
        //@ts-ignore
        lng: `${plc.geometry.location.lng()}`,
        name: `${plc.name}, ${plc.formatted_address}`,
      });
    });
  };

  useEffect(() => {
    prepareAddressField();
  }, []);
  return (
    <TextInput
      sizing={sizing}
      style={style}
      type="text"
      id="comp_geo_address"
      placeholder="search address"
    />
  );
};

export default AddressSearch;
