// import { Button } from "flowbite-react";
import { FaTimes } from "react-icons/fa";
// import { IoPrint } from "react-icons/io5";
import Modal from "react-responsive-modal";
import utills from "../../lib/functions";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "flowbite-react";
import { IoPrint } from "react-icons/io5";
import { useGetUserQuery, useLazyGetUserQuery } from "../../redux/queries/users";
import { useAppSelector } from "../../lib/hook";
import { useLazyGetOrderQuery } from "../../redux/queries/order";

interface IPrintModal {
  isOpen: boolean;
  close: () => void;
  order?: CompanyOrder;
  orderId?: string;
}
const Invoice = (props: IPrintModal) => {
  const { close, isOpen, orderId } = props;
  const [getOrder, { data: order, isLoading }] = useLazyGetOrderQuery();

  useEffect(() => {
    if (orderId) {
      getOrder(orderId);
    }
  }, [orderId, getOrder]);

  if (!order?.data) return null;
  return (
    <Modal
      open={isOpen}
      onClose={close}
      classNames={{
        modalContainer: "__remove_modal_bg",
      }}
      showCloseIcon={false}
    >
      <div className="bg-white p-5 w-4/5 min-w-[800px]">
        <div className="flex justify-between items-center">
          <div></div>
          <button onClick={close}>
            <FaTimes />
          </button>
        </div>
        {!orderId ? (
          <div className="bg-red-200 border border-red-600 p-2 rounded-md">
            <span className="text-red-500">Can not find id</span>
          </div>
        ) : (
          <>
            {/* Page 1: First 30 items */}
            <PagePrint order={order?.data} items={order?.data?.items?.slice(0, 36)} totalPage={Math.ceil((order?.data?.items?.length ?? 0) / 36)} />

            {/* Remaining pages: 36 items per page */}
            {order?.data?.items?.length > 36 &&
              Array(Math.ceil((order.data.items.length - 36) / 40))
                .fill(0)
                .map((_, index) => {
                  const start = 36 + index * 40;
                  const end = start + 40;
                  return <PagePrint2 page={index+2} key={index} order={order.data} items={order.data.items.slice(start, end)} totalPage={Math.ceil((order?.data?.items?.length ?? 0) / 36)} />;
                })}
          </>
        )}
      </div>
    </Modal>
  );
};
export default Invoice;

export const PagePrint = ({ order, items, totalPage }: { order?: CompanyOrder; items?: CompanyOrderItem[], totalPage?: number }) => {
  const elRef = useRef(null);
  const [getUserRecord] = useLazyGetUserQuery();
  const { user } = useAppSelector((state) => state.appUserConfig);
  const { data: userData } = useGetUserQuery(user?.uuid as string);
  const [buyer, setBuyer] = useState<User | undefined>();

  const handlePrint = useReactToPrint({
    content: () => elRef.current,
  });
  // useEffect(()=> {
  //   handlePrint()
  // },[])

  const buyerRecord = async () => {
    const res = await getUserRecord(order?.buyer_details.uuid ?? "");
    if (res.data?.data) {
      setBuyer(res.data.data);
    }
  };

  const totalPrice = utills._invoice_total__price(items);

  useEffect(() => {
    buyerRecord();
  }, []);

  return (
    <>
      <Button className="" size="xs" onClick={(e: unknown) => handlePrint(e)}>
        <IoPrint className="mr-2 h-4 w-4" />
        Print page 1
      </Button>

      <div className=" px-5" ref={elRef}>
        <div className="text-center border-b border-black pb-2">
          <div className="text-[21px] font-bold">{userData?.data.company.name}</div>
          <div className="text-xs">{userData?.data.company.address.name}</div>
          <div className="text-xs">
            <span>Tel: {userData?.data.company.phone}</span> - <span>Whatsapp: {userData?.data.company.whatsapp}</span> -{" "}
            <span>Email: {userData?.data.company.email}</span>{" "}
          </div>
        </div>

        <div className="flex justify-between mt-0">
          <div className="flex flex-col gap-0 text-xs">
            <div className="flex gap-2">
              <span className="font-bold">TO:</span>
              <div className="font-bold uppercase">{buyer?.company.name}</div>
            </div>
            <div className="flex gap-2">
              <span className="font-bold">EMAIL:</span>

              <div>{buyer?.company.email}</div>

              <div className="flex gap-2">
                <span className="font-bold">STAFF:</span>

                <div className="capitalize">{buyer?.name}</div>
              </div>
            </div>
            <div className="flex gap-2">
              <span className="font-bold">ADDRESS:</span>
              <div>{buyer?.company.address2 ?? buyer?.company.address.name}</div>
            </div>
            <div className="flex gap-2">
              <span className="font-bold">Total items on page: </span>
              <div>{items?.length}</div>
            </div>
          </div>
          <div className="text-[10px] font-bold">
            <div className="flex">
              <span className="w-24">{order?.order.type === 11 ? "INVOICE" : order?.order.type === 10 ? "QUOTATION" : "RECEIPT"} NO:</span>{" "}
              <span>#{order?.id}</span>
            </div>
            <div className="flex">
              <span className="w-24">DATE:</span> <span>{moment(order?.created_at).format("LLL")}</span>
            </div>
            <div className="flex">
              <span className="w-24">SERVED BY:</span> <span className="capitalize">{order?.staff.name}</span>
            </div>
            <div className="flex">
              <span className="w-24">BRANCH:</span> <span className="capitalize">{order?.company.name}</span>
            </div>
          </div>
        </div>

        <div className="mt-0">
          <table className="w-full text-[13px]">
            <thead className="w-full bg-black/80 text-white text-xs">
              <tr>
                <th className="px-2 text-start">QTY</th>
                <th className="text-start">NAME</th>
                <th className="text-start">RATE</th>
                <th className="text-start">VALUE</th>
              </tr>
            </thead>
            <tbody>
              {items
                ?.filter((x) => !x.return)
                .map((item) => (
                  <tr key={crypto.randomUUID()} className="border-b border-black">
                    <td className="px-2 py-0">
                      {item.quantity} {item.unit}
                    </td>
                    <td className="font-semibold">{item.name}</td>
                    <td>{utills._currency_format(Number(item.price), "NGN")}</td>
                    <td>{utills._currency_format(Number(item.price) * item.quantity, "NGN")}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* <div className="text-xs flex gap-2 font-semibold mt-1">
            <span>Delivery Fee: {utills._currency_format(order?.delivery_price ?? 0, "NGN")}</span>
            <span>Grand Total: {utills._currency_format(order?.total_items_cost ?? 0, "NGN")}</span>
            <span>Total Amount On Page: {utills._currency_format(totalPrice.totalPriceInNaira, "NGN")}</span>
            <span>Total Paid: {utills._currency_format(order?.amount_paid ?? 0, "NGN")}</span>
            <span>Balance: {utills._currency_format((order?.total_price ?? 0) - (order?.amount_paid ?? 0), "NGN")}</span>
          </div> */}
          <div className="flex items-center justify-between">
          <div className="text-xs flex gap-2 font-semibold mt-1">
            <span>Delivery Fee: {utills._currency_format(order?.delivery_price ?? 0, "NGN")}</span>
            <span>Total Due: {utills._currency_format(totalPrice.totalPriceInNaira, "NGN")}</span>
            <span>Total Paid: {utills._currency_format(order?.amount_paid ?? 0, "NGN")}</span>
            <span>Balance: {utills._currency_format((order?.total_price ?? 0) - (order?.amount_paid ?? 0), "NGN")}</span>
          </div>
          <div className="text-xs font-semibold">Page 1/{totalPage}</div>
          </div>
        </div>

        <div className="mt-5 mb-5">
          <div className="text-xs italic border-t text-center">
            All payments must be strictly made to Grace Johnsons Ltd 1013842180 Zenith Bank. Our return policy covers 2 weeks after purchase.
          </div>
        </div>
      </div>
    </>
  );
};

export const PagePrint2 = ({ order, items, page, totalPage }: { order?: CompanyOrder; items?: CompanyOrderItem[], page: number, totalPage?: number }) => {
  const elRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => elRef.current,
  });

  const totalPrice = utills._invoice_total__price(items);
  // useEffect(()=> {
  //   handlePrint()
  // },[])
  return (
    <>
      <Button className="" size="xs" onClick={(e: unknown) => handlePrint(e)}>
        <IoPrint className="mr-2 h-4 w-4" />
        Print page {page}
      </Button>
      <div className="p-5" ref={elRef}>
        <div className="flex justify-between mt-3">
          <div className="flex flex-col gap-0 text-xs">
            <span><strong>To: </strong><span className="font-bold uppercase">{order?.buyer_details.name}</span></span>
            <span><strong>Email: </strong><span className="font-bold uppercase">{order?.buyer_details.email}</span></span>
            <div><strong>Total items on page: </strong> {items?.length}</div>
          </div>
          <div className="text-[10px] font-bold">
            <div className="flex">
              <span className="w-24">{order?.order.type === 11 ? "INVOICE" : order?.order.type === 10 ? "QUOTATION" : "RECEIPT"} NO:</span>{" "}
              <span>#{order?.id}</span>
            </div>
            <div className="flex">
              <span className="w-24">DATE:</span> <span>{moment(order?.created_at).format("LLL")}</span>
            </div>
            <div className="flex">
            <span className="w-24">SERVED BY:</span> <span className="capitalize">{order?.staff.name}</span>
            </div>
          </div>
        </div>

        {/* Body of the Invoice */}

        <div className="mt-0">
          <table className="w-full text-[13px]">
            <thead className="w-full bg-black/80 text-white text-xs">
              <tr>
                <th className="px-2 text-start">QTY</th>
                <th className="text-start">NAME</th>
                <th className="text-start">RATE</th>
                <th className="text-start">VALUE</th>
              </tr>
            </thead>
            <tbody>
              {items
                ?.filter((x) => !x.return)
                .map((item, index) => (
                  <tr key={item.uuid} className="border-b border-black">
                    <td className="px-2 py-0">
                      {item.quantity} {item.unit}
                    </td>
                    <td className="font-semibold">{item.name}</td>
                    <td>{utills._currency_format(Number(item.price), "NGN")}</td>
                    <td>{utills._currency_format(Number(item.price) * item.quantity, "NGN")}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="flex items-center justify-between">
          <div className="text-xs flex gap-2 font-semibold mt-1">
            <span>Delivery Fee: {utills._currency_format(order?.delivery_price ?? 0, "NGN")}</span>
            <span>Total Due: {utills._currency_format(totalPrice.totalPriceInNaira, "NGN")}</span>
            <span>Total Paid: {utills._currency_format(order?.amount_paid ?? 0, "NGN")}</span>
            <span>Balance: {utills._currency_format((order?.total_price ?? 0) - (order?.amount_paid ?? 0), "NGN")}</span>
          </div>
          <div className="text-xs font-semibold">Page {page}/{totalPage}</div>
          </div>
        </div>

        <div className="mt-5 mb-5">
          <div className="text-xs italic border-t text-center">
            All payments must be strictly made to Grace Johnsons Ltd 1013842180 Zenith Bank. Our return policy covers 2 weeks after purchase.
          </div>
        </div>
      </div>
    </>
  );
};
