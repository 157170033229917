import { useState } from "react";
import { Outlet } from "react-router-dom";
import LeftBar from "./LeftBar";
import Navbar from "./Navbar";
import Drawer from "react-modern-drawer";
import PinApplication from "../components/PinApplication";

export default function Layout() {
  const [isOpen, setIsOpen] = useState(false);

  const close = () => setIsOpen(false);

  return (
    <div className="flex min-h-screen bg-slate-100 dark:bg-gray-900">
      <div className="bg-white w-[275px] hidden lg:block">
        <LeftBar close={close} />
      </div>
      <div className="lg:hidden">
        <Drawer
          open={isOpen}
          onClose={() => setIsOpen((prev) => !prev)}
          direction="left"
          size={275}
          duration={256}
        >
          <div className="lg:block">
            <LeftBar close={close} />
          </div>
        </Drawer>
      </div>
      <main className="flex-1 h-screen overflow-auto">
        <Navbar toggle={() => setIsOpen((prev) => !prev)} />
        <div className="p-3">
          <Outlet />
        </div>
      </main>
      <PinApplication />
    </div>
  );
}
