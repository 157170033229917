import { api } from "../baseConfig";

interface PayloadFilter {
  limit?: string;
  name?: string;
  company_uuid?: string;
}

interface otherPayloadFilter {
  name: string;
  company_uuid: string;
  uuid?: string;
}

interface DepartmentsResponse {
  count: number;
  data: Singledepartment[];
  status: number;
}

export const departmentsSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    createDepartment: builder.mutation<any, otherPayloadFilter>({
      query: (data) => ({
        url: "/companies/departments",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Departments"],
    }),
    updateDepartment: builder.mutation<any, any>({
      query: (data) => ({
        url: `/companies/departments/${data.uuid}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Departments"],
    }),
    getDepartments: builder.query<DepartmentsResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/companies/departments`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Departments"],
    }),
    deleteDepartment: builder.mutation<any, string>({
      query: (uuid) => ({
        url: `/companies/departments/${uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Departments"],
    }),
  }),
});

export const {
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useGetDepartmentsQuery,
  useDeleteDepartmentMutation,
} = departmentsSlice;
