import { Banner, Button, Select, Spinner, Table } from "flowbite-react";
import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { BiSolidSortAlt } from "react-icons/bi";
import { useAppSelector } from "../../lib/hook";
import utills from "../../lib/functions";
import emptyIcon from "../../assets/empty.png";
import ImageView from "../../components/ImageView";
import {
  useGetStockCountQuery,
  useGetStockDataListQuery,
  useMarkAsCompletedMutation,
} from "../../redux/queries/stock";
import { BsBookshelf } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import toast from "react-hot-toast";
import moment from "moment";

const StockCount = () => {
  const { id: uuid } = useParams();
  const navigate = useNavigate();
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [company, setCompany] = useState(defaultBranchUUid);
  const { data: stockLists } = useGetStockDataListQuery({});
  const [selectStock, setSelectStock] = useState(uuid);
  const { data: stockCount, isLoading } = useGetStockCountQuery(
    { company: company, company_count_uuid: selectStock },
    { skip: !selectStock }
  );
  const [id, setId] = useState<number | null>(null);

  const [markAsCompleted, { isLoading: isMarkasCompleted }] =
    useMarkAsCompletedMutation();

  const onMarkasCompleted = async () => {
    const payload = {
      company_count_uuid: selectStock,
    };

    const res = (await markAsCompleted(payload)) as any;
    if (res?.data?.status === 200) {
      toast.success("Marked As Completed");
    } else toast.error(res?.data?.message);
  };

  return (
    <div>
      <Breadcrumb title="Stock Count" />
      <button
        onClick={() => navigate("/stock-count")}
        className="mr-3 inline-flex items-center justify-center rounded-md border border-gray-200 bg-white px-3 py-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-cyan-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
      >
        <IoMdArrowRoundBack className="mr-2 h-4 w-4" />
        Back
      </button>
      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Stock count.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                See all stock count records.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <BiSolidSortAlt className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="bg-white dark:bg-gray-800 p-3 rounded-md flex items-cente justify-between">
        <div className="w-[350px]">
          <Select
            sizing="sm"
            style={{ borderRadius: 3 }}
            className="w-full"
            value={selectStock}
            onChange={(e) => setSelectStock(e.target.value)}
          >
            {stockLists?.data.map((sto) => (
              <option key={sto.uuid} value={sto.uuid}>
                {sto.name}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <Button
            size="sm"
            disabled={isMarkasCompleted}
            isProcessing={isMarkasCompleted}
            onClick={onMarkasCompleted}
          >
            MARK AS COMPLETED
          </Button>
        </div>
      </div>

      {isLoading && (
        <div className="flex items-center justify-center gap-3">
          <Spinner />
          <Spinner />
          <Spinner />
        </div>
      )}

      <div className="flex gap-5 mt-5">
        <div>
          {stockCount?.data.map((st) => (
            <StockCard
              key={st.id}
              stockCountData={st}
              selectShelf={(val) => setId(val)}
              selectedId={id}
            />
          ))}
        </div>
        <div className="flex-1">
          <StockDetailsCard
            stockCountData={stockCount?.data.find((sto) => sto.id === id)}
          />
        </div>
      </div>
    </div>
  );
};

export default StockCount;

interface IStockCard {
  stockCountData: IStockCountSingle;
  selectShelf: (val: number) => void;
  selectedId: number | null;
}
const StockCard = (props: IStockCard) => {
  const { stockCountData, selectShelf, selectedId } = props;
  return (
    <div
      onClick={() => selectShelf(stockCountData.id)}
      className="border rounded-sm border-gray-400 dark:border-gray-700 bg-white dark:bg-gray-800 w-[300px] mb-4 cursor-pointer hover:bg-gray-100 hover:dark:bg-gray-900"
    >
      <div
        className={`flex items-center justify-between gap-3 p-2 ${
          selectedId === stockCountData.id
            ? "bg-[#167490] text-white"
            : "bg-transparent text-[#167490]"
        } rounded-t-sm border-b-2 dark:border-gray-700`}
      >
        <div className="flex items-center gap-3">
          <BsBookshelf />{" "}
          <span className="text-[#12px] font-semibold">
            {stockCountData.shelf.name}
          </span>
        </div>
        <div onClick={(e) => e.stopPropagation()}></div>
      </div>
      <div className="p-3 dark:text-gray-200 text-xs flex justify-between items-center">
        <span>Stock Count</span>
        <span>{stockCountData.count ?? "-"}</span>
      </div>
      <div className="p-3 dark:text-gray-200 text-xs flex justify-between items-center border-t border-gray-400 dark:border-gray-600">
        <span>Stock Recount</span>
        <span>{stockCountData.recount}</span>
      </div>
      <div className="p-3 dark:text-gray-200 text-xs border-t border-gray-400 dark:border-gray-600">
        {stockCountData.company_count.name}
      </div>
    </div>
  );
};

interface IStockDetailsCard {
  stockCountData?: IStockCountSingle;
}
const StockDetailsCard = (props: IStockDetailsCard) => {
  const { stockCountData } = props;
  console.log(stockCountData);

  if (!stockCountData) {
    return (
      <div className="flex items-center justify-center my-5">
        <div className="flex flex-col items-center">
          <img src={emptyIcon} alt="empty record" className="w-20" />
          <span className="dark:text-gray-400 mt-2 text-xs">
            Select at least one shelf
          </span>
        </div>
      </div>
    );
  }
  return (
    <div className="w-full sticky top-16">
      <div className="bg-[#167490] text-white flex items-center justify-between h-10 px-3">
        <span>#{stockCountData.id}</span>
        <span>{stockCountData.shelf.name}</span>
      </div>
      <div className="py-3">
        <div className="flex flex-col gap-2 mb-5 min-w-[40%] font-medium">
          <div className="flex items-center justify-between w-full">
            <div className="dark:text-gray-200 text-xs flex-1">
              <span className="mr-3">Total Count: {stockCountData.count}</span>
            </div>
            <div className="dark:text-gray-200 text-xs flex-1">
              <span className="mr-3">
                Date:{" "}
                {stockCountData?.start_date
                  ? moment(stockCountData?.start_date).format("Do MMM, YYYY")
                  : null}
              </span>{" "}
            </div>
            <div className="dark:text-gray-200 text-xs flex-1">
              <span className="mr-3">Staff:</span>{" "}
            </div>
          </div>
          <div className="flex items-center justify-between w-full">
            <div className="dark:text-gray-200 text-xs flex-1">
              <span className="mr-3">
                Total ReCount: {stockCountData.recount}
              </span>
            </div>
            <div className="dark:text-gray-200 text-xs flex-1">
              <span className="mr-3">
                Date:{" "}
                {stockCountData?.end_date
                  ? moment(stockCountData?.end_date).format("Do MMM, YYYY")
                  : null}
              </span>{" "}
            </div>
            <div className="dark:text-gray-200 text-xs flex-1">
              <span className="mr-3">Staff:</span>{" "}
            </div>
          </div>
        </div>

        <div className="">
          <Table hoverable className="text-xs">
            <Table.Head>
              <Table.HeadCell>#</Table.HeadCell>
              <Table.HeadCell>Item Name</Table.HeadCell>
              <Table.HeadCell>Expected</Table.HeadCell>
              <Table.HeadCell>Count</Table.HeadCell>
              <Table.HeadCell>Differences</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {stockCountData.count_detail?.map((stock) => {
                return (
                  <Table.Row
                    key={stock.uuid}
                    className="bg-white dark:border-gray-800 dark:bg-gray-800"
                  >
                    <Table.Cell>
                      <ImageView
                        url={utills._default_img}
                        className="w-6 h-6"
                      />
                    </Table.Cell>
                    <Table.Cell>{stock.item.name}</Table.Cell>
                    <Table.Cell>{stock.expected}</Table.Cell>
                    <Table.Cell>{stock.count}</Table.Cell>
                    <Table.Cell
                      className={
                        stock.difference < 0
                          ? "text-red-600"
                          : stock.difference > 0
                          ? "text-yellow-400"
                          : "text-green-500"
                      }
                    >
                      {stock.difference}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
};
