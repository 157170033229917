import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../lib/hook";

const useAuth = () => {
  const { token } = useAppSelector((state) => state.appUserConfig);

  const isUserAuthenticated = !!token;
  return isUserAuthenticated;
};

const AuthChecker = ({ children }: { children: any }) => {
  const location = useLocation();
  const isAuth = useAuth();

  return isAuth ?  <Navigate to="/dashboard" state={{ from: location }} /> : children
};

export default AuthChecker;