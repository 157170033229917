import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { openRoutes, authRoutes } from "./data";
import Layout from "../layout";
import ProtectedRoute from "./ProtectedRoute";
import AuthChecker from "./AuthChecker";
import POS from "../POS";
import { useAppSelector } from "../lib/hook";

const Index = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      {openRoutes.map((rout) => (
        <Route
          key={rout.route}
          element={
            <AuthChecker>
              <rout.component />
            </AuthChecker>
          }
          path={rout.route}
        />
      ))}
      {authRoutes
        .filter((x) => (!user?.type ? true : x.type.includes(user.type)))
        .map((rout) => (
          <Route
            key={rout.route}
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route element={<rout.component />} path={rout.route} />
          </Route>
        ))}
      <Route
        path="pos"
        element={
          <ProtectedRoute>
            <POS />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default Index;
