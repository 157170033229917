import { api } from "../baseConfig";

interface PayloadFilter {
  limit?: string;
  name?: string;
  status?: number;
  company?: string;
  category?: string;
  user?: string;
  start_date?: string;
  end_date?: string;
  gateway?: string;
  description?: string;
  amount?: number;
}

export const expensesSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getExpenses: builder.query<ExpensesResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/companies/expenses`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Expenses"],
    }),
    addExpense: builder.mutation<any, PayloadFilter>({
      query: (data) => ({
        url: "/companies/expenses",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Expenses"],
    }),
  }),
});

export const { useGetExpensesQuery, useAddExpenseMutation } = expensesSlice;
