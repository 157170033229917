import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../lib/hook";

const useAuth = () => {
  const { token } = useAppSelector((state) => state.appUserConfig);

  const isUserAuthenticated = !!token;
  return isUserAuthenticated;
};

const ProtectedRoute = ({ children }: { children: any }) => {
  const location = useLocation();
  const isAuth = useAuth();

  return isAuth ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default ProtectedRoute;
