import { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { Banner, Button, Datepicker, Label, Select, TextInput, Tooltip, useThemeMode } from "flowbite-react";
import { LiaFileInvoiceDollarSolid, LiaTimesSolid } from "react-icons/lia";
import BranchSwitcher from "../../components/BranchSwitcher";
import { FaPlus, FaTruck } from "react-icons/fa";
import ProductSearch from "../../components/ProductSearch";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import CustomSelect from "../../components/CustomSelect";
import { useGetCompaniesQuery } from "../../redux/queries/company";
import { useCreateUserMutation, useLazyGetUserQuery, useLazyGetUsersQuery, useUpdateUserMutation } from "../../redux/queries/users";
import { addToInvoice, invoiceForPreview, updateInvoice, updateInvoiceMeta } from "../../redux/slices/cart";
import { useLazyGetCartItemsQuery } from "../../redux/queries/cart";
import { v4 as uuidv4 } from "uuid";
import VariantModal from "../../components/VariantModal";
import utills from "../../lib/functions";
import Modal from "react-responsive-modal";
import InvoiceTable from "./InvoiceTable";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { lowerCase } from "lodash";
import { useLazyGetProductQuery } from "../../redux/queries/products";
import { Draft, Drafts } from "./AddInvoice";

interface MyInterface {
  label: string;
  value: string;
}

const PharmaInvoice = () => {
  const dispatch = useAppDispatch();
  const { defaultBranchUUid, user } = useAppSelector((state) => state.appUserConfig);
  const { invoice, invoiceMeta, isInvoiceCurrent, duplicateItem } = useAppSelector((state) => state.cartAndDraft);
  const [branch, setBranch] = useState(defaultBranchUUid);
  const [variantModal, setVariantModal] = useState(false);
  const [draftOpen, setDraftOpen] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [variantItem, setVariantItem] = useState<Product>();
  const [userDrafts, setUserDrafts] = useState<Draft[]>([]);
  const [customerFilter, setCustomerFilter] = useState({
    limit: "0,20",
    name: "",
    type: "pharmacy",
    status: 1,
  });

  const [ getProduct ] = useLazyGetProductQuery()
  const [getUserData] = useLazyGetUserQuery();
  const [getOnlineInvoice, { data }] = useLazyGetCartItemsQuery();
  const { data: companies, isFetching } = useGetCompaniesQuery(customerFilter);
  const [getCustomers, { data: customersList, isFetching: userFetching }] = useLazyGetUsersQuery();

  const handleCustomerSelect = useCallback((data: MyInterface) => {
    dispatch(
      updateInvoiceMeta({
        delevery_fee: invoiceMeta.delevery_fee,
        customer: {
          label: "",
          value: "",
        },
        company: data,
      })
    );
    getCustomers({
      company: data.value,
      status: 1,
      limit: "0,50",
    });
  }, []);

  const getInvoiceToRender = useCallback(async () => {
    const res = await getOnlineInvoice({ type: "11" });
    if (res.data?.data) {
      const record = res.data.data;
      if (Array.isArray(record)) {
        const drafts = record.map((item) => ({
          id: item.invoice_id,
          uuid: item.uuid,
          customer: item.buyer_company_detail?.name ?? "",
          total: item.cartitems.reduce((acc, x) => acc + x.price * x.quantity, 0),
        }));
        setUserDrafts(drafts);
        renderInvoice(record[0]);
       
      }

    } else if (invoiceMeta.company.value) {
      getCustomers({
        limit: "0,60",
        company: invoiceMeta.company.value,
        status: 1,
      });
    }
  }, []);

  const renderInvoice = useCallback(async(obj: ICartFromServer)=> {
      const userCompany = await getUserData(obj.buyer);
      const userCompanydata = userCompany?.data?.data;
      const properties = {
        delevery_fee: obj.delivery_fee.toString(),
        customer: {
          label: userCompanydata?.name ?? "",
          value: userCompanydata?.uuid ?? "",
        },
        company: {
          label: obj.buyer_company_detail.name ?? "",
          value: obj.buyer_company_detail.uuid ?? "",
        },
        invoice_id: obj.invoice_id,
      };
      const items = obj.cartitems.map((item) => ({
        cart_uuid: uuidv4(),
        uuid: item.item_uuid,
        price: item.price,
        image: item.item.images,
        name: item.name,
        quantity: item.quantity,
        company: item.company,
        verified: item.verified,
        base_unit: item.item.base_unit,
      }));
      if (properties.company.value)
        getCustomers({
          limit: "0,60",
          company: properties.company.value,
          status: 1,
          // type: "user",
        });

      dispatch(
        invoiceForPreview({
          invoice: items,
          params: properties,
        })
      );
  },[])


  const handleItemToCart = useCallback((item: Product, variant?: { id: number; name: string; price: number }) => {
    const cart_uuid = uuidv4();
    const payload: InvoiceCartWithId = {
      cart_uuid,
      uuid: item.uuid,
      price: item.selling_price,
      name: item.name,
      quantity: 1,
      company: item.company.uuid,
      image: item.images_links,
      verified: item.search_by_barcode ? 1 : 0,
      base_unit: item.base_unit,
    };
    if (variant) {
      payload.variant_id = variant.id;
      payload.base_unit = variant.name;
      payload.price = variant.price;
    }
    const isAlreadyExist = invoice.find((item) => item.uuid === payload.uuid && variant?.id);
    if (isAlreadyExist) {
      dispatch(updateInvoice(payload));
    }else dispatch(addToInvoice(payload));
  }, []);

  useEffect(() => {
    getInvoiceToRender();
    getCustomers({limit: "0,50", status: 1, type: "user"})
  }, []);

  useEffect(() => {
    if (invoice.length > 0) {
      let pickedItemCopy = structuredClone(invoice);
      const lastdata = pickedItemCopy.shift();
      if (lastdata) {
        const el = document.getElementById(lastdata.cart_uuid);
        setTimeout(() => el?.focus(), 1000);
      }
    }
  }, [invoice.length]);
  const editVariant = async (uuid: string) => {
    const toastId = toast.loading("Retrieving variants, please wait...");
    const product = await getProduct(uuid);
    if(product.data){
      setVariantItem(product.data.data)
      toast.success("Variant retrieved successfully", { id: toastId });
      setVariantModal(true)
    }else toast.error("Failed to retrieve variant", { id: toastId });
  }

  const handleRenderDraft = useCallback(async(draft: Draft)=> {
    const toastId = toast.loading("Retrieving draft, please wait...");
    const onlineInvoices = await getOnlineInvoice({ type: "11" });
    if (onlineInvoices.data?.data) {
      const record = onlineInvoices.data.data;
      if (Array.isArray(record)) {
        const selectedDraft = record.find((item) => item.uuid === draft.uuid);
        if(selectedDraft) renderInvoice(selectedDraft);     
      }
    }
    toast.success("Draft retrieved successfully", { id: toastId });
  }, [])

  return (
    <>
      <div>
        <Breadcrumb title="Create Invoice" />
        <div className="mt-10">
          <Banner className="mb-5">
            <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
              <div className="mb-4 md:mb-0 md:mr-4">
                <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">Create Invoice For Customer.</h2>
                <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                  Search through you products and create an invoice.
                </p>
              </div>
              <div className="flex flex-shrink-0 items-center">
                <LiaFileInvoiceDollarSolid className="text-5xl text-gray-500 dark:text-white" />
              </div>
            </div>
          </Banner>
        </div>

        <div className="mt-3">
          <div className="flex items-center">
            <div className="max-w-sm">
              <Label value="Invoice Date" className="mb-1" />
              <Datepicker style={{ borderRadius: 3 }} />
            </div>
          </div>
        </div>
        <div className="mt-2">
          Invoice ID: {invoiceMeta.invoice_id}
        </div>

        <div>
          <div className="flex-1 my-4">
            <div className="flex gap-3">
              <div className="w-[300px]">
                <CustomSelect
                  defaultValue={invoiceMeta.customer.value ? { ...invoiceMeta.customer, disabled: false } : undefined}
                  isClearable={false}
                  onSearch={(val) => {
                    getCustomers({
                      name: val,
                      status: 1,
                      limit: "0,50",
                    });
                  }}
                  loading={userFetching}
                  placeholder="Select patient..."
                  inputClasses="h-[43px] pt-1 rounded-sm"
                  isSearchable={true}
                  options={
                    customersList?.data.map((customer) => ({
                      label: customer.name,
                      value: customer.uuid,
                    })) ?? []
                  }
                  onSelect={(val) => {
                    const userName = customersList?.data.find((item) => item.uuid === val.value);
                    if (userName) {
                      dispatch(
                        updateInvoiceMeta({
                          delevery_fee: invoiceMeta.delevery_fee,
                          company: invoiceMeta.company,
                          customer: {
                            label: userName.name,
                            value: val.value,
                          },
                        })
                      );
                    }
                  }}
                />
              </div>

                <Tooltip content="Add patient">
                  <button onClick={() => setAddUser(true)} className="border border-[#167490] p-3 size-[41px]">
                    <FaPlus className="text-[#167490]" />
                  </button>
                </Tooltip>

              <BranchSwitcher sizing="md" styling={{ borderRadius: 3 }} onChange={(text) => setBranch(text)} />
              <TextInput
                id="fee"
                icon={FaTruck}
                value={invoiceMeta.delevery_fee}
                min="0"
                onChange={(e) =>
                  dispatch(
                    updateInvoiceMeta({
                      delevery_fee: e.target.value,
                      customer: invoiceMeta.customer,
                      company: invoiceMeta.company,
                    })
                  )
                }
                type="number"
                step="any"
                className=""
                placeholder="Delivery fee"
                style={{ borderRadius: 2 }}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center mt-5">
          <div className="flex-1 relative">
            <ProductSearch
              sizing="lg"
              companyId={branch}
              onSelect={(item) => {
                const isItemWithVariant = utills._display_variant(item.variants);
                if (isItemWithVariant) {
                  setVariantItem(item);
                  setVariantModal(true);
                  return;
                } else {
                  handleItemToCart(item);
                }
              }}
              error="Please Select Branch"
            />
          </div>
        </div>
        <InvoiceTable branch={branch} editVariant={editVariant} openDraft={() => setDraftOpen(true)}/>
      </div>

      <VariantModal
        isOpen={variantModal}
        close={() => {
          setVariantModal(false);
        }}
        item={variantItem}
        onSelectItemVariant={(product, variant) => handleItemToCart(product, variant)}
      />

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={addUser}
        onClose={() => setAddUser(false)}
      >
        <AddPatient
          user={undefined}
          // user={companies?.data.find((x) => x.uuid === invoiceMeta.company.value)}
          close={() => {
            setAddUser(false);
            getCustomers({
              company: invoiceMeta.company.value,
              status: 1,
              limit: "0,50",
            });
          }}
          type={companies?.data.find((x) => x.uuid === invoiceMeta.company.value)?.type}
        />
      </Modal>
      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={draftOpen}
        onClose={() => setDraftOpen(false)}
      >
        <Drafts close={() => setDraftOpen(false)} drafts={userDrafts} onSelect={handleRenderDraft} invoice_id={invoiceMeta.invoice_id} />
      </Modal>
    </>
  );
};

export default PharmaInvoice;

interface UserCard {
  user: Branch | undefined;
  close: () => void;
  type?: string;
}

const schemaAddUser = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  email: yup.string(),
  phone: yup.string().required("Phone number is a required field"),
  status: yup.string().required("Status is a required field"),
});

interface IFormInputAddUser {
  name: string;
  email?: string;
  phone: string;
  status: string;
}


export const AddPatient = (props: UserCard) => {
  const { user, close } = props;
  const [createUser, { isLoading: isCreatingLoading }] =
    useCreateUserMutation();
  const [updateUser, { isLoading: loading }] = useUpdateUserMutation();
  const { mode } = useThemeMode();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputAddUser>({ resolver: yupResolver(schemaAddUser) });

  const onSubmit = async (data: IFormInputAddUser) => {
    const payload = {
      name: data.name,
      email: data.email,
      phone: Number(data.phone),
      status: Number(data.status),
      type: user?.type,
      country_id: user?.country?.id,
      company: user?.uuid,
    };

    const res = (await createUser(payload)) as any;
    if (res?.data?.status === 200) {
      if ("data" in res) {
        const result = await updateUser({
          uuid: res?.data?.data.uuid,
          type: res?.data?.data.type,
          company: user?.uuid,
        });
        if ("data" in result) {
          if (result.data.status === 200) {
            toast.success("User Added");
            close();
          } else toast.error("Unable to add user");
        } else toast.error("Unable to add user");
      } else toast.error("Unable to add user");
    } else toast.error(res.data.data.join(","));
  };

  return (
    <div className="w-[500px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">
          Add Patient
        </span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <form
        autoComplete="off"
        className="bg-white p-3 pb-5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-2 gap-3 mt-2">
          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Full Name
            </span>
            <TextInput
              disabled={loading ?? isCreatingLoading}
              sizing="md"
              type="text"
              placeholder="Enter Your Full Name"
              {...register("name")}
              color={errors?.name ? "failure" : "gray"}
              helperText={
                errors.name && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.name?.message}!
                  </span>
                )
              }
            />
          </div>

          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Email Address
            </span>
            <TextInput
              disabled={loading ?? isCreatingLoading}
              sizing="md"
              type="email"
              placeholder="Enter Email Address"
              {...register("email")}
            />
          </div>

          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Phone Number
            </span>

            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <PhoneInput
                    {...field}
                    country={lowerCase(user?.country?.code)}
                    disabled={loading ?? isCreatingLoading}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    buttonStyle={{
                      backgroundColor: errors?.phone
                        ? "rgb(254 242 242)"
                        : mode === "light"
                        ? "rgb(249 250 251)"
                        : "rgb(75 85 99)",
                      border: `1px solid ${
                        errors?.phone
                          ? "rgb(239 68 68)"
                          : mode === "light"
                          ? "rgb(209 213 219)"
                          : "rgb(75 85 99)"
                      }`,
                    }}
                    inputStyle={{
                      width: "auto",
                      height: "42px",
                      paddingRight: "5px",
                      backgroundColor: errors?.phone
                        ? "rgb(254 242 242)"
                        : mode === "light"
                        ? "rgb(249 250 251)"
                        : "rgb(75 85 99)",
                      border: `1px solid ${
                        errors?.phone
                          ? "rgb(239 68 68)"
                          : mode === "light"
                          ? "rgb(209 213 219)"
                          : "rgb(75 85 99)"
                      }`,
                      color: mode === "light" ? "black" : "white",
                    }}
                    dropdownStyle={{
                      position: "absolute",
                      top: -20,
                      left: 0,
                    }}
                  />
                  {errors.phone && (
                    <span className="font-medium text-[10px] text-red-500">
                      {" "}
                      {errors.phone?.message}!
                    </span>
                  )}
                </div>
              )}
            />
          </div>

          <div>
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Status
            </span>
            <Select
              disabled={loading ?? isCreatingLoading}
              sizing="md"
              {...register("status")}
              color={errors?.status ? "failure" : "gray"}
              helperText={
                errors.status && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.status?.message}
                  </span>
                )
              }
            >
              <option value="" disabled hidden>
                Select a status
              </option>
              <option value="1">Active</option>
              <option value="0">InActive</option>
            </Select>
          </div>
        </div>

        <div className="mt-3 flex items-center justify-end">
          <Button
            disabled={isCreatingLoading ?? loading}
            isProcessing={isCreatingLoading ?? loading}
            size="md"
            type="submit"
            className="rounded-sm"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

