import { api } from "../baseConfig";

interface PayloadFilter {
  name?: string;
  email?: string;
  phone?: string;
  category: string;
  message: string;
}

export const supportSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    sendMessage: builder.mutation<any, PayloadFilter>({
      query: (data) => ({
        url: "/support",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Support"],
    }),
  }),
});

export const { useSendMessageMutation } = supportSlice;
