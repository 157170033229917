import { useAppSelector } from "../../lib/hook";
import Breadcrumb from "../../components/Breadcrumb";
import CustomPagination from "../../components/CustomPagination";
import { useState } from "react";
import { usePurReturnQuery } from "../../redux/queries/stock";
import utills from "../../lib/functions";
import ImageView from "../../components/ImageView";

const ReturnHistory = () => {
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    company_uuid: defaultBranchUUid,
    limit: "0,10",
  });
  const { data: returns, isLoading } = usePurReturnQuery(filters);

  return (
    <div>
      <Breadcrumb title="Purchase Return History" />

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={returns?.data}
          total={returns?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Product
                </th>
                <th scope="col" className="px-6 py-3">
                  Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {returns?.data?.map((item) => (
                <tr
                  key={item.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="p-4">
                    <ImageView className="w-8 h-8" url={utills._default_img} />
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {item.item.name}
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {item.item.quantity}
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {utills._figure_format(Number(item.price))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CustomPagination>
      </div>
    </div>
  );
};

export default ReturnHistory;
