import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-responsive-modal';
import utills from '../../lib/functions';

interface IPrintPurchaseModal {
    data?: IPurchaseHistory
    isOpen: boolean;
    close: () => void;
}


const PurchaseDoc = (props: IPrintPurchaseModal) => {
    const elRef = useRef(null);
    const { close, isOpen, data } = props;
    const [totalItems, setTotalItems] = useState(0)
  
    useEffect(()=> {
        console.log(data)
    },[isOpen])
    return (
      <Modal
        open={isOpen}
        onClose={close}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        showCloseIcon={false}
      >
        <div className="bg-white p-5 w-4/5 min-w-[800px]">
          <div className="flex justify-between items-center">
            <div></div>
            {/* <Button className="opacity-0" size="xs" onClick={(e: unknown) => handlePrint(e)}>
              <IoPrint className="mr-2 h-4 w-4" />
              Print page
            </Button> */}
            <button onClick={close}>
              <FaTimes />
            </button>
          </div>
          
          <div className=" p-5" ref={elRef}>
      <div className="text-center border-b border-black pb-2">
        <div className="text-[21px] font-bold">{data?.buyer.name}</div>
        <div className="text-xs">{data?.buyer.address.name}</div>
        <div className="text-xs">
          <span>Tel: {data?.buyer.phone}</span>{" "}
          <span>Email: {data?.buyer.email}</span>{" "}
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <div className="flex gap-2 text-xs">
          <span className="font-bold">TO:</span>
          <div>
            <div className="font-bold uppercase">
              {data?.buyer.name}
            </div>
            <div>{data?.buyer.address.name}</div>
            <div>{data?.buyer.email}</div>
          </div>
        </div>
        <div className="text-[10px] font-bold">
          <div className="flex">
            <span className="w-24">
              INVOICE ID:
            </span>{" "}
            <span>#{data?.invoice_id}</span>
          </div>
          <div className="flex">
            <span className="w-24">DATE:</span>{" "}
            <span>{moment(data?.purchase_date).format("LLL")}</span>
          </div>
          <div className="flex">
            <span className="w-24">SERVED BY:</span> <span>-</span>
          </div>
        </div>
      </div>

      {/* Body of the purchase */}

      <div className="mt-4">
        <table className="w-full text-xs">
          <thead className="w-full bg-black/80 text-white text-xs">
            <tr>
              <th className="px-2 text-start">QTY</th>
              <th className="text-start">NAME</th>
              <th className="text-start">RATE</th>
              <th className="text-start">VALUE</th>
            </tr>
          </thead>
          <tbody>
            {data?.items.map((item) => (
                <tr key={item.uuid} className="border-b border-black">
                  <td className="px-2 py-1">
                    {item.quantity}
                  </td>
                  <td className="font-semibold">{item.item.name}</td>
                  <td>{utills._currency_format(Number(item.price), "NGN")}</td>
                  <td>
                    {utills._currency_format(
                      Number(item.price) * item.quantity,
                      "NGN"
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="text-xs flex gap-2 font-semibold mt-3">
          <span>
            Delivery Fee:{" "}
            {utills._currency_format(0, "NGN")}
          </span>
          <span>
            Total Due: {utills._currency_format(data?.total_cost ?? 0, "NGN")}
          </span>
          <span>
            Total Paid:{" "}
            {utills._currency_format(data?.amount_paid ?? 0, "NGN")}
          </span>
          <span>
            Balance:{" "}
            {utills._currency_format(
              (data?.total_cost ?? 0) - (data?.amount_paid ?? 0),
              "NGN"
            )}
          </span>
        </div>
      </div>

      <div className="mt-10 mb-5">
        <div className="text-xs italic border-t text-center">
          Thanks for your patronage!!!
        </div>
      </div>
    </div>
        </div>
      </Modal>
    );
  };

export default PurchaseDoc