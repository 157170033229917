import JsBarcode from "jsbarcode";
import { useEffect } from "react";
// import Barcode from 'react-barcode';

const BarcodeSvg = ({
  value,
  className,
}: {
  value: string;
  className?: string;
}) => {
  useEffect(() => {
    JsBarcode("#barcode", value, {
      format: "CODE128",
      displayValue: true,
      fontSize: 13
    });
  }, [value]);

  return <svg id="barcode" className={className}></svg>;

};

export default BarcodeSvg;
