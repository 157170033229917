import { api } from "../baseConfig";

interface PayloadFilter {
  limit?: string;
  name?: string;
  status?: number | string;
  company?: string;
  category?: string;
  user?: string;
  start_date?: string;
  end_date?: string;
  cheque_no?: number | string;
  payment_uuid?: string;
  date?: string;
  order_company_uuid?: string;
  cheque_post_date?: string;
  gateway?: string;
  other_party?: string;
}

interface Attendance {
  action: string;
  created_at: string;
  desc: string;
  duration: number;
  id: number;
  staff: { name: string };
}

interface AttendanceResponse {
  count: number;
  data: Attendance[];
  status: number;
}

export const attendanceSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getAttendance: builder.query<AttendanceResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/v2/staff/attendance`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Attendance"],
    }),
  }),
});

export const { useGetAttendanceQuery } = attendanceSlice;
