import React from "react";
import { useGetOrdersQuery } from "../../redux/queries/order";
import { Spinner } from "flowbite-react";
import emptyIcon from "../../assets/empty.png";

interface UsersCard {
  back: () => void;
  user: Branch;
  type: string;
}

const CustomersOrder = (props: UsersCard) => {
  const { user } = props;
  const { data: orders, isLoading } = useGetOrdersQuery({
    company: user.uuid,
    verified: 1,
    limit: "0,20",
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center pt-5">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <div className="bg-white dark:bg-gray-800 p-2">
          <span className="font-semibold text-[13px] dark:text-gray-200">
            Company Orders
          </span>
        </div>

        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Total
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
            </tr>
          </thead>
        </table>
        {orders?.status === 404 || orders?.count === 0 && (
          <div className="flex items-center justify-center my-5">
            <div className="flex flex-col items-center">
              <img src={emptyIcon} alt="empty record" className="w-20" />
              <span className="dark:text-gray-400 mt-2 text-xs">
                No record found
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomersOrder;
