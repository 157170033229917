import { Button, Label, Select, TextInput } from "flowbite-react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import CustomSelect from "../../components/CustomSelect";
import { useGetBanksQuery } from "../../redux/queries/Payments";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useGetCountriesQuery } from "../../redux/queries/settings";
import {
  useGetCitiesQuery,
  useGetStatesQuery,
} from "../../redux/queries/location";
import { useAppSelector } from "../../lib/hook";
import { useCreateDVAMutation, useGetDVAQuery } from "../../redux/queries/dva";

interface IFormInput {
  first_name: string;
  last_name: string;
  bvn: string;
  countryName: string;
  state_id: string;
  city: string;
}

const schema = yup.object().shape({
  first_name: yup.string().required("First name is a required field"),
  last_name: yup.string().required("Last name is a required field"),
  bvn: yup.string().required("BVN is a required field"),
  countryName: yup.string().required("Country is a required field"),
  state_id: yup.string().required("State is a required field"),
  city: yup.string().required("City is a required field"),
});

const CompanyDVA = () => {
  const { user, defaultBranchUUid } = useAppSelector(
    (state) => state.appUserConfig
  );
  const { data: banks } = useGetBanksQuery({});
  const { data: countries } = useGetCountriesQuery();
  const [searchKey, setSearchKey] = useState("");
  const [bankData, setBankData] = useState<
    { acc_no?: string; bank_code?: string } | undefined
  >();
  const [countryArr, setCountryArr] = useState<any>([]);
  const [countryName, setCountryName] = useState<any>("");
  const [stateId, setStateId] = useState<any>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });
  useGetDVAQuery({
    company_uuid: defaultBranchUUid,
    status: 3,
  });
  // console.log(data);

  const [createDVA, { isLoading }] = useCreateDVAMutation();

  useEffect(() => {
    const getCountry = countries?.data?.filter(
      (country) => country?.name === countryName
    );
    setCountryArr(getCountry);
  }, [countries?.data, countryName]);

  const { data: states } = useGetStatesQuery({
    country_id: countryArr ? countryArr[0]?.id : null,
  });

  const { data: cities } = useGetCitiesQuery({
    country_id: countryArr ? countryArr[0]?.id : null,
    state_id: stateId,
  });

  const onSubmit = async (data: IFormInput) => {
    const { countryName, state_id, city, ...rest } = data;
    const getCountry = countries?.data?.filter(
      (country) => country?.name === countryName
    );
    const getCity = cities?.data?.filter((cityinn) => cityinn?.name === city);
    if (!bankData?.acc_no?.length || !bankData?.bank_code?.length)
      toast.error("Add bank details!!!");
    else {
      const payload = {
        ...rest,
        country_id: getCountry ? getCountry[0]?.id : null,
        state_id: Number(state_id),
        city_id: getCity ? getCity[0]?.id : null,
        user: user?.uuid,
        company_uuid: defaultBranchUUid,
        acc_details: {
          acc_no: bankData?.acc_no,
          bank_code: bankData?.bank_code,
        },
      };
      const res = await createDVA(payload);
      if ("data" in res) {
        if (res.data.status === 200 || res.data.status === 100) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.data[0]);
        }
      }
    }
  };

  // const intialStatesArr = states?.data?.filter(
  //   (item) => item.id === user?.state_id
  // );

  // const stateName = intialStatesArr?.length ? intialStatesArr[0]?.name : "";

  return (
    <div className="flex items-center justify-center mt-10">
      <div className="xl:max-w-xl w-full px-2 xl:px-0">
        <form className="p-3 pb-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-1">
            <div
              className="grid grid-cols-2 gap-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Label value="First Name" />
                <TextInput
                  sizing="sm"
                  type="text"
                  //disabled={isLoading}
                  placeholder="First name"
                  {...register("first_name")}
                  color={errors?.first_name ? "failure" : "gray"}
                  helperText={
                    errors.first_name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.first_name?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Last Name" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Last name"
                  {...register("last_name")}
                  color={errors?.last_name ? "failure" : "gray"}
                  helperText={
                    errors.last_name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.last_name?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div className="max-w-sm">
                <Label value="Select Bank" />
                <CustomSelect
                  isClearable={false}
                  onSelect={(val) => {
                    setBankData((prev) => ({ ...prev, bank_code: val.value }));
                  }}
                  options={
                    banks?.data
                      .filter((x) =>
                        x.name.toLowerCase().includes(searchKey.toLowerCase())
                      )
                      .map((item) => ({
                        label: item.name,
                        value: item.code,
                      })) ?? []
                  }
                  disabled={isLoading}
                  onSearch={(text) => setSearchKey(text)}
                  placeholder="Banks"
                  inputClasses="h-[35px]"
                />
              </div>
              <div>
                <Label value="Account No" />
                <TextInput
                  sizing="sm"
                  type="number"
                  //disabled={isLoading}
                  placeholder="Account No"
                  value={bankData?.acc_no}
                  onChange={(e) =>
                    setBankData((prev) => ({ ...prev, acc_no: e.target.value }))
                  }
                />
              </div>
              <div>
                <Label value="BVN" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="BVN"
                  {...register("bvn")}
                  color={errors?.bvn ? "failure" : "gray"}
                  helperText={
                    errors.bvn && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.bvn?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Country" />
                <Select
                  sizing="sm"
                  {...register("countryName", {
                    onChange: (e) => {
                      setCountryName(e.target.value);
                    },
                  })}
                  disabled={isLoading}
                  color={errors?.countryName ? "failure" : "gray"}
                  helperText={
                    errors.countryName && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.countryName?.message}!
                      </span>
                    )
                  }
                >
                  {/* <option value={user?.country?.name} hidden disabled>
                    {user?.country?.name}
                  </option> */}
                  <option value="" hidden>
                    Select Country
                  </option>

                  {countries?.data?.map((country) => (
                    <option key={country?.id} value={country?.name}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <Label value="State" />
                <Select
                  sizing="sm"
                  {...register("state_id", {
                    onChange: (e) => {
                      setStateId(e.target.value);
                    },
                  })}
                  //disabled={isLoading}
                  color={errors?.state_id ? "failure" : "gray"}
                  helperText={
                    errors.state_id && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.state_id?.message}!
                      </span>
                    )
                  }
                >
                  {/* <option value={stateName} hidden disabled>
                    {stateName}
                  </option> */}

                  <option value="" hidden>
                    Select State
                  </option>

                  {states?.data?.map((state) => (
                    <option key={state?.id} value={state?.id}>
                      {state.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <Label value="City" />
                <Select sizing="sm" {...register("city")} disabled={isLoading}>
                  {/* <option value={user?.city?.name} hidden disabled>
                    {user?.city?.name}
                  </option> */}

                  <option value="" hidden>
                    Select City
                  </option>

                  {cities?.data?.map((cityinn: any) => (
                    <option key={cityinn?.id} value={cityinn?.name}>
                      {cityinn.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="mt-10 flex items-center justify-end">
            <Button
              disabled={isLoading}
              isProcessing={isLoading}
              size="xs"
              className="rounded-sm"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyDVA;
