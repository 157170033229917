import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
    company: string
}
const initialState: InitialState = {
    company: ""
};
const utillsSlice = createSlice({
  name: "APP UTILLS",
  initialState,
  reducers: {
    switchCompany: (state, action: PayloadAction<string>)=> {
        state.company = action.payload
    }
  },
});

export const { switchCompany } = utillsSlice.actions;
export default utillsSlice.reducer;
