import React from "react";
import ImageView from "../../components/ImageView";
import { Spinner } from "flowbite-react";
import emptyIcon from "../../assets/empty.png";
import { useAppSelector } from "../../lib/hook";
import { useGetUserCompaniesQuery } from "../../redux/queries/company";

const BranchTable = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);

  const { data: branches, isLoading } = useGetUserCompaniesQuery({
    user: user?.uuid,
  });

  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Branch name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Phone
              </th>
            </tr>
          </thead>
          <tbody>
            {branches?.data.map((item) => (
              <tr
                key={item.company.uuid}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td className="px-6">
                  <ImageView
                    url={item.company.avatar_link}
                    className="w-8 h-8"
                  />
                </td>
                <td className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white">
                  {item.company.name}
                </td>
                <td className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white">
                  {item.company.email}
                </td>
                <td className="px-6 py-4 font-medium truncate text-gray-900 whitespace-nowrap dark:text-white">
                  {item.company.phone}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading && (
          <div className="text-center h-9 mt-4">
            <Spinner />
          </div>
        )}
        {branches?.status === 404 && (
          <div className="flex items-center justify-center my-5">
            <div className="flex flex-col items-center">
              <img src={emptyIcon} alt="empty record" className="w-20" />
              <span className="dark:text-gray-400 mt-2 text-xs">
                No record found
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BranchTable;
