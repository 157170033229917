import { api } from "../baseConfig";

interface syncDataResponse {
  status: any;
}

export const offlineSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    syncData: builder.mutation<syncDataResponse, any>({
      query: (data) => ({
        url: "/synchronization",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["offline"],
    }),
  }),
});

export const { useSyncDataMutation } = offlineSlice;