import { Datepicker } from "flowbite-react";
import moment from "moment";

interface CustomDatePickerType {
  start: string;
  end: string;
  onStartChange: (date: string) => void;
  onEndChange: (date: string) => void;
}

const CustomDatePicker = ({
  start,
  end,
  onStartChange,
  onEndChange,
}: CustomDatePickerType) => {
  return (
    <div className="w-fit flex items-center border border-gray-200 dark:bg-gray-800 rounded-lg px-1 text-gray-700 dark:text-gray-400">
      <Datepicker
        value={moment(start).format("Do MMM, YYYY")}
        onSelectedDateChanged={(date: any) => onStartChange(date)}
        style={{
          borderWidth: "0",
          backgroundColor: "transparent",
          width: "150px",
          outline: "none",
          height: "30px",
          fontSize: "13px",
        }}
        icon={() => null}
        name="date"
        datepicker-format={"YYYY-MM-DD"}
      />
      <span className="text-xs ml-2">To</span>
      <Datepicker
        value={moment(end).format("Do MMM, YYYY")}
        onSelectedDateChanged={(date: any) => onEndChange(date)}
        icon={() => null}
        style={{
          borderWidth: "0",
          backgroundColor: "transparent",
          width: "150px",
          outline: "none",
          height: "30px",
          fontSize: "13px",
        }}
        name="date"
        datepicker-format={"YYYY-MM-DD"}
      />
    </div>
  );
};

export default CustomDatePicker;
