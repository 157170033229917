import { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { settingsNav } from "../../route/data";
import Profile from "./Profile";
import CompanyProfile from "./CompanyProfile";
import Store from "./Store";
import PinSetting from "./PinSetting";
import Performance from "./Performance";
import Permission from "./Permission";
import Loan from "./Loan";
import SyncData from "./SyncData";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import { Button, Label } from "flowbite-react";
import BranchSwitcher from "../../components/BranchSwitcher";
import { MdOutlineDone } from "react-icons/md";
import toast from "react-hot-toast";
import { setDefaultBranch, updateUserRecord } from "../../redux/slices/appUser";
import { useUpdateUserMutation } from "../../redux/queries/users";
import CompanyDVA from "./CompanyDVA";
import Schedule from "./Schedule";
import Address from "./Address";
import Categories from "./Categories";

const Settings = () => {
  const [tag, setTag] = useState(1);
  const { offline, defaultBranchUUid, user } = useAppSelector(
    (state) => state.appUserConfig
  );
  const hide = window.location.hostname === "pos.gohealthy.ng";
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const [branch, setBranch] = useState(defaultBranchUUid);
  const dispatch = useAppDispatch();

  const data = {
    name: user?.name,
    email: user?.email,
    phone: user?.phone,
    nin: user?.nin,
    religion: user?.religion,
    bvn: user?.bvn,
    status: user?.status.toString(),
    whatsapp: user?.whatsapp,
  };

  const onSubmit = async () => {
    const payload = {
      ...data,
      company: branch,
    };
    const res = await updateUser({ ...payload, uuid: user?.uuid });
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record Updated");
        dispatch(setDefaultBranch(res.data.data?.company?.uuid));
        dispatch(updateUserRecord(res.data.data));
        setTag(1);
      } else toast.error(`${res.data.data} - ${res.data.message as string}`);
    } else toast.error("Unable to update record");
  };

  return (
    <div>
      <Breadcrumb
        title="Settings"
        rightButton={
          <div className="flex items-center gap-2">
            <Label value="Switch Branch:" />
            <BranchSwitcher
              sizing="sm"
              defaultCompany={branch}
              onChange={(value) => setBranch(value)}
            />
            <Button
              size="sm"
              disabled={branch === defaultBranchUUid}
              type="button"
              isProcessing={isLoading}
              onClick={onSubmit}
            >
              <MdOutlineDone />
            </Button>
          </div>
        }
      />
      <div className="w-full flex gap-5">
        <div className="w-[20%] min-h-[600px] bg-white dark:bg-gray-700 text-gray-600 dark:text-white py-14">
          <div className="flex flex-col gap-5 ">
            {settingsNav.map((item) =>
              item.tag === 9 && !offline && hide ? null : (
                <div
                  onClick={() => setTag(item.tag)}
                  className={`py-1 pl-1 xl:pl-3 2xl:pl-5 border-r-4 flex items-center gap-3 font-medium cursor-pointer text-sm xl:text-base ${
                    tag === item.tag
                      ? "border-[#1f91b2] text-[#1f91b2]"
                      : "border-transparent"
                  }`}
                >
                  <item.icon />
                  <span>{item.name}</span>
                </div>
              )
            )}
          </div>
        </div>

        <div className="w-[80%] min-h-[600px] bg-white dark:bg-gray-700 dark:text-white">
          {tag === 1 ? (
            <Profile />
          ) : tag === 2 ? (
            <CompanyProfile />
          ) : tag === 3 ? (
            <CompanyDVA />
          ) : tag === 4 ? (
            <Schedule />
          ) : tag === 5 ? (
            <Address />
          ) : tag === 6 ? (
            <Store />
          ) : tag === 7 ? (
            <PinSetting />
          ) : tag === 8 ? (
            <Performance />
          ) : tag === 9 ? (
            <Permission />
          ) : tag === 10 ? (
            <Loan />
          ) : tag === 11 ? (
            <Categories />
          ) :tag === 12 ? (
            <SyncData />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Settings;
