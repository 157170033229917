import React, { useEffect, useState } from "react";
import { useGetSellingGroupsQuery } from "../../redux/queries/sellingGroup";
import { Button, Label, Select, TextInput, Tooltip } from "flowbite-react";
import { useAppSelector } from "../../lib/hook";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import { FaPlus } from "react-icons/fa";
import utills from "../../lib/functions";
import { IoIosRemoveCircleOutline } from "react-icons/io";

interface ISellingGroup {
    removeItem: (uuid: string)=> void;
  sellinggroup: ISellingG[] | [];
  updateSellingGroup: (uuid: string, markup: string, price: string) => void;
}
const SellingGroup = (props: ISellingGroup) => {
  const { sellinggroup, updateSellingGroup, removeItem } = props;
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters] = useState({
    limit: "0,10",
    company: defaultBranchUUid,
    name: "",
  });
  const { data: sellingGroups } = useGetSellingGroupsQuery(filters);
  const [isOpen, setIsOpen] = useState(false);
  const [group, setGroup] = useState({ uuid: "", price: "", markup: "" });

  const close = () => setIsOpen(false);

  const setGroupRecord = () => {
    updateSellingGroup(group.uuid, group.markup, group.price)
    setGroup({ uuid: "", price: "", markup: "" })
    close()
  };

  return (
    <div>
      <div className="mb-5">
        <span className="dark:text-gray-200 text-sm">Selling Groups</span>
        <div>
          {sellinggroup.map((item) => {
            const sgp = sellingGroups?.data.find(x=> x.uuid === item.uuid)
            return (
                <div key={item.uuid} className="flex items-center gap-5 dark:text-white mt-3 mb-2 text-xs font-semibold">
                <span>Name: {sgp?.name}</span>
                <span>Markup: {item.markup}</span>
                <span>
                  Price: {utills._currency_format(Number(item.price), "NGN")}
                </span>
                <button onClick={()=> removeItem(item.uuid)} className="dark:text-white"><IoIosRemoveCircleOutline /></button>
              </div>
            )
          })}
        </div>
        <Tooltip content="Add selling group">
          <Button className="mt-4" color="gray" onClick={() => setIsOpen(true)}>
            <FaPlus />
          </Button>
        </Tooltip>
      </div>
      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        open={isOpen}
        onClose={close}
      >
        <div className="w-[800px] rounded-t-lg">
          <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
            <span className="text-white text-sm font-samibold">
              Selling Group
            </span>
            <span className="text-white cursor-pointer" onClick={close}>
              <LiaTimesSolid />
            </span>
          </div>
          <div className="bg-white dark:bg-gray-800 p-5">
            <div className="grid grid-cols-3 gap-3">
              <div>
                <Label value="Select Group" />
                <Select
                  onChange={(e) => {
                    const sg = sellingGroups?.data.find(
                      (item) => item.uuid === e.target.value
                    );
                    const sgp = sellinggroup.find(
                      (item) => item.uuid === e.target.value
                    );
                    setGroup((prev) => ({
                      ...prev,
                      uuid: e.target.value,
                      markup: sg?.markup.toString() ?? "0",
                      price: sgp?.price.toString() ?? "0",
                    }));
                  }}
                >
                    <option>Select Group</option>
                  {sellingGroups?.data.map((item) => (
                    <option key={item.uuid} value={item.uuid}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <Label value="Markup" />
                <TextInput
                  type="number"
                  step="any"
                  value={group.markup}
                  onChange={(e) =>
                    setGroup((prev) => ({ ...prev, markup: e.target.value }))
                  }
                />
              </div>
              <div>
                <Label value="Price" />
                <TextInput
                  type="number"
                  step="any"
                  value={group.price}
                  onChange={(e) =>
                    setGroup((prev) => ({ ...prev, price: e.target.value }))
                  }
                />
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <Button onClick={setGroupRecord} size="xs">
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SellingGroup;
