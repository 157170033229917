import { api } from "../baseConfig";

interface PayloadFilter {
  limit?: string;
  name?: string;
  country_id?: number | string | undefined;
  state_id?: number | string | undefined;
  city_id?: string;
  staff_uuid?: string;
}

interface CitiesResponse {
  data: City[];
  count: number;
  status: number;
}

interface assignCity {
  city: { id: number; name: string };
  uuid: string;
}

interface assignCityResponse {
  data: assignCity[];
  count: number;
  status: number;
  message: string;
}

export const locationSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getCities: builder.query<CitiesResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/location/cities`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Location"],
    }),
    getStates: builder.query<CitiesResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/location/states`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Location"],
    }),
    getAssignedCity: builder.query<assignCityResponse, PayloadFilter>({
      query: (filters) => ({
        url: `/location/gettstaffassigntocity`,
        method: "GET",
        params: filters,
      }),
      providesTags: ["Location"],
    }),
    assignCity: builder.mutation<assignCityResponse, PayloadFilter>({
      query: (data) => ({
        url: "/location/assignstafftocity",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Location"],
    }),
  }),
});

export const {
  useGetCitiesQuery,
  useGetStatesQuery,
  useGetAssignedCityQuery,
  useAssignCityMutation,
} = locationSlice;
