import { Dropdown } from "flowbite-react";
import React from "react";

interface IMenu {
  title: string;
  icon: any;
  action: () => void;
}
interface ICustomDropdown {
  children?: JSX.Element;
  trigger: JSX.Element;
  menu: IMenu[] | [];
}
const CustomDropdown = (props: ICustomDropdown) => {
  const { children, trigger, menu } = props;
  return (
    <Dropdown label="Dropdown button" renderTrigger={() => trigger}>
      {children && <Dropdown.Header>{children}</Dropdown.Header>}
      {menu.map((item) => (
        <Dropdown.Item key={item.title} icon={item.icon} onClick={item.action}>
          {item.title}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

export default CustomDropdown;
