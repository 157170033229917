import React, { useState, useRef, memo } from "react";
import { LiaTimesSolid } from "react-icons/lia";
import ImageView from "../../components/ImageView";
import { Button, TextInput, Tooltip } from "flowbite-react";
import { FcSearch } from "react-icons/fc";
import { useGetProductsQuery } from "../../redux/queries/products";
import utills from "../../lib/functions";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import emptyIcon from "../../assets/empty.png";
import { useAddItemToShelfMutation } from "../../redux/queries/shelf";
import toast from "react-hot-toast";

interface IShelfItem {
  close: () => void;
  shelf: SingleShelf;
}
const ShelfItems = (props: IShelfItem) => {
  const searchField = useRef<HTMLInputElement>(null);
  const { close, shelf } = props;

  const [addItemsToShelf, { isLoading }] = useAddItemToShelfMutation();
  const [cartItem, setCartItem] = useState<Product[] | []>([]);
  const [searchKey, setSearchKey] = useState("");
  const [filters, setFilters] = useState({
    limit: "0,60",
    name: "",
  });
  // console.log(shelf)

  const { data: products, isSuccess } = useGetProductsQuery({
    ...filters,
    company: shelf.company,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchKey(value);
    setFilters((prev) => ({ ...prev, name: value }));
  };

  const handleSubmit = async () => {
    const items = cartItem.map((x) => ({ quantity: 0, uuid: x.uuid }));
    const payload = {
      items,
      shelf_uuid: shelf.uuid,
    };
    const res = await addItemsToShelf(payload);
    if ("data" in res) {
      toast.success(res.data.message ?? "Product added to shelf");
      close()
      setCartItem([]);
    } else toast.error("Unable to add product!!!");
  };

  return (
    <div className="w-[800px]">
      <div className="bg-[#167490] flex items-center px-3 justify-between h-10">
        <span className="text-white font-semibold text-sm">
          Add Product To {shelf.name}
        </span>
        <button className="text-white" onClick={close}>
          <LiaTimesSolid />
        </button>
      </div>

      <div className="bg-white dark:bg-gray-800 pb-3">
        <div className="flex items-center gap-3 p-3 mb-3">
          {/* <div>
            <BranchSwitcher
              onChange={(val) => setCompanyId(val)}
              styling={{ borderRadius: 3 }}
            />
          </div> */}
          <div className="flex-1">
            <TextInput
              ref={searchField}
              className="flex-1"
              sizing="sm"
              type="text"
              icon={FcSearch}
              onChange={handleChange}
              value={searchKey}
              placeholder="search..."
              style={{ borderRadius: 2 }}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex-1 p-2 h-[400px] overflow-auto">
            <div className="grid grid-cols-4 gap-3">
              {products?.data.map((item, index) => {
                const imgurl =
                  item.images_links[0] ===
                  "https://dev-assets.gohealthy.ng/items/"
                    ? utills._default_img
                    : item.images_links[0];
                return (
                  <div
                    tabIndex={index}
                    key={item.id}
                    className="rounded-lg cursor-pointer"
                    onClick={() => {
                      const prod = shelf.stocks.find(
                        (x) => x.item_uuid === item.uuid
                      );
                      if (prod) {
                        toast.error("Item already exist in shelf");
                        return;
                      }
                      const rec = cartItem.find((x) => x.uuid === item.uuid);
                      if (rec) {
                        toast.error("You have already picked this item");
                        return;
                      }
                      setCartItem((prev) => [...prev, item]);
                      utills._play_sound();
                      searchField.current?.focus();
                    }}
                  >
                    <div className="relative">
                      <div className="bg-yellow-500 absolute top-1 left-2 rounded-xs flex items-center justify-center px-1 rounded-sm">
                        <span className="text-[6px] text-white font-semibold">
                          {item.quantity} left
                        </span>
                      </div>
                      <div></div>
                      <div
                        className="w-full h-28 rounded-sm bg-white  border border-gray-300 dark:border-gray-600"
                        style={{
                          backgroundImage: `url(${imgurl})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      />
                    </div>
                    <div className="flex flex-col mt-2 w-full">
                      <span
                        className="text-[9px] font-semibold dark:text-white"
                        style={{ lineHeight: "11px" }}
                      >
                        {item.name}
                      </span>
                      <span className="text-[9px] dark:text-white mt-0">
                        {utills._currency_format(Number(item.price), "NGN")}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-[200px] flex flex-col justify-between h-[400px] px-2">
            <div className="flex-1 overflow-auto">
              {cartItem.length === 0 && (
                <div className="flex items-center justify-center my-5">
                  <div className="flex flex-col items-center">
                    <img src={emptyIcon} alt="empty record" className="w-20" />
                    <span className="dark:text-gray-400 mt-2 text-[9px]">
                      Select a product from the list
                    </span>
                  </div>
                </div>
              )}

              {cartItem.map((item) => {
                const imgurl =
                  item.images_links[0] ===
                  "https://dev-assets.gohealthy.ng/items/"
                    ? utills._default_img
                    : item.images_links[0];
                return (
                  <div
                    key={item.uuid}
                    className="flex items-center justify-between gap-2 border-b pb-2 mb-2 dark:border-gray-600"
                  >
                    <div className="flex items-center gap-2 w-[158px]">
                      <ImageView className="w-6 h-6" url={imgurl} />
                      <span className="text-[10px] truncate ... dark:text-gray-200">
                        <Tooltip content={item.name}>{item.name}</Tooltip>
                      </span>
                    </div>
                    <button
                      className="text-red-500"
                      onClick={() =>
                        setCartItem((prev) =>
                          prev.filter((cart) => cart.uuid !== item.uuid)
                        )
                      }
                    >
                      <IoMdRemoveCircleOutline />
                    </button>
                  </div>
                );
              })}
            </div>
            <div>
              <Button
                disabled={isLoading}
                isProcessing={isLoading}
                size="xs"
                className="rounded-sm w-full"
                onClick={handleSubmit}
              >
                Add Products
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ShelfItems);
