import { api } from "../baseConfig";

interface Payload {
  name: string;
  company: string;
  permissions: string[];
  status?: number;
  uuid?: string
}

interface IPerm {
  company?: string;
  name?: string;
}

interface ISingleResponse {
  data: IPermission;
  status: number;
}
export const permissionSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    createPermission: builder.mutation<any, Payload>({
      query: (data) => ({
        url: "/permissions",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Permissions"],
    }),
    updatePermission: builder.mutation<ISingleResponse, Payload>({
      query: (data) => ({
        url: `/permissions/${data.uuid}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Permissions"],
    }),
    deletePermission: builder.mutation<any, Payload>({
      query: (id) => ({
        url: `/permissions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Permissions"],
    }),
    getPermissions: builder.query<IPermissionesponse, IPerm>({
      query: (data) => ({
        url: "/permissions",
        method: "GET",
        params: { ...data },
      }),
      providesTags: ["Permissions"],
    }),
  }),
});

export const {
  useCreatePermissionMutation,
  useGetPermissionsQuery,
  useDeletePermissionMutation,
  useUpdatePermissionMutation,
} = permissionSlice;
