import React, { useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Badge,
  Banner,
  Button,
  Label,
  Spinner,
  Table,
  TextInput,
} from "flowbite-react";
import { BiSolidSortAlt } from "react-icons/bi";
import { IoIosAdd } from "react-icons/io";
import {
  useCreateStockCountMutation,
  useDeleteStockMutation,
  useGetStockDataListQuery,
} from "../../redux/queries/stock";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import CustomDropdown from "../../components/CustomDropdown";
import { CiCircleMore } from "react-icons/ci";
import { FaRegEdit } from "react-icons/fa";
import { IoTrashOutline } from "react-icons/io5";
import emptyIcon from "../../assets/empty.png";
import { useNavigate } from "react-router-dom";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import toast from "react-hot-toast";
import BranchSwitcher from "../../components/BranchSwitcher";
import { useAppSelector } from "../../lib/hook";
import moment from "moment";

const StockList = () => {
  const navigate = useNavigate();
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [isStockOpen, setIsStockOpen] = useState(false);
  const [filters, setFilters] = useState({ company: defaultBranchUUid });
  const { data: stockLists, isLoading } = useGetStockDataListQuery({
    ...filters,
  });
  const [deleteStock] = useDeleteStockMutation();

  const deleteHandler = (id: string) => {
    const action = window.confirm("Are you sure you want to delete");
    if (action)
      utills._asynchronous_toast(
        deleteStock,
        "Record Deleted",
        "Unable to delete",
        id
      );
  };

  return (
    <div>
      <Breadcrumb
        title="Stock Count List"
        rightButton={
          <Button size="xs" onClick={() => setIsStockOpen(true)}>
            <IoIosAdd className="mr-2 h-5 w-5" /> Create Stock
          </Button>
        }
      />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Stock List.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                See and manage the list of all the stocks taken and also create
                new stock.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <BiSolidSortAlt className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="grid grid-cols-4 bg-white px-3 py-5 mb-5 rounded-sm dark:bg-gray-800">
        <div>
          <Label value="Branch" className="mb-2" />
          <BranchSwitcher
            sizing="md"
            defaultCompany={filters.company}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, company: value }))
            }
          />
        </div>
      </div>

      <div className="">
        <Table hoverable className="">
          <Table.Head>
            <Table.HeadCell className="w-8">#</Table.HeadCell>
            <Table.HeadCell>Stock Title</Table.HeadCell>
            <Table.HeadCell>Start Date</Table.HeadCell>
            <Table.HeadCell>End Date</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell>
              Action
              <span className="sr-only">Edit</span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {stockLists?.data.map((stock) => (
              <Table.Row
                onClick={() => navigate(`/stock-count/${stock.uuid}`)}
                key={stock.uuid}
                className="bg-white dark:border-gray-700 dark:bg-gray-800"
              >
                <Table.Cell>
                  <ImageView url={utills._default_img} className="w-6 h-6" />
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {stock.name}
                </Table.Cell>
                <Table.Cell className="font-medium text-gray-900 dark:text-white">
                  {moment(stock.created_at).format("LLL")}
                </Table.Cell>
                <Table.Cell className="font-medium text-gray-900 dark:text-white">
                  {moment(stock.updated_at).format("LLL")}
                </Table.Cell>
                <Table.Cell>
                  <Badge
                    className="w-max"
                    color={stock.verified ? "success" : "warning"}
                  >
                    {stock.verified ? "completed" : "pending"}
                  </Badge>
                </Table.Cell>
                <Table.Cell onClick={(e) => e.stopPropagation()}>
                  <CustomDropdown
                    trigger={
                      <div className="">
                        <CiCircleMore className="text-2xl" />
                      </div>
                    }
                    menu={[
                      {
                        icon: FaRegEdit,
                        title: "Edit",
                        action: () => console.log(""),
                      },
                      {
                        icon: IoTrashOutline,
                        title: "Delete",
                        action: () => deleteHandler(stock.uuid),
                      },
                    ]}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {isLoading && (
          <div className="flex items-center justify-center gap-2 h-20 mt-6">
            <Spinner />
            <Spinner />
            <Spinner />
          </div>
        )}
        {stockLists?.status === 404 && (
          <div className="flex items-center justify-center my-5">
            <div className="flex flex-col items-center">
              <img src={emptyIcon} alt="empty record" className="w-20" />
              <span className="dark:text-gray-400 mt-2 text-xs">No record</span>
            </div>
          </div>
        )}
      </div>

      <Modal
        open={isStockOpen}
        showCloseIcon={false}
        onClose={() => setIsStockOpen(false)}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
      >
        <CreateStock
          defaultBranchUUid={defaultBranchUUid}
          close={() => setIsStockOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default StockList;

interface ICreateStock {
  defaultBranchUUid: string;
  close: () => void;
}
const CreateStock = (props: ICreateStock) => {
  const { defaultBranchUUid, close } = props;
  const [entries, setEntries] = useState({
    company: defaultBranchUUid,
    name: "",
  });
  const [createStockCount, { isLoading }] = useCreateStockCountMutation();

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await createStockCount(entries);
    if ("data" in res) {
      toast.success("Stock Created");
      close();
    } else toast.error("Unable to create stock");
  };
  return (
    <form className="w-[350px]" onSubmit={submit}>
      <div className="bg-[#167490] flex items-center px-3 justify-between h-10">
        <span className="text-white font-semibold text-sm">Create Stock</span>
        <button type="button" className="text-white" onClick={close}>
          <LiaTimesSolid />
        </button>
      </div>
      <div className="px-3 py-5 flex flex-col gap-5 bg-white dark:bg-gray-800">
        <div>
          <Label value="Select Branch" />
          <BranchSwitcher
            onChange={(val) =>
              setEntries((prev) => ({ ...prev, company: val }))
            }
          />
        </div>

        <div>
          <Label value="Stock Name" />
          <TextInput
            required
            sizing="sm"
            type="text"
            onChange={(e) =>
              setEntries((prev) => ({ ...prev, name: e.target.value }))
            }
          />
        </div>
        <div className="mt-3">
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            size="xs"
            type="submit"
            className="rounded-md w-full"
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};
