import { Banner } from "flowbite-react";
import { GrSecure } from "react-icons/gr";

const Permission = () => {
  return (
    <div className="mt-10">
      <Banner className="mb-5">
        <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
          <div className="mb-4 md:mb-0 md:mr-4">
            <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
              My Permissions.
            </h2>
            <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
              See all user permissions.
            </p>
          </div>
          <div className="flex flex-shrink-0 items-center">
            <GrSecure className="text-5xl text-gray-500 dark:text-white" />
          </div>
        </div>
      </Banner>
    </div>
  );
};

export default Permission;
