import { FormEvent, useState } from "react";
import { Label, TextInput, Checkbox, Button, Blockquote, Select } from "flowbite-react";
import { HiMail } from "react-icons/hi";
import { RiLockPasswordFill } from "react-icons/ri";
import { useAuthMutation } from "../../redux/queries/auth";
import { useAppDispatch } from "../../lib/hook";
import { registerUser } from "../../redux/slices/appUser";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [authUser, { isLoading }] = useAuthMutation();
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [fields, setFields] = useState<{ username: string, password: string, type: 'manufacturer' | 'pharmacy'}>({ username: "", password: "", type: "manufacturer" });
  const [error, setError] = useState<string>("");

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    if (!fields.username || !fields.password) {
      flashError("Please enter your email and password!!!");
      return;
    }
    const res = await authUser(fields);
    if ("data" in res) {
      if (res.data.status === 200) {
        const { token, data } = res.data;
        dispatch(registerUser({ token, user: data }));
        navigate("/dashboard");
      } else flashError("Invalid Credentials");
    } else flashError("Invalid Credentials");
  };

  const flashError = (msg: string) => {
    setError(msg);
    setTimeout(() => {
      setError("");
    }, 5000);
  };
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-950 text-gray-900 flex justify-center">
      <div className="max-w-screen-xl m-0 sm:m-10 bg-white dark:bg-gray-800 shadow sm:rounded-lg flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-5/12 w-full p-6 sm:p-12">
          <div>
            <img
              width={50}
              src="https://cdn.pixabay.com/photo/2016/09/16/19/15/drug-bottle-1674888_960_720.png"
              alt="logo"
              className="mx-auto rounded-full"
            />
          </div>
          <div className="mt-3 flex flex-col items-center">
            <h1 className="text-2xl xl:text-3xl font-extrabold dark:text-gray-300">
              GoHealthy
            </h1>
            <div className="w-full flex-1 mt-3">
              <div className="h-10">
                {error && (
                  <div
                    id="alert-border-2"
                    className="flex items-center py-2 px-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-gray-800 dark:border-red-800"
                    role="alert"
                  >
                    <BsFillInfoCircleFill className="text-red-700" />
                    <div className="ms-3 text-sm font-medium">{error}</div>
                  </div>
                )}
              </div>

              <div className="mb-4 border-b text-center">
                <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white dark:bg-gray-800 dark:text-gray-200 transform translate-y-1/2">
                  Login to your account
                </div>
              </div>
              <form onSubmit={submit}>
                <div className="mx-auto lg:max-w-xs">
                  <TextInput
                    disabled={isLoading}
                    icon={HiMail}
                    className="w-full "
                    type="email"
                    placeholder="Email"
                    onChange={(e) =>
                      setFields((prev) => ({
                        ...prev,
                        username: e.target.value,
                      }))
                    }
                  />
                  <TextInput
                    disabled={isLoading}
                    icon={RiLockPasswordFill}
                    className="w-full  mt-5"
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) =>
                      setFields((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                  />

                  <div className="flex items-center gap-2 mt-3">
                    <Checkbox
                      id="remember"
                      onChange={() => setIsPasswordVisible((prev) => !prev)}
                    />
                    <Label className="text-xs" htmlFor="remember">
                      Show Password
                    </Label>
                  </div>
                  <Select className="my-5"
                  value={fields.type}
                  onChange={(e) => setFields((prev) => ({
                    ...prev,
                    type: e.target.value as "pharmacy" | "manufacturer"
                  }))}
                  >
                    <option value="manufacturer">Manufacturer</option>
                    <option value="pharmacy">Pharmacy</option>
                  </Select>
                  <Button
                    disabled={isLoading}
                    isProcessing={isLoading}
                    className="mt-5 w-full"
                    type="submit"
                  >
                    <span className="">Login</span>
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="flex-1 text-center hidden lg:flex _custom_bg lg:items-center lg:justify-center">
          <div className="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat">
            <div className="">
              <h4 className="mb-4 text-white dark:text-gray-200 text-md">
                We are more than just a company
              </h4>
              <Blockquote className="text-white dark:text-gray-400 text-sm font-semibold">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
