import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import {
  Label,
  TextInput,
  Select,
  Textarea,
  Button,
  Spinner,
  Alert,
} from "flowbite-react";
import { useForm } from "react-hook-form";
import {
  useGetCategoriesQuery,
  useGetSettingsQuery,
} from "../../redux/queries/settings";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useGetProductQuery,
  useLazyGetProductQuery,
  useUpdateProductMutation,
} from "../../redux/queries/products";
import { useAppSelector } from "../../lib/hook";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { HiInformationCircle } from "react-icons/hi";
import { VarianFields } from "./AddProducts";
import Detailscrumb from "../../components/DetailsCrumb";
import { IoIosRemoveCircle, IoMdArrowRoundBack } from "react-icons/io";
import { GoTrash } from "react-icons/go";
import { FaPlus } from "react-icons/fa";
import { useUploadFileMutation } from "../../redux/queries/upload";
import utills from "../../lib/functions";
import SellingGroup from "./SellingGroup";
import { CiBarcode } from "react-icons/ci";
import { get, isEqual } from "lodash";
import ProductSearch from "../../components/ProductSearch";
import ImageView from "../../components/ImageView";

interface IFormInput {
  name: string;
  category: string;
  discount?: string;
  price: string;
  company: string;
  reorder_level?: string;
  reorder_quantity?: string;
  reorder_quantity_limit?: string;
  pricein_dollar?: string;
  selling_price?: string;
  batch_no?: string;
  quantity: string;
  status?: string;
  barcode?: string;
  min?: string;
  markup?: string;
  max?: string;
  description?: string;
  cashback?: string;
  stock?: string;
}

const schema = yup.object().shape({
  name: yup.string().required("Product name is a required field"),
  category: yup.string().required("Product category is a required field"),
  price: yup.string().required("Cost price is a required field"),
  selling_price: yup.string(),
  company: yup.string().required("Branch is a required field"),
  batch_no: yup.string(),
  quantity: yup.string().required("Price is a required field"),
  status: yup.string(),
  reorder_level: yup.string(),
  reorder_quantity: yup.string(),
  reorder_quantity_limit: yup.string(),
  markup: yup.string(),
  barcode: yup.string(),
  min: yup.string(),
  max: yup.string(),
  cashback: yup.string(),
  stock: yup.string(),
  description: yup.string(),
});
const EditProduct = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data: product,
    isSuccess,
    isLoading: loading,
  } = useGetProductQuery(id || "");

  const { user } = useAppSelector((state) => state.appUserConfig);
  const [updatePoduct, { isLoading }] = useUpdateProductMutation();
  const [getProduct] = useLazyGetProductQuery();
  const [imgs, setImgs] = useState<string[]>([]);
  const [imgLinks, setImgLinks] = useState<any>([]);
  const [flat, setFlat] = useState(0);
  const [variants, setVariants] = useState([
    { name: "", quantity: "", is_required: false },
  ]);
  const [sellingGroup, setSellingGroup] = useState<ISellingG[] | []>([]);
  const [crossSelling, setCrossSelling] = useState<Product[]>([]);
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });
  const { data: categories } = useGetCategoriesQuery({
    type: user?.company.type,
    status: 1,
  });

  const getItemRequired = async (ids: string[]) => {
    for (const uuid of ids) {
      const res = await getProduct(uuid);
      if ("data" in res) {
        if (res.data?.status === 200) {
          const product = res.data?.data;
          setCrossSelling((prev) => [...prev, product]);
        }
      }
    }
  };
  {
  }

  const onSubmit = async (data: IFormInput) => {
    const payload = {
      ...data,
      company: user?.company.uuid,
      type: user?.company.type,
      images: "",
      variants: variants,
      flat,
      // barcode: Number(data.barcode)
      selling_group: sellingGroup.filter((item) => Number(item.price) > 0),
    };
    if (imgs.length > 0) payload.images = imgs.join(",");

    const item_required = crossSelling.map((x) => x.uuid);
    const { selling_group, ...rest } = payload;
    const res = (await updatePoduct({
      id: id ?? "",
      data: { ...payload, item_required },
    })) as CreatProductResponse;
    if (res?.data?.status !== 200) {
      toast.error(res?.data.error ?? "Error");
      toast.error(res?.data?.message ?? "Error");
      toast.error(res?.data?.data?.join(",") ?? "Error");
    } else {
      toast.success("Product updated successfully");
      reset();
    }
  };

  useEffect(() => {
    if (isSuccess && product.status === 200) {
      const { data } = product;
      // console.log("----", data)
      setValue("name", data.name);
      setValue("category", data.category.uuid);
      setValue("discount", data.discount.toString());
      setValue("price", data.price);
      setValue("reorder_level", data.reorder_level?.toString());
      setValue("reorder_quantity", data.reorder_quantity?.toString());
      setValue(
        "reorder_quantity_limit",
        data.reorder_quantity_limit?.toString()
      );
      if (data.item_required) {
        getItemRequired(data.item_required);
      }
      setValue("company", data.company.uuid);
      setValue("batch_no", data.batch_no);
      setValue("quantity", data.quantity.toString());
      setValue("status", data.status.toString());
      setValue("min", data.min.toString());
      setValue("markup", data.markup.toString());
      setValue("barcode", data.barcode);
      setValue("max", data.max.toString());
      setValue("stock", data.stock.toString());
      setValue("cashback", data.cashback.toString());
      setValue("description", data.description);
      setImgLinks(utills._remove_invalid_link(data.images_links));
      setImgs(data.images.split(",").filter((item) => item));
      if (data.flat === 0)
        setValue("selling_price", data.selling_price.toString());
      if (data.variants.length > 0) setVariants([...data.variants]);
      else setVariants([{ name: "pack", quantity: "1", is_required: false }]);
      setSellingGroup(
        data.selling_group.map((item) => ({
          uuid: item.uuid,
          markup: item.markup.toString(),
          price: item.price.toString(),
        }))
      );
      setFlat(data.flat);
    }
  }, [isSuccess, product, setValue]);

  const formState = watch();

  useEffect(() => {
    if (formState.markup && formState.price && flat === 0) {
      const selling =
        (Number(formState.markup) / 100) * Number(formState.price);
      setValue(
        "selling_price",
        (selling + Number(formState.price)).toFixed(0).toString()
      );
    } else {
      const selling = Number(formState.markup) + Number(formState.price);
      setValue("selling_price", selling.toFixed(0).toString());
    }
  }, [formState.markup, formState.price, flat, setValue]);

  const hasChanges = () =>
    !isEqual(formState.name, product?.data?.name) ||
    !isEqual(formState.barcode, product?.data?.barcode) ||
    !isEqual(formState.cashback, product?.data?.cashback.toString()) ||
    !isEqual(formState.batch_no, product?.data?.batch_no) ||
    !isEqual(formState.category, product?.data?.category?.uuid) ||
    !isEqual(formState.company, product?.data?.company?.uuid) ||
    !isEqual(formState.description, product?.data?.description) ||
    !isEqual(formState.markup, product?.data?.markup.toString()) ||
    !isEqual(formState.max, product?.data?.max.toString()) ||
    !isEqual(formState.min, product?.data?.min.toString()) ||
    !isEqual(formState.price, product?.data?.price) ||
    !isEqual(formState.quantity, product?.data?.quantity.toString()) ||
    !isEqual(
      formState.reorder_level,
      product?.data?.reorder_level?.toString()
    ) ||
    !isEqual(
      formState.reorder_quantity,
      product?.data?.reorder_quantity?.toString()
    ) ||
    !isEqual(
      formState.reorder_quantity_limit,
      product?.data?.reorder_quantity_limit?.toString()
    ) ||
    !isEqual(formState.discount, product?.data?.discount.toString()) ||
    !isEqual(formState.stock, product?.data?.stock.toString()) ||
    !isEqual(formState.status, product?.data?.status.toString()) ||
    !isEqual(
      imgs,
      product?.data?.images.split(",").filter((item) => item)
    ) ||
    !isEqual(
      imgLinks,
      utills._remove_invalid_link(product?.data.images_links)
    ) ||
    !isEqual(variants, product?.data.variants);

  if (loading) {
    return (
      <div className="text-center mt-10">
        <Spinner size="xl" />
      </div>
    );
  }

  if (product?.status === 404) {
    return (
      <div className="mt-10">
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">Info alert!</span> Change a few things
          up and try submitting again.
        </Alert>
      </div>
    );
  }
  return (
    <div>
      <Detailscrumb
        title="Update Product"
        leftButton={
          <button
            onClick={() => navigate(-1)}
            className="mr-3 inline-flex items-center justify-center rounded-md border border-gray-200 bg-white px-3 py-2 text-xs font-medium text-gray-900 hover:bg-gray-100 hover:text-cyan-700 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
          >
            <IoMdArrowRoundBack className="mr-2 h-4 w-4" />
            Back
          </button>
        }
      />
      <div className="mt-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white dark:bg-gray-800 rounded-lg">
            <div className="dark:bg-gray-700 rounded-t-lg p-2">
              <span className="dark:text-white">
                Update Product Information
              </span>
            </div>
            <div className="grid grid-cols-3 gap-4 p-4">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Product Name" />
                </div>
                <TextInput
                  disabled={isLoading}
                  id="name"
                  type="text"
                  placeholder="Product Name"
                  {...register("name")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="branch" value="Branch" />
                </div>

                <Select
                  disabled={isLoading}
                  {...register("company")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                >
                  {user?.branches.map((branch) => (
                    <option key={branch.uuid} value={branch.uuid}>
                      {branch.name}
                      {` ${branch.type !== "branch" ? " (Main branch)" : ""}`}
                    </option>
                  ))}
                </Select>
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="cat" value="Product Category" />
                </div>

                <Select
                  disabled={isLoading}
                  {...register("category")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                >
                  {categories?.data.map((cat) => (
                    <option value={cat.uuid} key={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="discount" value="Discount (NGN)" />
                </div>
                <TextInput
                  disabled={isLoading}
                  id="discount"
                  type="number"
                  step="any"
                  placeholder="Discount(NGN)"
                  {...register("discount")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="qty" value="Quantity" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("quantity")}
                  id="qty"
                  type="number"
                  step="any"
                  placeholder="Quantity"
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                />
              </div>

              {/* <div>
                <div className="mb-2 block">
                  <Label htmlFor="base_unit" value="Base Unit" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("base_unit")}
                  id="base_unit"
                  type="text"
                  placeholder="Base Unit"
                />
              </div> */}

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="stu" value="Status" />
                </div>

                <Select id="stu" {...register("status")} disabled={isLoading}>
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </Select>
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="markup" value="Unique Markup / Profit" />
                </div>
                <div className="flex items-center w-full">
                  <TextInput
                    disabled={isLoading}
                    className="flex-1"
                    id="markup"
                    style={{ borderRadius: "7px 0px 0px 7px" }}
                    type="number"
                    step="any"
                    placeholder="Unique Markup / Profit"
                    {...register("markup")}
                  />
                  <Select
                    id="prf"
                    onChange={(e) => setFlat(Number(e.target.value))}
                    value={flat}
                    style={{ borderRadius: "0px 7px 7px 0px" }}
                  >
                    <option value={0}>Markup</option>
                    <option value={1}>Profit</option>
                  </Select>
                </div>
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="pricen" value="Cost Price (₦)" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("price")}
                  id="pricen"
                  type="number"
                  step="any"
                  placeholder="Cost Price (₦)"
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="pricen" value="Selling Price (₦)" />
                </div>
                <TextInput
                  disabled
                  {...register("selling_price")}
                  id="pricen"
                  type="number"
                  step="any"
                  placeholder="Price (₦)"
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="min" value="Min" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("min")}
                  id="min"
                  type="number"
                  step="any"
                  placeholder="Min"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="max" value="Max" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("max")}
                  id="max"
                  type="number"
                  step="any"
                  placeholder="Max"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="stock" value="Stock Limit" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("stock")}
                  id="stock"
                  type="number"
                  step="any"
                  placeholder="Stock Limit"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="reward" value="Cash Back" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("cashback")}
                  id="reward"
                  type="number"
                  step="any"
                  placeholder="Cash Back"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="barcode" value="Barcode" />
                </div>
                <div className="flex items-center w-full">
                  <TextInput
                    disabled={isLoading}
                    {...register("barcode")}
                    id="barcode_input"
                    type="text"
                    className="flex-1"
                    style={{ borderRadius: "7px 0px 0px 7px" }}
                    placeholder="Barcode"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const code = utills._generate_barcode();
                      setValue("barcode", code.toString());
                    }}
                    className="bg-[#167490] h-[42px] px-2 text-sm text-white font-semibold"
                    style={{ borderRadius: "0px 7px 7px 0px" }}
                  >
                    <CiBarcode size={24} />
                  </button>
                </div>
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="batch_no" value="Batch No" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("batch_no")}
                  id="batch_no"
                  type="text"
                  placeholder="Batch No"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="reorder" value="Reorder Level" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("reorder_level")}
                  id="reorder"
                  type="number"
                  step="any"
                  placeholder="Batch No"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="reorder_q" value="Reorder Qty" />
                </div>
                <TextInput
                  disabled={isLoading}
                  id="reorder_q"
                  type="number"
                  step="any"
                  placeholder="Reorder Qty"
                  {...register("reorder_quantity")}
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="reorder_l" value="Reorder Qty Limit" />
                </div>
                <TextInput
                  disabled={isLoading}
                  min={0}
                  id="reorder_l"
                  type="number"
                  step="any"
                  placeholder="Reorder Qty Limit"
                  {...register("reorder_quantity_limit")}
                />
              </div>
            </div>

            <div className="p-4 pt-0">
              <div className="">
                <div className="mb-2 block">
                  <Label htmlFor="comment" value="Product Desc" />
                </div>
                <Textarea
                  disabled={isLoading}
                  id="comment"
                  placeholder="Product Desc..."
                  rows={4}
                  {...register("description")}
                />
              </div>

              <div className="mt-5 pt-4 border-t border-gray-300 dark:border-gray-600">
                <div className="dark:text-gray-200 text-sm mb-2">
                  Cross Selling
                </div>

                <div
                  className={`max-w-[500px] mb-3 relative ${
                    !formState.company && "hidden"
                  }`}
                >
                  <div className="text-red-500 text-xs font-semibold pl-3">
                    Note: products are from the branch selected.
                  </div>
                  <ProductSearch
                    companyId={formState.company}
                    onSelect={(product) =>
                      setCrossSelling((prev) => [...prev, product])
                    }
                  />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-4 2xl:grid-cols-4 gap-6">
                  {crossSelling.map((product) => {
                    const imgurl =
                      product.images_links[0] ===
                      "https://dev-assets.gohealthy.ng/items/"
                        ? utills._default_img
                        : product.images_links[0];

                    return (
                      <div
                        key={product.uuid}
                        className="flex gap-3 border p-2 relative rounded-md"
                      >
                        <button
                          className="text-red-500 absolute -top-3 -right-3"
                          onClick={() =>
                            setCrossSelling((prev) =>
                              prev.filter((x) => x.uuid !== product.uuid)
                            )
                          }
                        >
                          <IoIosRemoveCircle size={25} />{" "}
                        </button>
                        <ImageView url={imgurl} className="w-10 h-10" />
                        <div className="font-semibold text-[13px]">
                          {product.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="mt-5 pt-4 border-t border-gray-300 dark:border-gray-600">
                <SellingGroup
                  removeItem={(uuid) =>
                    setSellingGroup((prev) =>
                      prev.filter((x) => x.uuid !== uuid)
                    )
                  }
                  updateSellingGroup={(uuid, markup, price) => {
                    const existing = sellingGroup.find(
                      (item) => item.uuid === uuid
                    );
                    if (existing) {
                      setSellingGroup((prev) =>
                        prev.map((item) => {
                          if (item.uuid === uuid) {
                            return { uuid, markup, price };
                          } else return item;
                        })
                      );
                    } else
                      setSellingGroup((prev) => [
                        ...prev,
                        { uuid, markup, price },
                      ]);
                  }}
                  sellinggroup={sellingGroup}
                />
              </div>

              <div className="mt-5 pt-4 border-t border-gray-300 dark:border-gray-600">
                <VarianFields variants={variants} setVariants={setVariants} />
              </div>
              <div className="mt-5">
                <div className="mb-2 block">
                  <Label htmlFor="comment" value="Add Product Image" />
                </div>
                <ImageUpload
                  imgLinks={imgLinks}
                  setImgs={setImgs}
                  setImgLinks={setImgLinks}
                />
              </div>
            </div>

            <div className="p-4 w-full">
              <Button
                isProcessing={isLoading}
                disabled={!hasChanges()}
                type="submit"
                className="w-full"
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProduct;
const ImageUpload = ({
  setImgs,
  setImgLinks,
  imgLinks,
}: {
  imgLinks: string[];
  setImgs: React.Dispatch<React.SetStateAction<string[]>>;
  setImgLinks: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadFile, { isLoading }] = useUploadFileMutation();

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const res = await uploadFile({ file: file });
    if ("data" in res) {
      const { links, data } = res.data;
      setImgs((prev) => [...prev, ...data]);
      setImgLinks((prev) => [...prev, ...links]);
    } else toast.error("Unable to upload file");
  };

  const removeImage = (link: string) => {
    setImgLinks((prev) => prev.filter((item) => item !== link));
    const img = link.split("/").pop();
    setImgs((prev) => prev.filter((item) => item !== img));
  };
  return (
    <div className="flex items-center gap-5">
      {imgLinks.map((link) => (
        <div
          key={link}
          className="w-24 h-24 bg-white rounded-md border border-gray-300 dark:border-gray-600 relative"
          style={{
            backgroundImage: `url(${link})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <span
            onClick={() => removeImage(link)}
            className="w-5 h-5 rounded-full flex justify-center items-center bg-red-500 hover:bg-red-600 cursor-pointer absolute top-[-5px] left-[-5px]"
          >
            <GoTrash className="text-white text-xs" />
          </span>
        </div>
      ))}
      <div
        onClick={() => inputRef.current?.click()}
        className="w-24 h-24 flex items-center justify-center bg-gray-200 dark:bg-gray-700 cursor-pointer hover:dark:bg-gray-600 hover:bg-gray-300 rounded-md"
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <FaPlus className="text-2xl text-gray-500 dark:text-white" />
        )}
      </div>
      <input
        disabled={isLoading}
        ref={inputRef}
        onChange={handleFileUpload}
        className="hidden"
        type="file"
      />
    </div>
  );
};
