import Breadcrumb from "../../components/Breadcrumb";
import {
  Banner,
  Button,
  Datepicker,
  Label,
  Select,
  TextInput,
} from "flowbite-react";
import { LuHistory } from "react-icons/lu";
import { useLazyGetPurchaseHistoryQuery } from "../../redux/queries/stock";
import { useAppSelector } from "../../lib/hook";
import { useEffect, useState } from "react";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import moment from "moment";
import {
  MdEditDocument,
  MdFormatListNumbered,
  MdOutlinePayment,
  MdSearch,
} from "react-icons/md";
import { useGetCompaniesQuery } from "../../redux/queries/company";
import CustomDropdown from "../../components/CustomDropdown";
import { CiCircleMore } from "react-icons/ci";
import { IoTrashOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { IoMdPrint } from "react-icons/io";
import PurchaseDoc from "../../components/Documents/PurchaseDoc";
import CustomSelect from "../../components/CustomSelect";
// import Drawer from "react-modern-drawer";
import Drawer from '@mui/material/Drawer';
import BranchSwitcher from "../../components/BranchSwitcher";
import CustomPagination from "../../components/CustomPagination";
import PurchasePayment from "./PurchasePayment";

const PurchaseHistory = () => {
  const navigate = useNavigate();
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);

  const [buyer, setBuyer] = useState(defaultBranchUUid);
  const [filterLoading, setFilterLoading] = useState(false);
  const [isPrintOpen, setIsPrintOpen] = useState(false);
  const [row, setRow] = useState<IPurchaseHistory>();
  const [supplierParams, setSupplierParams] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [filters, setFilters] = useState({
    buyer: defaultBranchUUid,
    company: "",
    status: "",
    purchase_date: "",
    expiring_date: "",
    payment_status: "",
    batch_no: "",
    invoice_id: "",
  });

  const [getPurchaseHistory, { data: purchases, isLoading }] =
    useLazyGetPurchaseHistoryQuery();

  const { data: sellers } = useGetCompaniesQuery({
    type: "pharmacy,manufacturer",
    limit: "0,10",
    name: supplierParams,
  });

  const applyFilter = async () => {
    setFilterLoading(true);
    await getPurchaseHistory({
      limit: "0,10",
      ...filters,
    });
    setFilterLoading(false);
  };

  const handlePagination = async (limit: string) => {
    await getPurchaseHistory({
      limit,
      ...filters,
    });
  };

  useEffect(()=> {
    getPurchaseHistory({
      limit: "0,10",
      ...filters
    });
  },[filters.buyer])

  useEffect(() => {
    getPurchaseHistory({
      buyer: buyer,
      limit: "0,10",
    });
  }, []);
  return (
    <div>
      <Breadcrumb title="Purchase History" />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Purchase History.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                Purchase history records.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <LuHistory className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="flex items-center gap-4 p-4 bg-white rounded-md mb-4 dark:bg-gray-700">
        <button
          onClick={()=> setFilters((prev)=> ({ ...prev, buyer: defaultBranchUUid, company: "" }))}

          className={`${"rounded-lg border-2 h-10 w-32 text-sm font-semibold border-[#167490]"} ${
            filters.buyer
              ? "bg-[#167490] text-white"
              : "text-[#167490] hover:bg-[#167490]/10"
          }`}
        >
          Outgoing
        </button>
        <button
         onClick={()=> setFilters((prev)=> ({ ...prev, company: defaultBranchUUid, buyer: "" }))}
          className={`${"rounded-lg border-2 h-10 w-32 text-sm font-semibold border-[#167490]"} ${
            filters.company
              ? "bg-[#167490] text-white"
              : "text-[#167490] hover:bg-[#167490]/10"
          }`}
        >
          Incoming
        </button>
      </div>

      <form
        className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm"
        onSubmit={(e) => {
          e.preventDefault();
          applyFilter();
        }}
      >
        <div className="grid grid-cols-4 gap-3">
          <div>
            <Label value="Invoice ID" />
            <TextInput
              value={filters.invoice_id}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, invoice_id: e.target.value }))
              }
              type="text"
              icon={MdSearch}
              placeholder="search..."
            />
          </div>
          <div>
            <Label value="Batch No" />
            <TextInput
              value={filters.batch_no}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, batch_no: e.target.value }))
              }
              type="text"
              icon={MdSearch}
              placeholder="search..."
            />
          </div>
          <div>
            <Label value="Branch" />
            <BranchSwitcher
              defaultCompany={filters.buyer}
              sizing="md"
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, buyer: value }))
              }
            />
          </div>
          <div>
            <Label value="Supplier" />
            <CustomSelect
              onSelect={(val) =>
                setFilters((prev) => ({ ...prev, company: val.value }))
              }
              inputClasses="h-[43px] pt-1"
              options={
                sellers?.data.map((item) => ({
                  label: item.name,
                  value: item.uuid,
                })) ?? []
              }
              onSearch={(text) => setSupplierParams(text)}
              placeholder="Supplier"
              reset={filters.company === ""}
            />
          </div>

          <div>
            <Label value="Order Status" />
            <Select
              value={filters.status}
              onChange={(e) =>
                setFilters((prev) => ({ ...prev, status: e.target.value }))
              }
            >
              <option value="">Show all</option>
              <option value="delivered">Delivered</option>
              <option value="requested">Requested</option>
            </Select>
          </div>

          <div>
            <Label value="Payment Status" />
            <Select
              value={filters.payment_status}
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  payment_status: e.target.value,
                }))
              }
            >
              <option value="">Show all</option>
              <option value="paid">Paid</option>
              <option value="unpaid">Unpaid</option>
              <option value="part-paid">Part Paid</option>
            </Select>
          </div>
          <div>
            <Label value="Date" />
            <Datepicker
              value={filters.purchase_date}
              onSelectedDateChanged={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  purchase_date: moment(date).format("DD-MM-YYYY"),
                }))
              }
              datepicker-format={"DD-MM-YYYY"}
            />
          </div>
          <div>
            <Label value="Expiry Date" />
            <Datepicker
              value={filters.expiring_date}
              onSelectedDateChanged={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  expiring_date: moment(date).format("DD-MM-YYYY"),
                }))
              }
              datepicker-format={"DD-MM-YYYY"}
            />
          </div>

          <div>
            <Label value="actions" className="opacity-0" />
            <div className="flex items-center gap-3">
              <Button
                disabled={filterLoading}
                outline
                size="xs"
                onClick={() => {
                  setFilters({
                    company: "",
                    buyer: defaultBranchUUid,
                    status: "",
                    purchase_date: "",
                    expiring_date: "",
                    payment_status: "",
                    batch_no: "",
                    invoice_id: "",
                  });
                  getPurchaseHistory({
                    buyer: buyer,
                    limit: "0,10",
                  });
                  setSupplierParams("");
                }}
              >
                Reset
              </Button>
              <Button
                type="submit"
                disabled={filterLoading}
                isProcessing={filterLoading}
                size="xs"
              >
                Filter
              </Button>
            </div>
          </div>

          {/* <div>
            <BranchSwitcher onChange={(value) => setBuyer(value)} />
          </div> */}
        </div>
      </form>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={handlePagination}
          isLoading={isLoading}
          data={purchases?.data}
          total={purchases?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-9">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  id
                </th>
                <th scope="col" className="px-6 py-3">
                  Supplier
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount Paid
                </th>
                <th scope="col" className="px-6 py-3">
                  Total cost
                </th>
                <th scope="col" className="px-6 py-3">
                  Payment Type
                </th>
                <th scope="col" className="px-6 py-3">
                  date
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {purchases?.data.map((purchase) => (
                <tr
                  key={purchase.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td
                    onClick={(e) => e.stopPropagation()}
                    className="px-6 py-4"
                  >
                    <ImageView url={utills._default_img} className="w-8 h-8" />
                  </td>
                  <td className="px-6 py-4">{purchase.invoice_id}</td>
                  <td className="px-6 py-4">
                    {purchase.supplier?.name ?? "-"}
                  </td>
                  <td className="px-6 py-4">
                    {utills._currency_format(purchase.amount_paid ?? 0, "NGN")}
                  </td>
                  <td className="px-6 py-4">
                    {utills._currency_format(purchase.total_cost ?? 0, "NGN")}
                  </td>
                  <td className="px-6 py-4">{purchase.payment_type}</td>
                  <td className="px-6 py-4">
                    {moment(purchase.purchase_date).format("LLL")}
                  </td>
                  <td className="px-6 py-4">
                    <CustomDropdown
                      trigger={
                        <div className="">
                          <CiCircleMore className="text-2xl" />
                        </div>
                      }
                      menu={[
                        {
                          icon: MdOutlinePayment,
                          title: "Pay Now",
                          action: () => {
                            setRow(purchase);
                            setSelectedOrder(purchase.uuid);
                            setPaymentModal(true);
                          },
                        },
                        {
                          icon: MdFormatListNumbered,
                          title: "View Items",
                          action: () =>
                            navigate(`/purchase-history/items/${purchase.uuid}`, {
                              state: { purchase },
                            }),
                        },
                        {
                          icon: MdEditDocument,
                          title: "Manage",
                          action: () =>
                            navigate(`/purchase-history/${purchase.uuid}`, {
                              state: { purchase },
                            }),
                        },
                        {
                          icon: IoTrashOutline,
                          title: "Delete",
                          action: () => console.log(purchase.uuid),
                        },
                        {
                          icon: IoMdPrint,
                          title: "Print",
                          action: () => {
                            setRow(purchase);
                            setIsPrintOpen(true);
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CustomPagination>
      </div>

      <PurchaseDoc
        isOpen={isPrintOpen}
        close={() => setIsPrintOpen(false)}
        data={row}
      />
      <Drawer
        open={paymentModal}
        onClose={() => setPaymentModal((prev) => !prev)}
        anchor="right"
        ModalProps={{
          keepMounted: false,
        }}
        sx={{ zIndex: 10}}
      >
        <PurchasePayment
          purchase={row}
          orderUuid={selectedOrder}
          close={() => setPaymentModal(false)}
        />
      </Drawer>
    </div>
  );
};

export default PurchaseHistory;
