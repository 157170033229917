import { api } from "../baseConfig";

interface Filters {
  type?: string;
  status?: number;
  limit?: string;
}
export const settingsSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<SettingsResponse, void>({
      query: () => ({
        url: "/settings",
        method: "GET",
      }),
      providesTags: ["Settings"],
    }),
    getCountries: builder.query<ICountryResponse, void>({
      query: () => ({
        url: "/settings/countries",
        method: "GET",
      }),
      providesTags: ["Settings"],
    }),
    getCategories: builder.query<CategoryResponse, Filters>({
      query: (filters) => ({
        url: "/items-categories",
        method: "GET",
        params: filters,
      }),
      providesTags: ["Settings"],
    }),
    createCategory: builder.mutation<any, any>({
      query: (data) => ({
        url: "/items-categories",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Settings"],
    }),
    updateCategory: builder.mutation<any, any>({
      query: ({ id, ...data }) => ({
        url: `/items-categories/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Settings"],
    }),
    deleteCategory: builder.mutation<any, string>({
      query: (id) => ({
        url: `/items-categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Settings"],
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useGetCategoriesQuery,
  useGetCountriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = settingsSlice;
