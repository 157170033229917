import { Button, Label, TextInput } from "flowbite-react";
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegAddressCard, FaTruck } from "react-icons/fa";
import { useGetUserQuery } from "../redux/queries/users";

interface IShipping extends UI {
  updateFee: (val: string) => void;
  customer: {
    label: string;
    value: string;
  } | null;
  deleveryFee: string
}
const Shipping = (props: IShipping) => {
  const { close, updateFee, customer, deleveryFee } = props;
  const { data: user } = useGetUserQuery(customer?.value ?? "");

  return (
    <div className="h-full flex flex-col bg-white dark:bg-gray-800 min-w-[450px]">
      <div className="flex justify-between items-center px-3 py-3 border-b border-gray-300">
        <h4 className="font-semibold dark:text-white">Shipping Information</h4>
        <span onClick={close} className="cursor-pointer">
          <AiOutlineCloseCircle className="dark:text-white" />
        </span>
      </div>

      <div className="px-3 mt-3 flex flex-col justify-between flex-1">
        <div>
          <div className="max-w-md mb-3">
            <Label htmlFor="address" value="Address" className="text-xs" />
            <TextInput
              id="address"
              value={user?.data.company.address.name}
              icon={FaRegAddressCard}
              type="text"
              className="flex-1 mt-1"
              sizing="sm"
              placeholder="Address"
              style={{ borderRadius: 2 }}
            />
          </div>

          <div className="max-w-md mb-3">
            <Label htmlFor="fee" value="Delivery fee" className="text-xs" />
            <TextInput
              id="fee"
              icon={FaTruck}
              value={deleveryFee}
              onChange={(e) => updateFee(e.target.value)}
              type="number"
              step="any"
              className="flex-1 mt-1"
              sizing="sm"
              placeholder="Delivery fee"
              style={{ borderRadius: 2 }}
            />
          </div>
        </div>

        <Button className="rounded-xs mb-4" onClick={close}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default Shipping;
